import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AutoComplete } from '../../../components/AutoComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
  },
  item: {
    margin: '5px',
    ...theme.palette.text,
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
  },
  label: {
    color: '#507180',
  },
}));

// Show related pipeline network(s) for a pipeline (displayedItem)
export default ({ displayedItem, loading, data, onChange }) => {
  const classes = useStyles();
  const pipelineNetworks = data?.pipeline?.pipelineNetworks || data?.asset?.pipelineNetworks;

  /**
   * This component should only be rendered when:
   * - there is an item object to be displayed
   * - data is not loading
   * - data exists
   * - entity is a `pipeline`
   * - there is a non-zero number of associated `pipeline network`s
   */
  if (!displayedItem || loading || !pipelineNetworks || !(pipelineNetworks.length > 0)) {
    return null;
  }

  const sortedPipelineNetworks = pipelineNetworks
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={classes.root}>
      <AutoComplete
        getData={null}
        menuItems={sortedPipelineNetworks}
        onChange={onChange}
        value={null}
        label="Pipeline Networks"
        optionKey="name"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={false}
      />
    </div>
  );
};
