export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (theme) => theme.palette.primaryBackground,
    height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
    padding: {
      lg: '1em 3em',
      md: '1em 2em',
      sm: '1em 1em',
      xs: '0.75em',
    },
  },
  pageContent: {
    marginTop: '0.5em',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '2.5em',
    margin: '0.4em 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '50px auto',
    padding: '50px',
    width: '100%',
    maxWidth: '1600px',
    minHeight: '90vh',
  },
};
