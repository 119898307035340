import makeStyles from '@mui/styles/makeStyles';

export const useSidePageButtonStyles = makeStyles((theme) => ({
  button: {
    color: 'black',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: '5px 0 0 5px',
    writingMode: 'vertical-lr',
  },
  buttonWithClose: {
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
  },
  buttonLabel: {
    display: 'block',
    transform: 'rotate(180deg)',
  },
  content: {
    position: 'relative',
    boxSizing: 'border-box',
    width: '40px',
    padding: '2rem 10px',
    borderRadius: '5px 0 0 5px',
    backgroundColor: '#e3e8eb',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#dae1e7',
    },
  },
  close: {
    position: 'absolute',
    paddingTop: '7px',
    paddingBottom: '7px',
    top: 0,
    left: 0,
    padding: 0,
    width: '100%',
    borderRadius: 0,
  },
  boxy: {
    backgroundColor: 'white',
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
  },
  pulse: {
    position: 'absolute',
    borderRadius: '5px 0 0 5px',
    top: '1px',
    width: '100%',
    height: 'calc(100% - 1px)',
    zIndex: 0,
    backgroundColor: theme.palette.baseColors.deepSeaNavy.c100,
    animation: '$pulse 3s 1',
  },
  pulse2: {
    animationDelay: '1s',
  },
  pulse3: {
    animationDelay: '2s',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale(1.5)',
    },
  },
  left: {
    borderRadius: '0 5px 5px 0',
  },
  mini: {
    padding: '35px 2px 25px 2px',
    width: '25px',
  },
}));
