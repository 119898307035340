import clsx from 'clsx';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid2 as Grid, Typography } from '@mui/material';
import ErrorBoundary from '../../../../../components/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '80%',
    border: 'solid thin black',
  },
  number: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Barlow',
  },
  selected: {
    borderColor: theme.palette.baseColors.infoBlue.c100,
  },
  scroll: {
    ...theme.palette.scrollbar,
    width: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
  },
}));

const PageItem = ({ page, selectedPage, setSelectedPage }) => {
  const classes = useStyles();
  const { img, number } = page;

  return (
    <Grid
      container
      size={12}
      onClick={() => setSelectedPage(page)}
      style={{ cursor: 'pointer' }}
      spacing={2}
      justifyContent="space-between"
    >
      <Typography variant="h6" className={classes.number}>
        {number}
      </Typography>
      <img
        src={img}
        alt={`smart-report-page-${number}`}
        className={clsx({
          [classes.img]: true,
          [classes.selected]: page?.number === selectedPage?.number,
        })}
      />
    </Grid>
  );
};

export const Pages = ({ pages, selectedPage, setSelectedPage }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item size={12}>
        <Typography variant="h6" style={{ fontFamily: 'Barlow' }}>
          Pages
        </Typography>
      </Grid>
      <Grid container item size={12} className={classes.scroll} spacing={2}>
        <ErrorBoundary>
          {pages?.map((page, idx) => (
            <PageItem
              key={`smart-report-page-${page?.id}-${idx}`}
              page={page}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          ))}
        </ErrorBoundary>
      </Grid>
    </Grid>
  );
};
