import assetThumbnail from '../components/NewSmartReport/images/asset-thumbnail.png';
import countryThumbnail from '../components/NewSmartReport/images/country-thumbnail.png';

export const useGetReportTemplates = () => {
  const templates = [
    {
      id: 1,
      type: 'country',
      name: 'Country Template',
      description: 'This is a country template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: countryThumbnail,
    },
    {
      id: 2,
      type: 'asset',
      name: 'Asset Template',
      description: 'This is an asset template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: assetThumbnail,
    },
    {
      id: 3,
      type: 'corporate',
      name: 'Corporate Template',
      description: 'This is a corporate template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: assetThumbnail,
    },
    {
      id: 4,
      type: 'country',
      name: 'Another Country Template',
      description: 'This is a another country template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: countryThumbnail,
    },
    {
      id: 5,
      type: 'asset',
      name: 'Another Asset Template',
      description: 'This is a another asset template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: assetThumbnail,
    },
    {
      id: 6,
      type: 'corporate',
      name: 'Another Corporate Template',
      description: 'This is a another corporate template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: assetThumbnail,
    },
    {
      id: 7,
      type: 'country',
      name: 'Yet Another Country Template',
      description: 'This is a yet another country template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: countryThumbnail,
    },
    {
      id: 8,
      type: 'asset',
      name: 'Yet Another Asset Template',
      description: 'This is a yet another asset template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: assetThumbnail,
    },
    {
      id: 9,
      type: 'corporate',
      name: 'Yet Another Corporate Template',
      description: 'This is a yet another corporate template',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: 'John Doe',
      thumbnailUrl: assetThumbnail,
    },
  ];

  return { templates };
};
