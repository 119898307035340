import { ADMINS_ENTITY_NAME_CORRECTIONS_PATH } from '../../../../utils/constants';

import React from 'react';
import { Route } from '@mui/icons-material';
import { EntityNameList } from './components/EntityNameList';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';

export const EntityNameCorrections = () => {
  return (
    <Switch path={ADMINS_ENTITY_NAME_CORRECTIONS_PATH}>
      <Route exact path={ADMINS_ENTITY_NAME_CORRECTIONS_PATH} component={EntityNameList} />
    </Switch>
  );
};
