import { ANALYTICS_EVENTS, ROOT_PATH, SIGN_IN_PATH, SIGN_UP_PATH } from '../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
// This is needed to "remove" navigate on line 121 to prevent a console error
/* eslint no-unused-vars: 0 */
import React, { useContext, useState } from 'react';

import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { UserConfirmationQuery } from './operations';
import Logo from '../../../assets/images/FullLogo.svg';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    margin: 'auto',
    marginBottom: '20px',
  },
  loginRoot: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginForm: {
    height: 'auto',
    maxHeight: '550px',
    width: '500px',
    padding: '45px',
    boxSizing: 'border-box',
  },
  loginButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginButton: {
    width: '75%',
    height: '40px',
    fontSize: '0.9rem',
    borderColor: theme.palette.baseColors.deepSeaNavy.c75,
  },
  link: {
    cursor: 'pointer',
  },
}));

const UserConfirmation = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  const { loading, error, data } = useQuery(UserConfirmationQuery, {
    variables: {
      confirmationToken: urlParams.get('confirmationToken'),
      redirectUrl: `${window.location.origin}${SIGN_IN_PATH}`,
    },
  });

  if (data) {
    const { confirmed, redirectUrl } = data.userConfirmation;
    if (confirmed) {
      const url = new URL(redirectUrl);
      history.push(`${url.pathname}${url.search}`);
    }
  }

  return (
    <div className={classes.loginRoot}>
      <Paper className={classes.loginForm}>
        <Grid
          container
          direction="column"
          align="center"
          spacing={3}
          sx={{ justifyContent: 'space-around' }}
        >
          <img
            className={classes.logo}
            id="logo"
            src={Logo}
            alt="Welligence-Logo"
            width="200px"
            height="75px"
          />
          <Divider variant="variant" />
          {loading && (
            <Typography fontWeight="medium">
              Your account is currently being validated. You will be automatically redirected to the
              sign-in page shortly.
            </Typography>
          )}
          {error && (
            <Typography fontWeight="medium">
              There was an error validating your account. Please contact Welligence support for
              further assistance.
            </Typography>
          )}
          {data && (
            <Typography fontWeight="medium">
              Your account has been validated! You will be redirected to the sign in page now.
            </Typography>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default UserConfirmation;
