import { ApolloConsumer } from '@apollo/client';
import Button from '@mui/material/Button';
import { LOGOUT_MUTATION } from '../../../pages/Login/operations';
import React from 'react';
import { SIGN_IN_PATH } from '../../../utils/constants';
import { clearUserCredentials } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 'bold',
    width: '100%',
    ...theme.menuButtonFontStyles,
    justifyContent: 'flex-start',
    padding: '6px 16px',
    color: theme.palette.baseColors.deepSeaNavy.c100,
  },
  iconButton: {
    color: theme.palette.button.backgroundColor,
  },
}));

export const SignOutButton = ({ onClick }) => {
  const history = useHistory();
  const classes = useStyles();

  const [userLogout, { called, loading }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      clearUserCredentials();
      sessionStorage.clear();
      history.push(SIGN_IN_PATH);
    },
    onError: (error) => alert(error),
  });

  return (
    <ApolloConsumer>
      {(client) => {
        const logOut = () => {
          userLogout();
          client.clearStore();
          onClick && onClick();
        };

        return (
          <Button className={classes.link} onClick={logOut} disabled={called || loading}>
            Sign out
          </Button>
        );
      }}
    </ApolloConsumer>
  );
};
