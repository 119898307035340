import makeStyles from '@mui/styles/makeStyles';

export const useAppBarStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    border: 'solid lightgrey 1px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
  },
  left: {
    display: 'flex',
  },
  list: {
    width: 250,
  },
  link: {
    ...theme.menuButtonFontStyles,
    justifyContent: 'flex-start',
    padding: '6px 16px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '45px',
    alignItems: 'center',
  },
  selectedLink: {
    ...theme.palette.accentText,
    fontWeight: 'bold',
    ...theme.menuButtonFontStyles,
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  walkthrough: {
    position: 'fixed',
    left: 0,
    top: '350px',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  button: {
    ...theme.palette.button,
  },
}));
