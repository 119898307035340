import { ArrowBack as ArrowBackIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { ADMINS_SMART_REPORTS_PATH } from '../../../../../../utils/constants';
import { ImageNotSupported as ImageNotSupportedIcon } from '@mui/icons-material';
import { PageShell } from '../../../PageShell';
import SnackbarContext from '../../../../../../contexts/SnackbarContext';
import { styles } from './styles';
import { useContext } from 'react';
import { useCreateReport } from '../../hooks/useCreateReport';
import { useGetEntities } from '../../hooks/useGetEntities';
import { useGetReportTemplates } from '../../hooks/useGetReportTemplates';
import { useGetReportTypes } from '../../hooks/useGetReportTypes';
import { useHistory } from 'react-router-dom';

const ReportTemplateList = ({ templates, template, setTemplate }) => {
  return (
    // TODO: make this responsive
    <ImageList cols={4} sx={styles.templateList}>
      {/* No template selected item */}
      <ImageListItem key="no-template" onClick={() => setTemplate(null)} sx={styles.template}>
        <Box sx={styles.noTemplate} className={!template ? 'selected' : ''}>
          <ImageNotSupportedIcon fontSize="large" />
        </Box>
        <ImageListItemBar title="Blank" position="below" />
      </ImageListItem>

      {/* Templates */}
      {templates.map((item) => (
        <ImageListItem
          key={item.id}
          onClick={() => setTemplate(item)}
          sx={styles.template}
          className={item?.id === template?.id ? 'selected' : ''}
        >
          <img
            srcSet={`${item.thumbnailUrl}?w=200&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.thumbnailUrl}?w=200&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar title={item.name} subtitle={item.description} position="below" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export const NewSmartReport = () => {
  const [reportType, setReportType] = useState('');
  const [entity, setEntity] = useState('');
  const [template, setTemplate] = useState(null);

  const { reportTypes } = useGetReportTypes();
  const { entities } = useGetEntities(reportType);
  const { templates } = useGetReportTemplates();
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);

  const { createReport } = useCreateReport({
    reportTypeId: reportType,
    entityId: entity,
    reportTemplate: template,
  });

  const onReportTypeChange = (e) => {
    setReportType(e.target.value);
    setEntity(null);
  };

  const onCreate = async () => {
    await createReport();
    setSnack({ open: true, message: 'Smart Report created successfully!', severity: 'success' });
    // redirect to the list of reports
    history.push(ADMINS_SMART_REPORTS_PATH);
  };

  const isCreateEnabled = reportType && entity;

  return (
    <PageShell title="Add New Report">
      <Button
        variant="text"
        color="secondary"
        href={ADMINS_SMART_REPORTS_PATH}
        startIcon={<ArrowBackIcon />}
      >
        <Typography variant="body1" sx={{ color: 'secondary' }}>
          Back to reports
        </Typography>
      </Button>
      <Box sx={styles.container}>
        {/* Report Type */}
        <Box display="flex" width="100%" alignItems="center" sx={styles.row}>
          <Box flex={1}>
            <Typography variant="h5" sx={styles.label}>
              Report Type
            </Typography>
          </Box>
          <Box flex={5}>
            <Select
              value={reportType}
              onChange={onReportTypeChange}
              variant="outlined"
              sx={styles.select}
              IconComponent={ExpandMoreIcon}
            >
              {reportTypes.map((reportType) => (
                <MenuItem key={reportType.id} value={reportType.id} sx={styles.menuItem}>
                  {reportType.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        {/* Entity */}
        <Box display="flex" width="100%" alignItems="center" sx={styles.row}>
          <Box flex={1}>
            <Typography variant="h5" sx={styles.label}>
              Entity
            </Typography>
          </Box>
          <Box flex={5}>
            <Select
              value={entity}
              onChange={(e) => setEntity(e.target.value)}
              variant="outlined"
              sx={styles.select}
              IconComponent={ExpandMoreIcon}
            >
              {entities.map((entity) => (
                <MenuItem key={entity.id} value={entity.id} sx={styles.menuItem}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        {/* Template */}
        <Box display="flex" width="100%" alignItems="center" sx={styles.row}>
          <Box flex={1}>
            <Typography variant="h5" sx={styles.label}>
              Template
            </Typography>
          </Box>
          <Box flex={5}>
            <ReportTemplateList
              templates={templates}
              template={template}
              setTemplate={setTemplate}
            />
          </Box>
        </Box>

        {/* Buttons */}
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Box flex={1}></Box>
          <Box flex={5}>
            <Button
              variant="contained"
              color="primary"
              sx={styles.cancelButton}
              href={ADMINS_SMART_REPORTS_PATH}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={styles.button}
              onClick={onCreate}
              disabled={!isCreateEnabled}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Box>
    </PageShell>
  );
};
