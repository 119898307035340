import { ADMINS_JOB_STATUS_PATH } from '../../../../utils/constants';

import React from 'react';
import { Route } from '@mui/icons-material';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { JobStatusList } from './components/JobStatusList/JobStatusList';

export const JobStatus = () => {
  return (
    <Switch path={ADMINS_JOB_STATUS_PATH}>
      <Route exact path={ADMINS_JOB_STATUS_PATH} component={JobStatusList} />
    </Switch>
  );
};
