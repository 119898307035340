import {
  ADMINS_PATH,
  ANALYTICS_EVENT_ACTIONS,
  ANALYTICS_EVENTS,
  DATA_DOWNLOADS_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../../utils/constants';
import { AppBar as AppBarMui, Button, Toolbar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React, { useCallback, useContext, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { DropDown } from './DropDown';
import FullLogo from '../../../assets/images/FullLogo.svg';
import { GlobalSearch } from '../GlobalSearch';
import MenuIcon from '@mui/icons-material/Menu';
import { MobileTray } from './MobileTray';
import { ShinyLink } from '../Shiny';
import { SidePageButton } from '../SidePageButton';
import { SignOutButton } from './SignOutButton';
import { FeedbackPanel } from './FeedbackPanel';
import WLogo from '../../../assets/images/WLogo.svg';
import WalkthroughContext from '../../contexts/WalkthroughContext';
import { useAppBarStyles } from './AppBar.style';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useLinks } from './hooks/useLinks';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUserId } from '../../utils/auth';
import SnackbarContext from '../../contexts/SnackbarContext';
import { useFeatureFlags } from '../../contexts/FeatureFlagsContext';

const getUserInitials = (user, userLoading) => {
  if (userLoading) return '';
  if (!user || !user.firstName || !user.lastName) return 'W';
  return user.firstName[0] + user.lastName[0];
};

export const AppBar = ({ forceNewTab }) => {
  const classes = useAppBarStyles();
  const { hasWalkthrough, setWalkthrough, setWalkthroughSession } = useContext(WalkthroughContext);
  const { currentUser, userLoading, isUserEntitled } = useCurrentUser();
  const isLgMediaQueryPlus = useMediaQuery('(min-width:1536px)');
  const isMobile = useMediaQuery('(max-width:750px)');
  const [openMobileTray, setOpenMobileTray] = useState(false);
  const [sideButtonVisible, setSideButtonVisible] = useState(true);
  const [openFeedbackPanel, setOpenFeedbackPanel] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const location = useLocation();
  const path = location.pathname;
  const { feedbackFeature } = useFeatureFlags();

  const { available: walkthroughEnabled, sideButtonShown: walkthroughShowSideButton } =
    hasWalkthrough();
  const links = useLinks({
    enabled: walkthroughEnabled,
    setSideButtonVisible,
    setWalkthrough,
    class: classes.link,
  });

  let Logo, logoWidth, logoHeight;
  if (isLgMediaQueryPlus) {
    Logo = FullLogo;
    logoHeight = '45px';
    logoWidth = '172px';
  } else {
    Logo = WLogo;
    logoHeight = '45px';
    logoWidth = '50px';
  }

  const startWalkthrough = useCallback(() => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'walkthrough',
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'start_walkthrough_side_button',
      userId: getUserId(),
      page_location: path,
    });
    setWalkthrough(true);
  }, []);

  const hideWalkthrough = useCallback(() => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: 'walkthrough',
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'hide_walkthrough_side_button',
      userId: getUserId(),
      page_location: path,
    });
    setWalkthroughSession(false);
    setSideButtonVisible(false);
  }, []);

  return (
    <div>
      <AppBarMui position="sticky" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <div>
              <Link to={ROOT_PATH}>
                <img
                  id="logo"
                  src={Logo}
                  alt="Welligence-Logo"
                  width={logoWidth}
                  height={logoHeight}
                />
              </Link>
            </div>
            {!isMobile &&
              links?.map((link, i) =>
                link.type === 'menu' ? (
                  <DropDown key={`${i}-${link.title}`} link={link} forceNewTab={forceNewTab} />
                ) : link.type === 'shiny' ? (
                  <ShinyLink
                    key={`${i}-${link.title}`}
                    link={link}
                    styles={{ link: classes.link }}
                    disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
                  />
                ) : link.type === 'href' ? (
                  <Button
                    key={`${i}-${link.title}`}
                    color="inherit"
                    underline="none"
                    className={classes.link}
                    onClick={() => window.open(link.link, '_blank')}
                  >
                    {link.title}
                  </Button>
                ) : (
                  <Button
                    key={`${i}-${link.title}`}
                    component={Link}
                    to={`${link.link}`}
                    color="inherit"
                    underline="none"
                    disabled={!isUserEntitled(link.link)}
                    className={path.indexOf(link.link) !== -1 ? classes.selectedLink : classes.link}
                  >
                    {link.title}
                  </Button>
                ),
              )}
          </div>
          {(!openMobileTray || !isMobile) && <GlobalSearch />}
          {isMobile ? (
            <Button
              onClick={() => {
                setOpenMobileTray(!openMobileTray);
              }}
              color="inherit"
              underline="none"
              className={classes.link}
            >
              {openMobileTray ? <CloseIcon /> : <MenuIcon />}
            </Button>
          ) : (
            <div id="appbar-menu-button" className={classes.right}>
              {feedbackFeature ? (
                <Button
                  id="btn-feedback"
                  color="inherit"
                  underline="none"
                  variant="contained"
                  className={classes.button}
                  disabled={openFeedbackPanel}
                  onClick={() => setOpenFeedbackPanel(true)}
                >
                  <HelpOutlinedIcon fontSize="small" />
                  &nbsp;feedback
                </Button>
              ) : null}
              <DropDown
                link={{
                  title: getUserInitials(currentUser, userLoading),
                  menuItems: [
                    {
                      title: 'Settings',
                      link: SETTINGS_PATH,
                    },
                    // Only show Admins portal to admins:
                    ...(currentUser?.admin
                      ? [
                          {
                            title: 'Admins portal',
                            link: ADMINS_PATH,
                          },
                        ]
                      : []),
                    { type: 'jsx', jsx: <SignOutButton key="sign-out" /> },
                  ],
                }}
                isUserMenu
              />
            </div>
          )}
        </Toolbar>
      </AppBarMui>
      <FeedbackPanel
        open={openFeedbackPanel}
        onClose={() => setOpenFeedbackPanel(false)}
        setSnack={setSnack}
      />
      {openMobileTray && isMobile && (
        <MobileTray
          forceNewTab={forceNewTab}
          onButtonClick={() => setOpenMobileTray(false)}
          onCloseTray={() => {
            setOpenMobileTray(!openMobileTray);
          }}
        />
      )}
      {!!walkthroughEnabled && walkthroughShowSideButton && sideButtonVisible && (
        <SidePageButton
          onClick={startWalkthrough}
          label="START PAGE TUTORIAL"
          isOpen
          closeAble
          className={classes.walkthrough}
          onClose={hideWalkthrough}
          boxy
          left
          mini
        />
      )}
    </div>
  );
};
