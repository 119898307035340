const expandIcon = {
  width: '24px',
};

const grid = (columnsWidth) => ({
  display: 'grid',
  flexGrow: 1,
  columnGap: '1em',
  alignItems: 'center',
  gridTemplateColumns: `${columnsWidth.join(' ')}`,
});

const gridVersions = (columnsWidth) => {
  const gridStyles = grid(columnsWidth);
  return {
    ...gridStyles,
    gridTemplateColumns: `${gridStyles.gridTemplateColumns} calc(${expandIcon.width} - ${gridStyles.columnGap})`,
  };
};

const title = {
  display: 'flex',
  alignItems: 'center',
  gap: '1em',

  '& > svg': {
    color: 'gray',
  },
};

const block = (status = null) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
  paddingLeft: '2.25em',
  position: 'relative',
  paddingTop: '.5em',
  paddingBottom: '.5em',

  '&:first-child': {
    // Only add first-child styles if the status is draft
    ...(status === 'draft'
      ? {
          paddingTop: '2em',
          paddingBottom: '2em',

          '&:before': {
            top: '1em',
            // make this a dashed line instead
            background: 'linear-gradient(0deg, gray 60%, transparent 40%);',
            backgroundSize: '4px 4px',
          },

          '&:after': {
            background: 'linear-gradient(90deg, gray 60%, transparent 40%);',
            backgroundSize: '4px 4px',
          },
        }
      : {}),
  },

  '&:before': {
    content: '""',
    display: 'block',
    width: '1px',
    height: '100%',
    backgroundColor: 'gray',
    position: 'absolute',
    left: '.75em',
    top: 0,
  },

  '&:after': {
    content: '""',
    display: 'block',
    width: '1em',
    height: '1px',
    backgroundColor: 'gray',
    position: 'absolute',
    left: '.75em',
    top: '50%',
  },
});

const blockEnd = (status) => ({
  '&:first-child': {
    ...block(status)['&:first-child'],

    '&:before': {
      ...block(status)['&:first-child']['&:before'],
      top: 0,
      height: '50%',
    },
  },

  '&:before': {
    ...block(status)['&:before'],
    height: '50%',
  },
});

const titleBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const accordion = {
  padding: '0.1em 0.5em',
  margin: '0.8em 0',
  borderRadius: '1em',
  backgroundColor: (theme) => theme.palette.baseColors.cloudGray.c100,
  '&:before': {
    display: 'none',
  },

  // .css-7iurs7-MuiAccordionSummary-content.Mui-expanded
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '0.8em 0',
  },

  '.MuiAccordionSummary-expandIconWrapper, .MuiAccordion-heading': {
    color: 'black',
  },
};

const accordionDetails = {
  backgroundColor: (theme) => theme.palette.baseColors.cloudGray.c100,
};

export const styles = {
  grid,
  gridVersions,
  accordion,
  accordionDetails,
  expandIcon,
  titleBox,
  title,
  block,
  blockEnd,
};
