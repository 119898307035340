import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_MANUAL_REVIEW_ENTITY_QUERY,
  CREATE_MANUAL_ENTITY_NAME_MAPPING_MUTATION,
  CREATE_COMPANY_MUTATION,
} from '../../../../operations';
import { formatTimestamp } from '../../../../../../utils/helpers';
import { styles } from './styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const LoadingState = () => (
  <DialogContent sx={styles.stateContainer}>
    <CircularProgress />
    <Typography sx={{ mt: 2 }}>Loading entity details...</Typography>
  </DialogContent>
);

const ErrorState = ({ error }) => (
  <DialogContent sx={styles.stateContainer}>
    <ErrorOutlineIcon color="error" sx={styles.errorIcon} />
    <Typography color="error">{error}</Typography>
  </DialogContent>
);

const EmptySuggestionsState = () => (
  <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
    <Typography variant="subtitle1" color="textSecondary">
      No suggestions available.
    </Typography>
  </DialogContent>
);

export const EntityDetailModal = ({ open, onClose, entityId }) => {
  if (!open) return null;

  const {
    data,
    loading: getEntityLoading,
    error: getEntityError,
  } = useQuery(GET_MANUAL_REVIEW_ENTITY_QUERY, {
    variables: { id: entityId },
    skip: !open || !entityId,
  });

  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [newEntityName, setNewEntityName] = useState('');

  const [createMapping, { loading: createMappingLoading, error: createMappingError }] = useMutation(
    CREATE_MANUAL_ENTITY_NAME_MAPPING_MUTATION,
  );
  const [createCompany, { loading: createCompanyLoading, error: createCompanyError }] =
    useMutation(CREATE_COMPANY_MUTATION);

  const entity = data?.nexusManualReviewEntity;
  const suggestions = entity?.entityNameSuggestions || [];

  const handleSelectSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion.id);
    setNewEntityName('');
  };

  const handleCreateMapping = async () => {
    if (selectedSuggestion) {
      await createMapping({
        variables: {
          manualReviewEntityId: entity.id,
          entityNameId: selectedSuggestion,
        },
      });
    } else if (newEntityName.trim() !== '') {
      switch (entity.entityType) {
        case 'COMPANY':
          try {
            await createCompany({
              variables: {
                companyName: newEntityName,
                manualReviewEntityId: entity.id,
              },
            });
          } catch (error) {
            return;
          }
          break;
        default:
          console.warn('Entity type not supported yet.');
      }
    }
    onClose(true);
  };

  const isSubmitting = createMappingLoading || createCompanyLoading;
  const disableSuggestions = newEntityName.trim() !== '';
  const buttonText = selectedSuggestion ? 'Link to Existing' : 'Create New';
  const submitError = createMappingError?.message || createCompanyError?.message;

  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth>
      <DialogTitle>Entity Details</DialogTitle>
      {getEntityLoading ? (
        <LoadingState />
      ) : getEntityError ? (
        <ErrorState error={getEntityError.message} />
      ) : (
        <>
          <DialogContent>
            <Typography variant="h6">{entity.tentativeEntityName}</Typography>
            <Typography>Type: {entity.entityType}</Typography>
            <Typography>Ingested At: {formatTimestamp(entity.createdAt)}</Typography>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Suggestions
            </Typography>
            {!suggestions?.length ? (
              <EmptySuggestionsState />
            ) : (
              <FormControl component="fieldset" disabled={disableSuggestions}>
                <RadioGroup
                  value={selectedSuggestion}
                  onChange={(e) => setSelectedSuggestion(e.target.value)}
                >
                  <List>
                    {suggestions.map((suggestion) => (
                      <ListItem key={suggestion.id} disablePadding>
                        <ListItemButton
                          onClick={() => handleSelectSuggestion(suggestion)}
                          disabled={disableSuggestions}
                        >
                          <Radio checked={selectedSuggestion === suggestion.id} />
                          <ListItemText primary={suggestion.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </RadioGroup>
              </FormControl>
            )}

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Or Enter a New Entity Name
            </Typography>
            <TextField
              fullWidth
              value={newEntityName}
              onChange={(e) => {
                setNewEntityName(e.target.value);
                setSelectedSuggestion(null);
              }}
              placeholder="Enter new entity name"
            />
          </DialogContent>
          <DialogActions>
            {submitError && (
              <DialogContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ErrorOutlineIcon color="error" />
                <Typography color="error">{submitError}</Typography>
              </DialogContent>
            )}

            <Button onClick={() => onClose(false)} color="secondary" disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              onClick={handleCreateMapping}
              variant="contained"
              disabled={(!selectedSuggestion && !newEntityName.trim()) || isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {isSubmitting ? 'Processing...' : buttonText}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
