import React from 'react';
import { Card } from '@mui/material';

export const EditPage = () => {
  return (
    <Card
      sx={{
        // 16:9 aspect ratio
        aspectRatio: '16 / 9',
        // This has to be 90% to prevent vertical overflow
        width: '90%',
      }}
    ></Card>
  );
};
