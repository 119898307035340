import { AutoComplete } from '../../../components/AutoComplete';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.palette.mapInfoSection,
    '&.MuiAutocomplete-root .MuiInputLabel-root': {
      paddingTop: '2px',
    },
    '&.MuiAutocomplete-root .MuiFilledInput-root > input': {
      padding: '24px 4px 10px',
    },
  },
  input: {
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    '&.MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
  label: {
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.fontSizes.smallest,
    },
  },
}));

export default ({ displayedItem, loading, data, onChange }) => {
  const classes = useStyles();

  if (!displayedItem || loading || !data || !data.asset || !(data.asset.assetModels.length > 0)) {
    return null;
  }

  const { assetModels, baseName } = data.asset;
  const assetModel = assetModels.find((assetModel) => assetModel.name === displayedItem.name);

  if (!assetModel) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AutoComplete
        styles={classes}
        getData={null}
        menuItems={assetModels}
        onChange={(value) => {
          onChange({
            ...value,
            type: 'asset',
          });
        }}
        value={assetModel}
        label={`${baseName} Models`}
        optionKey="displayName"
        runQueryAfterOnChange={false}
        queryVariable={null}
        multiple={false}
      />
    </div>
  );
};
