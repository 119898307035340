import {
  OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM,
  OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS,
  OPPORTUNITIES_STACK_BY_TYPE_ENUM,
} from '../constants';

import { MA_OPPORTUNITIES_AGGREGATES_STACKED_QUERY } from '../operations';
import { useMaContext } from '../../../MaContext';
import { useQuery } from '@apollo/client';

export const useGetMaOpportunitiesAggregatesStacked = (
  by = OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  stackBy = OPPORTUNITIES_STACK_BY_TYPE_ENUM.SELLER,
  fields = OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS,
  skip = true,
) => {
  const { filterVariables } = useMaContext();
  const { data, loading, error } = useQuery(MA_OPPORTUNITIES_AGGREGATES_STACKED_QUERY, {
    variables: { by, stackBy, fields, filters: { ...filterVariables } },
    skip,
  });

  return {
    data: data?.maOpportunitiesAggregatesStacked?.data || [],
    stacks: data?.maOpportunitiesAggregatesStacked?.stacks || [],
    loading,
    error,
  };
};
