import {
  AdvancedFilterCategories,
  DEVELOPMENT_STATUSES_CHECK_LIST,
  HYDROCARBONS_CHECK_LIST,
  SHORE_STATUSES_CHECK_LIST,
} from '../../../../components/Filters/filterTypes';
import { EFFECTIVE_DATE, GOOGLE_ANALYTICS_TAG, MaFilterKeys } from './constants';
import { FilterBarWrapper, FilterSetsProvider } from '../../../../components/FilterBarWrapper';
import { MA_CONTEXT, useMaContext } from '../../MaContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AdvancedFiltersRow } from '../../../../components/Filters/advancedFilters';
import { FilterList } from '@mui/icons-material';
import { HYDROCARBON_TYPES } from '../../../../utils/constants';
import { PageActions } from '../../../../components/PageActions';
import { getMarks } from './utils';
import { useGetBasicFilters } from './hooks/useGetBasicFilters';
import { useGetDevelopmentStatuses } from './hooks/useGetDevelopmentStatuses';
import { useGetMaFiltersSummary } from './hooks/useGetMaFiltersSummary';
import { useGetShoreStatuses } from './hooks/useGetShoreStatuses';
import { useGetSliderFilterTypes } from './hooks/useGetSliderFilterTypes';
import { useStyles } from './styles';
import { useUpdateFilterRanges } from './hooks/useUpdateFilterRanges';

export const Filters = () => {
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
  const classes = useStyles();
  const { loading, filters, setFilters, setRefresh, resetFilters } = useMaContext();
  const { data, loading: loadingSummary } = useGetMaFiltersSummary();
  const { sliderFilters, sliderMaxValues } = useGetSliderFilterTypes();
  const { shoreStatuses } = useGetShoreStatuses();
  const { developmentStatuses } = useGetDevelopmentStatuses();
  const { basicFilters } = useGetBasicFilters();

  useUpdateFilterRanges();

  useEffect(() => {
    // force re-render of the graph because of height change
    // using setTimeout to avoid flickering
    setTimeout(() => setRefresh(false), 450);
  }, [openAdvancedFilters]);

  const toggleAdvancedFilters = () => {
    // force re-render of the graph because of height change
    // using setTimeout to avoid flickering
    setTimeout(() => setRefresh(true), 400);
    setOpenAdvancedFilters((prev) => !prev);
  };
  const handleChangeValueSlider = useCallback(
    (_, newValue) => {
      setFilters({ ...filters, [MaFilterKeys.dates]: newValue });
    },
    [filters],
  );
  const marks = useMemo(() => getMarks(data?.startYear, data?.endYear, true), [data]);

  const basicFilterTypesWithSlider = useMemo(() => {
    return [
      {
        ...EFFECTIVE_DATE,
        marks,
        onChange: handleChangeValueSlider,
        loading: loading || loadingSummary,
      },
      ...basicFilters,
    ];
  }, [basicFilters, marks, handleChangeValueSlider, loading, loadingSummary]);

  return (
    <FilterSetsProvider filterSetsCount={1}>
      <FilterBarWrapper
        filters={filters}
        growFirstFilter
        applyFilters={setFilters}
        context={MA_CONTEXT}
        googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
        basicFilterTypes={basicFilterTypesWithSlider}
        filterItemClass={classes.filterItem}
        advancedFiltersOpened={openAdvancedFilters}
        advancedFilters={
          <AdvancedFiltersRow
            loading={loading || loadingSummary}
            context={MA_CONTEXT}
            filters={filters}
            setFilters={setFilters}
            googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
            minMaxFilterTypes={[]}
            sliderFiltersLabel="Asset/Block Values"
            sliderFilterTypes={sliderFilters}
            sliderFilterMaxValues={sliderMaxValues}
            checkListAllReturnsNone
            checkListFilterTypes={[
              HYDROCARBONS_CHECK_LIST,
              DEVELOPMENT_STATUSES_CHECK_LIST,
              SHORE_STATUSES_CHECK_LIST,
            ]}
            checkListItems={{
              shoreStatuses,
              developmentStatuses,
              hydrocarbons: HYDROCARBON_TYPES,
            }}
            order={[AdvancedFilterCategories.checkLists, AdvancedFilterCategories.sliders]}
            equalWidth
          />
        }
      >
        <PageActions
          onOpenFilters={toggleAdvancedFilters}
          openFiltersLabel={openAdvancedFilters ? 'Less Filters' : 'All Filters'}
          openFiltersDisabled={loading || loadingSummary}
          openFiltersStartIcon={<FilterList />}
          onClearFilters={resetFilters}
        />
      </FilterBarWrapper>
    </FilterSetsProvider>
  );
};
