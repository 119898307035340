import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    margin: '0 5px',
    aspectRatio: 1,
  },
  dottedLine: {
    borderBottom: '4px dotted',
    margin: '0 5px',
    width: '10px',
  },
  line: {
    borderBottom: '1px solid',
    margin: '0 5px',
    width: '10px',
  },
  oval: {
    height: '10px',
    width: '20px',
    borderRadius: ' 50%',
    margin: '0 5px',
  },
  square: {
    width: '10px',
    height: '10px',
    margin: '0 5px',
  },
  clickable: {
    cursor: 'pointer',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '10px',
    alignItems: 'center',
  },
  invisible: {
    opacity: 0.5,
  },
}));

export default ({
  border = 'solid thin black',
  color,
  shape = 'circle',
  styles = {},
  title,
  clickCallback = () => {},
  visible = true,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.legendItem]: true,
        [classes.clickable]: !!clickCallback,
        [styles?.legendItem]: styles?.legendItem,
        [classes.invisible]: visible !== true, // plotly uses values that are truthy when it should be seen as false
      })}
      onClick={() => clickCallback(title)}
    >
      <div
        className={clsx({ [classes[shape]]: true })}
        style={{ backgroundColor: color, border }}
      />
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};
