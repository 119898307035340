import { FormGroup, Typography } from '@mui/material';
import React, { useState } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputList from '../../InputList';

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.palette.alternateText,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,

    '& > .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  item: {
    color: theme.palette.baseColors.skyBlue.c75,
    '&.Mui-checked': {
      color: theme.palette.baseColors.skyBlue.c75,
    },
  },
  minMaxContainer: {
    ...theme.palette.altScrollbarBlue,
    overflow: 'auto',
    padding: '5px 15px 0',
    flexWrap: 'nowrap',
  },
}));

const MinMaxFilter = ({ filters, handleSetState }) => {
  const classes = useStyles();
  const [filterState, setFilterState] = useState(
    filters?.reduce((acc, item) => ({ ...acc, [item.min.key]: '', [item.max.key]: '' }), {}),
  );

  const onSetDefaultValues = (item) => {
    const minValue = { [item.min?.key]: item.min?.default };
    const maxValue = { [item.max?.key]: item.max?.default };
    setFilterState({ ...filterState, ...minValue, ...maxValue });
    handleSetState({ ...minValue, ...maxValue });
  };
  const handleSetFilterState = (value, name) => {
    setFilterState({ ...filterState, [name]: value });
    handleSetState({ [name]: value });
  };

  if (!filters || !filters.length || !filters[0]) {
    return null;
  }

  return (
    <Box width={'100%'}>
      <Typography className={classes.label} variant="overline">
        Limits
      </Typography>
      <FormGroup>
        <div className={classes.minMaxContainer}>
          <InputList
            values={filterState}
            setInput={handleSetFilterState}
            items={filters}
            onSetDefaultValues={onSetDefaultValues}
          />
        </div>
      </FormGroup>
    </Box>
  );
};

export default MinMaxFilter;
