import { Box, Tab, Tabs } from '@mui/material';
import { MaProvider, useMaContext } from './MaContext';
import React, { useEffect } from 'react';

import { Filters } from './components/Filters';
import { Notes } from './components/Notes';
import { TABS } from './constants';
import { useFeatureFlags } from '../../contexts/FeatureFlagsContext';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';

const MaPageInner = () => {
  const classes = useStyles();
  const { currentTab, updateTab } = useMaContext();
  const location = useLocation();
  const { maOpportunitiesTab } = useFeatureFlags();

  const tabs = maOpportunitiesTab ? TABS : TABS.filter((tab) => tab?.tag !== 'opportunities');

  const onTabChange = (newValue) => {
    updateTab(newValue);
    // update URL hash
    window.location.hash = newValue;
  };

  useEffect(() => {
    // restore tab from URL hash
    const tag = location.hash.replace('#', '');
    // find the tab with the matching tag
    const tab = tabs.find((tab) => tab?.tag === tag);
    onTabChange(tab?.tag || TABS[0].tag);
  }, [location.hash]);

  // if no tab is selected, show nothing
  if (currentTab === null) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Filters />
        <Box className={classes.contentWrapper}>
          <Tabs className={classes.tabs} value={currentTab} onChange={(_, tab) => onTabChange(tab)}>
            {tabs.map((tab, index) => (
              <Tab className={classes.tab} key={index} label={tab.label} value={tab.tag} />
            ))}
          </Tabs>

          {tabs.map((tab, index) => currentTab === tab.tag && <tab.component key={index} />)}
        </Box>
      </Box>
      <Notes />
    </Box>
  );
};

export const MaPage = () => (
  <MaProvider>
    <MaPageInner />
  </MaProvider>
);
