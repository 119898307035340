import React, { createContext, useState } from 'react';

const MapFilterContext = createContext();

const defaultFilters = {
  country: [],
  basin: [],
  hydrocarbonType: [],
  shoreStatus: [],
  company: [],
  prodMin: '',
  prodMax: '',
  npvMin: '',
  npvMax: '',
  reservesMin: '',
  reservesMax: '',
};

export const MapFilterProvider = (props) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [operatorOnly, setOperatorOnly] = useState(false);
  const [opportunities, setOpportunities] = useState(false);
  const [showEaWells, setShowEaWells] = useState(true);
  const [secondaryLegendValues, setSecondaryLegendValues] = useState(null);

  const resetState = () => {
    setOperatorOnly(false);
    setOpportunities(false);
    setFilters(defaultFilters);
  };

  // This method is used to determine if it's the first filter applied; used for zooming
  // to the country if no other filters are applied:
  const isFirstFilter = () => {
    return Object.values(filters).every((f) => f.length === 0) && !opportunities && showEaWells;
  };

  const toggleOperatorOnly = (event) => {
    setOperatorOnly(event.target.checked);
  };

  const variables = {
    country: filters.country.map((a) => a.id),
    countryIsoCodes: filters.country.map((a) => a.isoCode),
    companies: filters.company,
    company: filters.company.map((a) => a.id),
    companyNames: filters.company.map((a) => a.name),
    basin: filters.basin.map((a) => a.id),
    basins: filters.basin,
    basinNames: filters.basin.map((a) => a.name),
    shoreStatus: filters.shoreStatus.map((a) => a.id),
    shoreStatusNames: filters.shoreStatus.map((a) => a.name),
    hydrocarbonType: filters.hydrocarbonType.map((a) => a.name.toLowerCase()),
    prodMin: filters.prodMin ? parseFloat(filters.prodMin) : null,
    prodMax: filters.prodMax ? parseFloat(filters.prodMax) : null,
    npvMin: filters.npvMin ? parseFloat(filters.npvMin) : null,
    npvMax: filters.npvMax ? parseFloat(filters.npvMax) : null,
    operator: operatorOnly,
    reservesMin: filters.reservesMin ? parseFloat(filters.reservesMin) : null,
    reservesMax: filters.reservesMax ? parseFloat(filters.reservesMax) : null,
    opportunities,
    eaWells: showEaWells,
    hasAssets: false,
  };

  const propVariables = {
    countries: variables.countryIsoCodes,
    companies: variables.companyNames,
    basins: variables.basinNames,
    operator: variables.operator,
  };

  return (
    <MapFilterContext.Provider
      value={{
        filters,
        isFirstFilter,
        propVariables,
        operatorOnly,
        opportunities,
        resetState,
        setFilters,
        setOpportunities,
        toggleOperatorOnly,
        variables,
        showEaWells,
        setShowEaWells,
        secondaryLegendValues,
        setSecondaryLegendValues,
      }}
    >
      {props.children}
    </MapFilterContext.Provider>
  );
};

export const MapFilterConsumer = MapFilterContext.Consumer;

export default MapFilterContext;
