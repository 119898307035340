import React, { useEffect, useState } from 'react';
import { PageShell } from '../../../PageShell';
import { useQuery } from '@apollo/client';
import { GET_JOBS_QUERY, GET_JOBS_FILTERS_QUERY } from '../../operations';
import { styles } from './styles';
import { formatTimestamp } from '../../../../../../utils/helpers';
import { truncate, titleize } from '../../../../../../utils/helpers';
import { JobModal } from '../JobModal/JobModal';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const LoadingState = () => {
  return (
    <Box sx={styles.loadingContainer}>
      <CircularProgress size={40} sx={styles.spinner} />
      <Typography sx={styles.loadingText}>Loading...</Typography>
    </Box>
  );
};

const ErrorState = ({ error, onRetry }) => {
  return (
    <Box sx={styles.errorContainer}>
      <ErrorOutlineIcon sx={styles.errorIcon} />
      <Typography sx={styles.errorText}>Something went wrong</Typography>
      <Typography sx={styles.errorMessage}>{error.message}</Typography>
      <Button variant="contained" color="primary" onClick={onRetry} sx={styles.retryButton}>
        Try Again
      </Button>
    </Box>
  );
};

const Filter = ({ onApply }) => {
  const [status, setStatus] = useState("all");
  const [step, setStep] = useState("all");

  const { data, loading, error, refetch } = useQuery(GET_JOBS_FILTERS_QUERY);

  const filters = data?.nexusJobsFilters;

  const handleFilterChange = () => {
    onApply({
      status: status === 'all' ? null : status,
      step: step === 'all' ? null : step,
    });
  };

  return (
    <Box sx={styles.filterContainer}>
      {error && <ErrorState error={error} onRetry={() => refetch()}></ErrorState>}
      {loading && <LoadingState />}

      {filters?.jobStatuses && (
        <FormControl sx={styles.select}>
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="all">
              <em>All</em>
            </MenuItem>
            {filters?.jobStatuses?.map((key) => (
              <MenuItem key={key} value={key} sx={styles.menuItem}>
                {titleize(key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {filters?.steps && (
        <FormControl sx={styles.select}>
          <InputLabel>Step</InputLabel>
          <Select value={step} onChange={(e) => setStep(e.target.value)}>
            <MenuItem value="all">
              <em>All</em>
            </MenuItem>
            {filters?.steps?.map((key) => (
              <MenuItem key={key} value={key}>
                {titleize(key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {filters && (
        <Button variant="contained" color="primary" onClick={handleFilterChange} sx={styles.button}>
          Apply Filters
        </Button>
      )}
    </Box>
  );
};

const ListItem = ({ job, onRowClick }) => {
  return (
    <TableRow key={job.id} onClick={onRowClick} sx={styles.tableRow}>
      <TableCell>{job.id}</TableCell>
      <TableCell>{job.status}</TableCell>
      <TableCell>{job.step}</TableCell>
      <TableCell>{job.region}</TableCell>
      <TableCell>{job.areaCode}</TableCell>
      <TableCell>{formatTimestamp(job.createdAt)}</TableCell>
      <TableCell>{formatTimestamp(job.updatedAt)}</TableCell>
      <TableCell>{truncate(job.message, 100)}</TableCell>
      <TableCell>{job.previousJobId}</TableCell>
    </TableRow>
  );
};

const List = ({ jobs, onRowClick }) => (
  <TableContainer component={Paper} sx={styles.tableContainer}>
    <Table>
      <TableHead sx={styles.tableHeader}>
        <TableRow sx={styles.tableRow}>
          <TableCell>ID</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Step</TableCell>
          <TableCell>Region</TableCell>
          <TableCell>Area Code</TableCell>
          <TableCell>Created At</TableCell>
          <TableCell>Updated At</TableCell>
          <TableCell>Message</TableCell>
          <TableCell>Previous job ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {jobs?.map((job) => (
          <ListItem key={job.id} job={job} onRowClick={() => onRowClick(job)} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const JobStatusList = () => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedJobId, setSelectedJobId] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_JOBS_QUERY, {
    variables: selectedFilters,
  });
  const jobs = data?.nexusJobs || [];

  const handleFilterChange = (newFilters) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };

      const params = new URLSearchParams(
        Object.entries(updatedFilters).filter(([, value]) => value !== null && value !== ''),
      );
      window.history.replaceState(null, '', `?${params.toString()}`);

      return updatedFilters;
    });
  };

  useEffect(() => {
    refetch(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    return () => {
      setSelectedFilters({});
      setSelectedJobId(null);
    };
  }, []);

  return (
    <PageShell title="Nexus - Job Status">
      <Filter onApply={handleFilterChange} />
      {error && <ErrorState error={error} onRetry={() => refetch(selectedFilters)}></ErrorState>}
      {jobs && <List jobs={jobs} onRowClick={(job) => setSelectedJobId(job.id)}  />}
      {loading && <LoadingState />}
      {selectedJobId && (
        <JobModal
          jobId={selectedJobId}
          open={() => Boolean(selectedJobId)}
          onClose={() => setSelectedJobId(null)}>
        </JobModal>
      )}
    </PageShell>
  );
};