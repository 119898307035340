export const styles = {
  button: {
    fontSize: '0.9rem',
    borderRadius: '1.5em',
    padding: '0.5em 1.5em',
    textTransform: 'none',
  },
  tableContainer: {
    marginTop: '1em',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: (theme) => theme.palette.grey[100],
    },
  },
  tableProperty: {
    backgroundColor: '#ccc',
    minWidth: '150px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
  },
  spinner: {
    color: (theme) => theme.palette.primary.main,
    marginBottom: '1em',
  },
  loadingText: {
    fontSize: '1.2rem',
    color: (theme) => theme.palette.text.secondary,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
    textAlign: 'center',
  },
  errorIcon: {
    fontSize: '3rem',
    color: 'red',
    marginBottom: '0.5em',
  },
  errorText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: (theme) => theme.palette.error.main,
  },
  errorMessage: {
    fontSize: '1rem',
    color: (theme) => theme.palette.text.secondary,
    marginBottom: '1em',
  },
};