import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { Button, Grid2 as Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { ADMINS_SMART_REPORTS_PATH } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: '1em',
    fontSize: '1rem',
    borderRadius: '2em',
    padding: '0.5em 2em',
    textTransform: 'none',
    maxHeight: 'fit-content',
  },
  backButton: {
    display: 'flex',
    textDecoration: 'none',
    width: 'fit-content',
    color: theme.palette.baseColors.deepSeaNavy.c50,
  },
  subheader: {
    whiteSpace: 'pre-line',
    color: theme.palette.baseColors.deepSeaNavy.c50,
  },
}));

export const EditReportHeader = ({ name, reportType, save, publish }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item size={12} display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
        <ArrowBack className={classes.backButton} />
        <Typography
          className={classes.backButton}
          variant="overline"
          component={Link}
          to={ADMINS_SMART_REPORTS_PATH}
        >
          BACK TO REPORTS
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography className={classes.reportsHeader} variant="h4">
            {name}
          </Typography>
        </Grid>
        <Grid container item spacing={2}>
          <Button className={classes.button} variant="contained" onClick={save}>
            Save Draft
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={publish}
          >
            Publish
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Typography className={classes.subheader} variant="caption">
          {reportType}
        </Typography>
      </Grid>
    </>
  );
};
