export const joinGqlErrorMessage = (graphQLErrors) => {
  let errMsgs = [];
  graphQLErrors?.forEach((gqlErr) => {
    errMsgs.push(
      gqlErr.extensions?.detailed_errors
        ? gqlErr.extensions.detailed_errors.join(' ')
        : gqlErr.message,
    );
  });

  if (errMsgs.length === 0) {
    return 'Something went wrong, please try again later.';
  }

  return `${errMsgs.join(' ')}`;
};
