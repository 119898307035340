import { gql } from '@apollo/client';

export const GET_JOBS_QUERY = gql`
  query nexusJobs($areaCode: String, $limit: Int = 10, $page: Int = 1, $region: String, $startedBy: String, $status: String, $step: String) {
    nexusJobs(
      areaCode: $areaCode
      limit: $limit
      page: $page
      region: $region
      startedBy: $startedBy
      status: $status
      step: $step
    ) {
      id
      status
      step
      region
      areaCode
      createdAt
      updatedAt
      message
      previousJobId
    }
  }
`;

export const GET_JOBS_FILTERS_QUERY = gql`
  query nexusJobsFilters {
    nexusJobsFilters {
      jobStatuses
      steps
    }
  }
`;

export const GET_JOB_QUERY = gql`
  query nexusJob($jobId: String!) {
    nexusJob(jobId: $jobId) {
      id
      status
      step
      region
      areaCode
      createdAt
      updatedAt
      message
      previousJobId
    }
  }
`;