import { PATHS } from './constants';

export const getLatLongFromPoint = (point) => {
  if (!point) return '';
  const [lat, lng] = point
    .replace(/[^\d .-]/g, '')
    .trim()
    .split(/\s+/);
  return [lat, lng];
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';
  return new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
  });
};

export const formatDate = (date, isUpperCase = true) => {
  if (!date) return null;
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  });
  return isUpperCase ? formattedDate.toUpperCase() : formattedDate;
};

export const formatDateShort = (date) =>
  date
    ? new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        timeZone: 'UTC',
      })
    : null;

export const formatDateYear = (date) =>
  date
    ? new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        timeZone: 'UTC',
      })
    : null;

export const formatNumberFromString = (num, decimalPlaces = 2) => {
  let decimal = num.split('.');
  if ((decimal && decimal.length > 2) || decimal[1]?.length > decimalPlaces) {
    return `${decimal[0]}.${decimal[1].slice(decimalPlaces)}`;
  }
  return num;
};

export const formatPrimaryHydrocarbon = (ph) => {
  const labels = {
    conventional_oil: 'Conventional Oil',
    conventional_gas: 'Conventional Gas',
    shale_gas: 'Shale Gas',
    tight_gas: 'Tight Gas',
    tight_oil: 'Tight Oil',
  };

  if (labels[ph] !== undefined) return labels[ph];
  return ph;
};

export const sortAToZ = (arr, key) =>
  arr.sort((a, b) => {
    const nameA = a[key].toUpperCase();
    const nameB = b[key].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

export const sortZToA = (arr, key) =>
  arr.sort((a, b) => {
    const nameA = a[key].toUpperCase();
    const nameB = b[key].toUpperCase();
    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }

    return 0;
  });

export const sortMinToMax = (arr, key) =>
  arr.sort((a, b) => {
    const numA = a[key];
    const numB = b[key];
    return numA - numB;
  });

export const sortMaxToMin = (arr, key) =>
  arr.sort((a, b) => {
    const numA = a[key];
    const numB = b[key];
    return numB - numA;
  });

export const nestedSortMinToMax = (arr, keys) =>
  arr.sort((a, b) => {
    let attributeA = a,
      attributeB = b;
    keys.forEach((key) => {
      attributeA = attributeA[key];
      attributeB = attributeB[key];
    });
    return attributeA - attributeB;
  });

export const nestedSortMaxToMin = (arr, keys) =>
  arr.sort((a, b) => {
    let attributeA = a,
      attributeB = b;
    keys.forEach((key) => {
      attributeA = attributeA[key];
      attributeB = attributeB[key];
    });
    return attributeB - attributeA;
  });

export const titleize = (str) =>
  str
    ? str
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    : null;

export const titleizeAlt = (str, delimiter) =>
  str && delimiter
    ? str
        .split(delimiter)
        .map((w) => titleize(w))
        .join(delimiter)
    : null;

export const snakeToCamel = (str) =>
  str?.replace(/_([a-zA-Z])/g, (_match, letter) => letter.toUpperCase());

export const snakeToTitle = (str) =>
  str
    ? titleize(
        str.replace(/([-_][a-z])/g, (group) =>
          group.toUpperCase().replace('-', ' ').replace('_', ' '),
        ),
      )
    : null;

/**
 * Convert all keys in an object from snake_case to camelCase
 * @param {Object} obj - Object to convert
 * @returns {Object} - Object with keys converted to camelCase
 */
export const objKeysFromSnakeToCamel = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => objKeysFromSnakeToCamel(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = snakeToCamel(key);
      acc[camelKey] = objKeysFromSnakeToCamel(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

export const roundAndFormat = (num, maxDecimalPlace = 1, minDecimalPlace = 0) =>
  num
    ? num.toLocaleString(undefined, {
        maximumFractionDigits: maxDecimalPlace,
        minimumFractionDigits: minDecimalPlace,
      })
    : '';

export const removeDoubleNegative = (str) => {
  const parts = str.split('-');
  if (parts[0].length > 0) {
    return parts.join('');
  } else if (parts.length > 2) {
    return `-${parts.slice(1).join('')}`;
  }
  return str;
};

export const curateLocation = (location) => {
  if (!location) return '';
  return PATHS.find((path) => location.startsWith(path));
};

export const isNumber = (value) => !Number.isNaN(parseFloat(value));

export const truncate = (str, length) => {
  if (str?.length > length) {
    return `${str.substring(0, length)}...`;
  }

  return str;
};

export const camelCaseToHumanReadable = (str) => {
  if (!str) return '';

  const words = str.match(/[A-Za-z][a-z]*/g) || [];
  return words.map(titleize).join(" ");
};