import { gql } from '@apollo/client';

export const MAP_PRODUCTION_QUERY = gql`
  query mapProduction($filters: JSON, $hydrocarbonType: String!, $layer: String!) {
    mapProduction(filters: $filters, hydrocarbonType: $hydrocarbonType, layer: $layer) {
      feature
      layer
    }
  }
`;

export const MAP_RESERVES_QUERY = gql`
  query mapReserves($filters: JSON, $hydrocarbonType: String!, $layer: String!) {
    mapReserves(filters: $filters, hydrocarbonType: $hydrocarbonType, layer: $layer) {
      feature
      layer
    }
  }
`;

export const MAP_NPV_QUERY = gql`
  query mapNpv($filters: JSON, $layer: String!) {
    mapNpv(filters: $filters, layer: $layer) {
      feature
      layer
    }
  }
`;

export const MAP_COMPANIES_QUERY = gql`
  query mapCompanies($filters: JSON, $layer: String!) {
    mapCompanies(filters: $filters, layer: $layer) {
      feature
      layer
    }
  }
`;

export const getQuery = (layer) => {
  switch (layer) {
    case 'totalProductionHeatMap':
    case 'oilAndCondensateProductionHeatMap':
    case 'gasProductionHeatMap':
      return MAP_PRODUCTION_QUERY;
    case 'totalReservesHeatMap':
    case 'oilAndCondensateReservesHeatMap':
    case 'gasReservesHeatMap':
      return MAP_RESERVES_QUERY;
    case 'npvHeatMap':
      return MAP_NPV_QUERY;
    case 'operatorsHeatMap':
    case 'participantsHeatMap':
      return MAP_COMPANIES_QUERY;
    // case 'productionBubbleMap':
    //   return COUNTRY_HEAT_MAP_QUERY;
    // case 'reservesBubbleMap':
    //   return COUNTRY_HEAT_MAP_QUERY;
    default:
      return MAP_PRODUCTION_QUERY;
  }
};
