import { Book, Chat } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { Divider } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { requestTrainingMailTo } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    padding: '6px 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.baseColors.deepSeaNavy.c100,

    '&.MuiButton-sizeMedium > .MuiButton-icon': {
      padding: '9px',
      borderRadius: '50px',
    },
  },
  trainingButton: {
    '&.MuiButton-sizeMedium > .MuiButton-icon': {
      background: theme.palette.baseColors.deepSeaNavy.c100,
      color: theme.palette.baseColors.white.c100,
    },
  },
  chatButton: {
    color: theme.palette.baseColors.fireOrange.c100,
    '&.MuiButton-sizeMedium > .MuiButton-icon': {
      ...theme.palette.actionCustom,
    },
  },
  info: {
    textTransform: 'none',
  },
  contact: {
    ...theme.palette.accentText,
    fontSize: '10px',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div>
      <Divider />
      <Button
        className={clsx(classes.button, classes.trainingButton)}
        href={requestTrainingMailTo}
        endIcon={<Book fontSize="small" />}
      >
        <Typography fontWeight="medium">Request training</Typography>
      </Button>

      <Button
        className={clsx(classes.button, classes.chatButton)}
        href={'mailto:info@welligence.com'}
        endIcon={<Chat fontSize="small" />}
      >
        <Typography fontWeight="medium">Customer Support</Typography>
      </Button>
    </div>
  );
};
