import makeStyles from '@mui/styles/makeStyles';

export const useFeedbackPanelStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: '30px',
    right: '0px',
    width: '250px',
    maxHeight: '250px',
    borderRadius: '12px',
    padding: '16px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    zIndex: 1000,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  leftIcon: {
    color: '#1A1A1A',
    position: 'absolute',
    left: 0,
  },
  rightIcon: {
    color: '#1A1A1A',
    position: 'absolute',
    right: 0,
  },
  title: {
    fontWeight: 'bold',
    color: '#1A1A1A',
  },
  divider: {
    margin: '12px 0',
  },
  label: {
    color: '#4A4A4A',
    marginBottom: '8px',
  },
  optionButton: {
    padding: '10px 14px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
  },
  textField: {
    marginBottom: '12px',
  },
  button: {
    ...theme.palette.button,
  },
}));
