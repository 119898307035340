import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    maxWidth: '380px',
    width: '100%',
  },
  card: {
    '&:hover': {
      boxShadow: theme.shadows[10],
    },
  },
}));
