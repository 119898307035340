import { Divider, Typography } from '@mui/material';

import Grid from '@mui/material/Grid2';
import React from 'react';
import { useAdminsPageOptions } from '../../hooks/useAdminsPageOptions';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

const GridItem = ({ label, description, icon: Icon, route }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      item="true"
      className={classes.item}
      size={{ md: 6, sm: 12 }}
      onClick={() => history.push(route)}
    >
      <Grid container spacing={2}>
        <Grid item="true">
          <Icon className={classes.icon} />
        </Grid>
        <Grid item="true" size={10}>
          <Typography variant="h6">{label}</Typography>
          <Divider />
          <Typography variant="caption">{description}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AdminsPage = () => {
  const classes = useStyles();
  const adminsPageOptions = useAdminsPageOptions();

  return (
    <div className={classes.root}>
      <div className={classes.scroll}>
        <Grid container spacing={2} className={classes.landingPageContainer}>
          <Grid size={12}>
            <Typography variant="h4" className={classes.header}>
              Admins Portal
            </Typography>
            <Divider />
          </Grid>
          {adminsPageOptions.map((option) =>
            option.hide ? null : <GridItem key={option.label} {...option} />,
          )}
        </Grid>
      </div>
    </div>
  );
};
