import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styles } from './styles';
import { formatTimestamp, camelCaseToHumanReadable } from '../../../../../../utils/helpers';
import { GET_JOB_QUERY } from '../../operations';
import { useQuery } from '@apollo/client';

const LoadingState = () => (
  <DialogContent sx={styles.loadingContainer}>
    <CircularProgress sx={styles.spinner} />
    <Typography sx={styles.loadingText}>Loading job details...</Typography>
  </DialogContent>
);

const ErrorState = ({ error }) => (
  <DialogContent sx={styles.errorContainer}>
    <ErrorOutlineIcon color="error" sx={styles.errorIcon} />
    <Typography color="error" sx={styles.errorText}>
      {error.message}
    </Typography>
  </DialogContent>
);

export const JobDetails = ({ job }) => {
  const format = (str) => {
    if (str?.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/)) {
      return formatTimestamp(str);
    }

    return str;
  };

  // eslint-disable-next-line no-unused-vars
  const { __typename, ...cleanJob } = job;

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table>
        <TableBody>
          {Object.keys(cleanJob).map((key) => (
            <TableRow key={`${job.id}-${key}`} sx={styles.tableRow}>
              <TableCell sx={styles.tableProperty}>{camelCaseToHumanReadable(key)}</TableCell>
              <TableCell>{format(job[key])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const JobModal = ({jobId, open, onClose}) => {
  const { data, loading, error } = useQuery(GET_JOB_QUERY, {
    variables: { jobId },
  });

  const job = data?.nexusJob;

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth='none' fullWidth>
      <DialogTitle>Job Details</DialogTitle>
      {loading ? (
        <LoadingState />
      ) : error ? (
        <ErrorState error={error} />
      ) : (
        <>
          <DialogContent sx={{ maxWidth: 'none' }}>
            <JobDetails job={job} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)} color="secondary" sx={styles.button}>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};