import React, { useContext, useRef, useState } from 'react';

import { GOOGLE_ANALYTICS_TAG } from '../../constants';
import GraphContainer from '../../../../components/Graphs/graphContainer';
import Legend from '../../../../components/Legend/legend';
import MonetaryLineGraphContext from './MonetaryLineGraphContext';
import Plotly from '../../../../components/Graphs/plotly';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  legendRoot: {
    flexDirection: 'column',
    backgroundColor: '#ecf0f5',
    height: '100%',
    width: '15%',
    padding: 0,
  },
  legendTitle: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingLeft: '5px',
    border: 'solid rgba(0, 0, 0, 0.12) thin',
    backgroundColor: 'white',
    width: '100%',
    height: '36px',
    [theme.breakpoints.down('lg')]: {
      height: '24px',
    },
  },
  legendItem: {
    padding: '5px',
  },
}));

const MonetaryLineGraph = ({
  open,
  setOpen,
  setClose,
  showLegend,
  range,
  consolidateAssetsProps,
  portfolios,
}) => {
  const classes = useStyles();
  const [showPlotlyLegend, setShowPlotlyLegend] = useState(true);
  const graphContainerRef = useRef(null);

  const {
    title,
    setOpenControlsModal,
    trace,
    loading,
    stacked,
    setStacked,
    downloadCallback,
    onGraphUpdate,
    onInitialized,
    onAfterPlot,
    onLegendDoubleClick,
    legendItems,
    onRelayout,
    maxY,
    discountRate,
    handleTraceToggle,
    setDiscountRate,
  } = useContext(MonetaryLineGraphContext);

  return (
    <div ref={graphContainerRef} className={classes.root}>
      {showLegend ? (
        <Legend
          vertical
          clickCallback={handleTraceToggle}
          items={legendItems}
          title="CASH FLOW"
          styles={classes}
        />
      ) : null}
      <GraphContainer
        open={open}
        setClose={setClose}
        setOpen={setOpen}
        setOpenControlsModal={setOpenControlsModal}
        showLineToggle
        showPlotlyLegendToggle={!showLegend}
        showPlotlyLegend={showPlotlyLegend}
        setShowPlotlyLegend={setShowPlotlyLegend}
        stacked={stacked}
        setStacked={setStacked}
        title={title}
        loading={loading}
        downloadCallback={downloadCallback}
        consolidateAssetsProps={consolidateAssetsProps}
        portfolios={portfolios}
        googleAnalyticsTag={GOOGLE_ANALYTICS_TAG}
        discountRate={discountRate}
        setDiscountRate={setDiscountRate}
      >
        <Plotly
          data={trace}
          layout={{
            margin: { l: 50, r: 30, b: 30, t: 30 },
            xaxis: {
              range: range.x,
              tickformat: 'd',
            },
            yaxis: {
              range: range.y,
            },
            // This legend refers to plotly legend.
            // Hide when we show our custom legend.
            showlegend: !showLegend && showPlotlyLegend,
            legend: {
              x: 1,
              xanchor: 'right',
              y: 1,
              bgcolor: 'rgba(236, 240, 245, 0.8)',
            },
          }}
          maxY={maxY}
          loading={loading}
          onRelayout={onRelayout}
          onInitialized={onInitialized}
          onAfterPlot={onAfterPlot}
          onLegendDoubleClick={onLegendDoubleClick}
          onUpdate={showLegend ? onGraphUpdate : () => {}}
        />
      </GraphContainer>
    </div>
  );
};

export default MonetaryLineGraph;
