export const formatTwoDecimals = (params) => {
  const { value } = params;
  if (value === null || value === undefined) {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatAsInt = (params) => {
  const { value } = params;
  if (value === null || value === undefined) {
    return '';
  }
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value);
};
