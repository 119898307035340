import React, { useContext, useState } from 'react';

import Grid from '@mui/material/Grid2';
import LineGraph from './lineGraph';
import LineGraphContextLeft from './LineGraphContextLeft';
import LineGraphContextRight from './LineGraphContextRight';
import Modal from '../../../../components/Modals';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  graph: {
    display: 'flex',
    flex: '1 1 auto',
    minHeight: 0,
  },
  modal: {
    backgroundColor: 'white',
    height: '85%',
    width: '90%',
    maxHeight: '600px',
    maxWidth: '1400px',
  },
  half: {
    height: `50%`,
  },
  graphContainer: {
    height: `calc(50% - ${theme.spacing(1)})`,
  },
}));

export default ({ portfolios }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    range: leftRange,
    modalRange: leftModalRange,
    setModalRange: leftSetModalRange,
  } = useContext(LineGraphContextLeft);
  const {
    range: rightRange,
    modalRange: rightModalRange,
    setModalRange: rightSetModalRange,
  } = useContext(LineGraphContextRight);

  const closeFullscreen = () => {
    setOpen(false);
    leftSetModalRange({ x: [], y: [] });
    rightSetModalRange({ x: [], y: [] });
  };

  return (
    <>
      <Grid id="ghg-line-graphs" size={12} className={classes.graphContainer}>
        <LineGraph
          context={LineGraphContextLeft}
          open={open}
          setOpen={() => setOpen(true)}
          range={leftRange}
          appBarId="ghg-graph-app-bar"
        />
      </Grid>
      <Grid size={12} className={classes.graphContainer}>
        <LineGraph
          context={LineGraphContextRight}
          open={open}
          setOpen={() => setOpen(true)}
          range={rightRange}
          portfolios={portfolios}
        />
      </Grid>
      <Modal
        handleClose={closeFullscreen}
        open={open}
        title="Chart Expansion"
        titleAlignedWithClose={true}
        styles={classes}
        disablePortal
      >
        <Grid container className={classes.root}>
          <Grid container className={classes.graph} direction="column">
            <div className={classes.half}>
              <LineGraph
                context={LineGraphContextLeft}
                open={open}
                setClose={closeFullscreen}
                showLegend
                range={leftModalRange}
              />
            </div>
            <div className={classes.half}>
              <LineGraph
                context={LineGraphContextRight}
                open={open}
                setClose={closeFullscreen}
                showLegend
                range={rightModalRange}
                portfolios={portfolios}
              />
            </div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
