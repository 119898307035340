import { titleize } from '../../utils/helpers';

export const getGraphLayout = ({
  showLegend,
  yAxisTitle,
  altYAxisTitle,
  xAxisType = 'category',
}) => ({
  hoverlabel: { align: 'left' },
  annotations: [
    {
      xref: 'paper',
      yref: 'paper',
      x: 1,
      xanchor: 'right',
      y: 1,
      yanchor: 'bottom',
      text: 'Source: Welligence Energy Analytics',
      showarrow: false,
      font: {
        color: 'lightgrey',
        size: 10,
      },
    },
  ],
  margin: {
    l: yAxisTitle ? 65 : 50,
    r: altYAxisTitle ? 65 : 15,
    b: 60,
    t: 30,
  },
  xaxis: {
    type: xAxisType,
    tickangle: 'auto',
    automargin: true,
  },
  barmode: 'stack',
  legend: {
    x: 1,
    xanchor: 'right',
    y: 1,
    bgcolor: 'rgba(236, 240, 245, 0.8)',
    traceorder: 'normal',
  },
  showLegend,
  yaxis: {
    ...(yAxisTitle && {
      title: { text: yAxisTitle },
    }),
    standoff: 10,
    automargin: true,
    tickformat: ',d',
    rangemode: 'tozero',
    zeroline: true,
  },
  ...(altYAxisTitle && {
    yaxis2: {
      title: {
        text: altYAxisTitle,
        standoff: 10,
      },
      overlaying: 'y',
      side: 'right',
      tickformat: ',d',
      rangemode: 'tozero',
    },
  }),
});

export const getChartOptions = (data = []) => {
  return data.map((d) => ({
    value: d,
    label: titleize(d),
  }));
};

const MIN_SCATTER_MARKER_SIZE = 6;
const MAX_SCATTER_MARKER_SIZE = 14;

export const getScatterMarkerSize = (data) => {
  const dataLength = data?.length || 0;
  // scale marker size based on number of data points, but never let it go lower than min, or larger than max
  const markerSize = Math.ceil(MAX_SCATTER_MARKER_SIZE / (dataLength / 10));
  return Math.min(Math.max(MIN_SCATTER_MARKER_SIZE, markerSize), MAX_SCATTER_MARKER_SIZE);
};

export const truncate = (str, length) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }

  return str;
};
