import { gql } from '@apollo/client';

// aside from id and legacyId, the fields are sorted alphabetically
const ASSETS_FIELDS = `
  id
  legacyId
  basin {
    name
  }
  breakevenOil
  country {
    id
    displayName
    name
    isoCode
    haveLiquidsReserves
  }
  currentDate
  developmentStatus {
    name
  }
  developmentType {
    name
  }
  discoveryDate
  displayName
  estimatedCurrentDate
  firstProductionDate
  fiscalRegime
  formattedCurYrTotalProdIntensityKgco2ePerBoe
  formattedEstimatedRecentProdGasMmcfd
  formattedEstimatedRecentProdLiqBbld
  formattedGasEurBcf
  formattedGasIpMmcfd
  formattedLiquidsEurMmbbl
  formattedLiquidsIpBbld
  formattedNpv
  formattedRecentProdGasMmcfd
  formattedRecentProdLiquidsBbld
  formattedRemainingCapex
  formattedRemainingReserves
  formattedWeaProdGasMmcfd
  formattedWeaProdLiquidsBbld
  geom
  hydrocarbonType
  isHpht
  licenseExpirationDate
  mAndAOpportunity
  name
  participations {
    effectiveDate
    formattedPercentStake
    percentStake
    isCompanyOperator
    company {
      name
    }
    mAndAOpportunity
  }
  peakProductionDate
  reportUpdatedDate
  reserve {
    formattedTotalRemainingReservesLiquidsMmbbl
    formattedTotalRemainingReservesOilMmbbl
    formattedTotalRemainingReservesGasBcf
  }
  shoreStatus {
    name
  }
  similarAssets
`;

export const ASSETS_QUERY = gql`
  query assetsConnection(
    $assetLegacyIds: [ID!]
    $assetIsoCodes: [String!]
    $country: [String!]
    $company: [String!]
    $basin: [String!]
    $developmentStatus: [ID!]
    $developmentType: [ID!]
    $shoreStatus: [String!]
    $operator: Boolean
    $opportunities: Boolean
    $geologyEpochIds: [ID!]
    $hydrocarbonType: [String!]
    $includeHistoricalParticipants: Boolean
    $oilType: [String!]
    $npvMin: Float
    $npvMax: Float
    $reservesMin: Float
    $reservesMax: Float
    $productionMin: Float
    $productionMax: Float
    $depthMin: Float
    $depthMax: Float
    $isWalkthrough: Boolean
    $sortType: String!
    $sortAscending: Boolean!
    $first: Int
    $after: String
  ) {
    assetsConnection(
      assetLegacyIds: $assetLegacyIds
      assetIsoCodes: $assetIsoCodes
      country: $country
      company: $company
      basin: $basin
      developmentStatus: $developmentStatus
      developmentType: $developmentType
      shoreStatus: $shoreStatus
      operator: $operator
      opportunities: $opportunities
      geologyEpochIds: $geologyEpochIds
      hydrocarbonType: $hydrocarbonType
      includeHistoricalParticipants: $includeHistoricalParticipants
      oilType: $oilType
      npvMin: $npvMin
      npvMax: $npvMax
      reservesMin: $reservesMin
      reservesMax: $reservesMax
      productionMin: $productionMin
      productionMax: $productionMax
      depthMin: $depthMin
      depthMax: $depthMax
      isWalkthrough: $isWalkthrough
      sortType: $sortType
      sortAscending: $sortAscending
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${ASSETS_FIELDS}
        }
      }
    }
  }
`;

export const ASSET_QUERY = gql`
  query asset($legacyId: ID!, $countryIsoCode: String!) {
    asset(legacyId: $legacyId, countryIsoCode: $countryIsoCode) {
      ${ASSETS_FIELDS}
    }
  }
`;

export const WATCH_LIST_QUERY = gql`
  query filterWatchList(
    $assetLegacyIds: [ID!]
    $assetIsoCodes: [String!]
    $country: [String!]
    $company: [String!]
    $basin: [String!]
    $developmentStatus: [ID!]
    $developmentType: [ID!]
    $shoreStatus: [String!]
    $operator: Boolean
    $opportunities: Boolean
    $geologyEpochIds: [ID!]
    $hydrocarbonType: [String!]
    $includeHistoricalParticipants: Boolean
    $oilType: [String!]
    $npvMin: Float
    $npvMax: Float
    $reservesMin: Float
    $reservesMax: Float
    $productionMin: Float
    $productionMax: Float
    $depthMin: Float
    $depthMax: Float
    $sortType: String!
    $sortAscending: Boolean!
  ) {
    filterWatchList(
      assetLegacyIds: $assetLegacyIds
      assetIsoCodes: $assetIsoCodes
      country: $country
      company: $company
      basin: $basin
      developmentStatus: $developmentStatus
      developmentType: $developmentType
      shoreStatus: $shoreStatus
      operator: $operator
      opportunities: $opportunities
      geologyEpochIds: $geologyEpochIds
      hydrocarbonType: $hydrocarbonType
      includeHistoricalParticipants: $includeHistoricalParticipants
      oilType: $oilType
      npvMin: $npvMin
      npvMax: $npvMax
      reservesMin: $reservesMin
      reservesMax: $reservesMax
      productionMin: $productionMin
      productionMax: $productionMax
      depthMin: $depthMin
      depthMax: $depthMax
      sortType: $sortType
      sortAscending: $sortAscending
    ) {
      ${ASSETS_FIELDS}
    }
  }
`;

export const SECURE_REPORT_DOWNLOAD_QUERY = gql`
  query secureReportDownload($assetLegacyId: ID!, $isoCode: String!, $reportType: String!) {
    secureReportDownload(
      assetLegacyId: $assetLegacyId
      isoCode: $isoCode
      reportType: $reportType
    ) {
      url
      filename
      downloadMessage
    }
  }
`;
