import { Redirect, Route } from 'react-router-dom';

import React from 'react';
import { SIGN_IN_PATH } from '../../utils/constants';
import { isAuthenticated } from '../../utils/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';

export default ({ children, ...rest }) => {
  const { isUserEntitled } = useCurrentUser();

  const NotAllowed = () => {
    return (
      <>
        <p>
          Access to this feature requires additional permissions. Please contact your account
          manager to enable this feature, or reach out to our support team if you need assistance.{' '}
        </p>
      </>
    );
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? (
          isUserEntitled(location.pathname) ? (
            children
          ) : (
            <NotAllowed />
          )
        ) : (
          <Redirect
            to={{
              pathname: SIGN_IN_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
