import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid2 as Grid, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useGetReport } from '../hooks/useGetReports';
import { Pages } from './Pages';
import { EditPage } from './EditPage';
import { Layout } from './Layout';
import { LAYOUT_MOCK } from '../mock';
import { EditReportHeader } from './EditReportHeader';

const useStyles = makeStyles((theme) => ({
  center: {
    ...theme.palette.primaryBackground,
    border: 'solid thin darkgrey',
  },
  grayBackground: {
    background: theme.palette.baseColors.cloudGray.c100,
  },
  loader: {
    ...theme.palette.loading,
  },
  root: {
    ...theme.sizes.fullPageNoAppBar,
  },
  scroll: {
    maxHeight: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
  },
}));

export const EditReport = () => {
  const classes = useStyles();
  const { uuid } = useParams();
  const { report } = useGetReport(uuid);
  const [editedReport, setEditedReport] = useState(report);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const { name, pages, reportType } = useMemo(() => {
    return {
      name: editedReport.metadata?.name,
      pages: editedReport.pages,
      reportType: editedReport.metadata?.reportType,
    };
  }, [editedReport]);
  // select first page by default
  useEffect(() => {
    setEditedReport(report);
    const newPage = pages[0];
    setSelectedPage(newPage);
  }, [report]);

  // select layout based on page viewed
  useEffect(() => {
    setSelectedLayout(LAYOUT_MOCK.find((layout) => layout.type === selectedPage?.layout));
  }, [selectedPage]);

  // previous page
  const previousPage = () => {
    if (selectedPage?.number > 1) {
      // subtract 2 since array is 0-indexed
      setSelectedPage(pages[selectedPage?.number - 2]);
    }
  };

  // next page
  const nextPage = () => {
    if (selectedPage?.number < pages?.length) {
      // subtract 1 since array is 0-indexed
      setSelectedPage(pages[selectedPage?.number]);
    }
  };

  // save draft
  const save = () => {};

  // publish draft
  const publish = () => {};

  if (!report)
    return (
      <Grid
        container
        className={clsx({ [classes.loader]: true, [classes.root]: true })}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" size={20} />
      </Grid>
    );

  return (
    <Grid container className={classes.root}>
      <Grid item size={2} padding={3} className={classes.grayBackground}>
        <Pages pages={pages} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      </Grid>
      <Grid
        container
        item
        size={7}
        className={classes.center}
        direction="column"
        padding={3}
        spacing={1}
      >
        <EditReportHeader name={name} reportType={reportType} save={save} publish={publish} />
        <Grid container flex="1 1 auto" margin="20px 0" alignItems="center" justifyContent="center">
          <EditPage />
        </Grid>
        <Grid container justifyContent="center">
          <KeyboardArrowLeft onClick={previousPage} />
          <Typography>
            {selectedPage?.number}/{pages?.length}
          </Typography>
          <KeyboardArrowRight onClick={nextPage} />
        </Grid>
      </Grid>
      <Grid
        item
        size={3}
        className={clsx({ [classes.grayBackground]: true, [classes.scroll]: true })}
      >
        <Layout
          report={report}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
        />
      </Grid>
    </Grid>
  );
};
