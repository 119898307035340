import { COMPONENTS_MOCK, LAYOUT_MOCK, REPORTS_MOCK } from '../mock';

export const useGetReports = () => {
  return { reports: REPORTS_MOCK };
};

export const useGetReport = (id) => {
  return { report: REPORTS_MOCK.find((report) => report.id === parseInt(id)) };
};
export const useGetLayoutItems = () => {
  return { layoutItems: LAYOUT_MOCK };
};

export const useGetComponentsFromLayout = (layout = {}) => {
  return {
    components:
      COMPONENTS_MOCK.filter((component) =>
        layout?.components?.find((layoutComps) => layoutComps === component.type),
      ) || [],
  };
};
