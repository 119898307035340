import {
  BLOCKS,
  CCUS_PROJECTS,
  CO2_EMITTERS,
  FIELDS,
  GAS_PRODUCTION_HEAT_MAP,
  GAS_RESERVES_HEAT_MAP,
  NPV_HEAT_MAP,
  OIL_AND_CONDENSATE_PRODUCTION_HEAT_MAP,
  OIL_AND_CONDENSATE_RESERVES_HEAT_MAP,
  OPERATORS_HEAT_MAP,
  PARTICIPANTS_HEAT_MAP,
  PIPELINES,
  STORAGE_LOCATIONS,
  TOTAL_PRODUCTION_HEAT_MAP,
  TOTAL_RESERVES_HEAT_MAP,
  WELLS,
} from '../Settings/constants';
import {
  BASIN_COLORS,
  BLOCK_COLOR,
  BLOCK_LABEL_COLOR,
  BOUNDARY_COLOR,
  DEFAULT_BOUNDARY_COLOR,
  DEFAULT_LABEL_COLOR,
  FIELD_COLORS,
  FORMATION_COLORS,
  GREEN_HIGH,
  GREEN_LOW,
  GREEN_MAX,
  GREEN_MEDIUM,
  GREEN_MIN,
  PIPELINE_COLORS,
  PRESALT_POLYGON_COLOR,
  RED_HIGH,
  RED_LOW,
  RED_MAX,
  RED_MEDIUM,
  RED_MIN,
  SCREENING_CLUSTER_COLOR,
  TRANSPARENT_COLOR,
  UNLICENSED_BLOCK_COLOR,
  WELL_COLORS,
  ZERO_VALUE_FILL,
} from './colors';

// Entity types (Match entityType key within the tileset)
export const MAP_ENTITY_TYPES = {
  asset: 'asset',
  block: 'block',
  ccusProject: 'ccus_project',
  co2Emitter: 'co2_emitter',
  eaWell: 'ea_well',
  facility: 'facility',
  field: 'field',
  formation: 'formation',
  lease: 'lease',
  lngProject: 'lng_project',
  pipeline: 'pipeline',
  pipelineNetwork: 'pipeline_network',
  pipelineSegment: 'pipeline_segment',
  presaltPolygon: 'presalt_polygon',
  storageSite: 'storage_site',
  well: 'well',
};

// Layer types (Layer types that can be enabled / disabled)
export const MAP_LAYER_TYPES = {
  assets: 'assets',
  blocks: 'blocks',
  ccusProjects: 'ccus projects',
  co2Emitters: 'co2 emitters',
  countryBoundaries: 'country boundaries', // Non country specific tileset layer (Added as additional layer)
  eaWells: 'ea wells',
  facilities: 'facilities',
  fields: 'fields',
  formations: 'formations',
  leases: 'leases',
  lng: 'lng', // Non country specific tileset layer (Added as additional layer)
  pipelines: 'pipelines',
  pipelineNetworks: 'pipeline networks', // Non country specific tileset layer (Added as additional layer)
  pipelineSegments: 'pipeline segments',
  presaltPolygons: 'presalt polygons',
  screening: 'screening', // Non country specific tileset layer (Added as additional layer)
  storageSites: 'storage sites',
  wells: 'wells',
};

// Renderable entity types (Entity types that can be clicked on to display information based off the layer name)
export const ENTITY_LAYER_NAMES = {
  assets: 'assets',
  basins: 'basins',
  blocks: 'blocks',
  ccusProjects: 'ccus_projects',
  co2Emitters: 'co2_emitters',
  countries: 'countries',
  customRegions: 'custom_regions',
  eaWells: 'eawells',
  facilities: 'facilities',
  fields: 'fields',
  formations: 'formations',
  leases: 'leases',
  lng: 'lng',
  pipelines: 'pipelines',
  pipelineNetworks: 'pipeline_networks',
  pipelineSegments: 'pipeline_segments',
  presaltPolygons: 'presalt_polygons',
  storageSites: 'storage_sites',
  wells: 'wells',
};

// Renderable entity types (Entity types that can be clicked on to display information based off the layer name)
export const RENDERABLE_ENTITY_LAYERS = [
  ENTITY_LAYER_NAMES.assets,
  ENTITY_LAYER_NAMES.basins,
  ENTITY_LAYER_NAMES.blocks,
  ENTITY_LAYER_NAMES.ccusProjects,
  ENTITY_LAYER_NAMES.co2Emitters,
  ENTITY_LAYER_NAMES.countries,
  ENTITY_LAYER_NAMES.customRegions,
  ENTITY_LAYER_NAMES.eaWells,
  ENTITY_LAYER_NAMES.facilities,
  ENTITY_LAYER_NAMES.fields,
  ENTITY_LAYER_NAMES.formations,
  ENTITY_LAYER_NAMES.leases,
  ENTITY_LAYER_NAMES.lng,
  ENTITY_LAYER_NAMES.pipelines,
  ENTITY_LAYER_NAMES.pipelineSegments,
  ENTITY_LAYER_NAMES.presaltPolygons,
  ENTITY_LAYER_NAMES.storageSites,
  ENTITY_LAYER_NAMES.wells,
];

// Tileset types
export const MAP_TILESET_TYPES = {
  ccus: 'ccus',
  oilAndGasMap: 'oilAndGasMap',
};

// Entity types that need to include ID's within the tileset
export const ENTITY_TYPE_LAYERS_WITH_IDS = [
  MAP_ENTITY_TYPES.ccusProject,
  MAP_ENTITY_TYPES.co2Emitter,
  MAP_ENTITY_TYPES.eaWell,
  MAP_ENTITY_TYPES.lngProject,
  MAP_ENTITY_TYPES.storageSite,
];

// Source IDs
export const MAP_SOURCE_IDS = {
  assetPointSource: 'asset-point-source',
  eaWells: 'screening-ea-wells-source',
  geojson: 'geojson',
  lngFacilities: 'lng-facilities-source',
  pipelineNetworks: 'pipeline-networks-source',
  screening: 'screening-source',
  screeningCluster: 'screening-cluster-source',
};

/* MAPBOX PROVIDED */
// Mapbox provided sources
export const MAPBOX_VECTOR_SOURCES = {
  countryBoundaries: 'mapbox://mapbox.country-boundaries-v1',
  customRegions: 'mapbox://welligence.1bkginly',
};

// Mapbox provided layers
export const MAPBOX_LAYERS = {
  countryBoundaries: 'country_boundaries',
  customRegions: 'custom_regions-0hsl1j',
};

// Use mapbox layer for custom countries:
const countryBoundaryLayerSource = {
  source: {
    type: 'vector',
    url: MAPBOX_VECTOR_SOURCES.countryBoundaries,
  },
  'source-layer': MAPBOX_LAYERS.countryBoundaries,
};

// Use mapbox layer for custom regions:
const customRegionLayerSource = {
  source: {
    type: 'vector',
    url: MAPBOX_VECTOR_SOURCES.customRegions,
  },
  'source-layer': MAPBOX_LAYERS.customRegions,
};

// Layer IDs
export const MAP_LAYER_IDS = {
  assetPointLayer: 'asset-point-layer',
  basins: 'basins',
  basinsLabels: 'basin_labels',
  countryFillBoundaries: 'country-fill-boundaries',
  countryLineBoundaries: 'country-line-boundaries',
  countrySelectedBoundaries: 'country-selected-boundaries',
  customRegionFillBoundaries: 'custom-region-fill-boundaries',
  customRegionLineBoundaries: 'custom-region-line-boundaries',
  customRegionLabels: 'custom-region-labels',
  customRegionSelectedBoundaries: 'custom-region-selected-boundaries',
  distanceLabels: 'distance-labels',
  eaWells: 'screening-ea-wells-layer',
  eaWellLabels: 'screening-ea-well-labels',
  lngFacilities: 'lng-facilities-layer',
  measureLines: 'measure-lines',
  measurePoints: 'measure-points',
  pipelineNetworks: 'pipeline-networks-layer',
  screening: 'screening-layer',
  screeningClusters: 'screening-layer-clusters',
  screeningPoints: 'screening-layer-points',
  screeningPointsCount: 'screening-layer-points-count',
};

export const WELLIGENCE_SOURCE = 'welligence';

// Layer options:
const assetLayerOptions = {
  type: 'fill',
  paint: {
    'fill-color': BLOCK_COLOR,
    'fill-outline-color': DEFAULT_BOUNDARY_COLOR,
  },
};

const blockLayerOptions = {
  type: 'fill',
  paint: {
    'fill-color': [
      'match',
      ['coalesce', ['get', 'licenseStatus'], ['get', 'license_status']],
      'Unlicensed',
      UNLICENSED_BLOCK_COLOR,
      /* other */ BLOCK_COLOR,
    ],
    'fill-outline-color': DEFAULT_BOUNDARY_COLOR,
  },
};

const labelLayerOptions = {
  type: 'symbol',
  minzoom: 6,
  layout: {
    'icon-anchor': 'center',
    'text-field': ['get', 'name'],
    'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
    'text-letter-spacing': 0.2,
    'text-size': 12,
  },
  paint: {
    'text-halo-color': DEFAULT_BOUNDARY_COLOR,
    'text-halo-width': 1.5,
  },
};

// prettier-ignore
export const MAP_LAYER_OPTIONS = {
  asset: assetLayerOptions,
  assetPoint: {
    id: MAP_LAYER_IDS.assetPointLayer,
    type: 'circle',
    source: MAP_SOURCE_IDS.assetPointSource,
    paint: {
      'circle-color': 'rgba(0,255,255,.55)',
      'circle-radius': 5,
      'circle-stroke-width': 2,
      'circle-stroke-color': 'rgba(0,255,255,.55)',
    },
  },
  block: {
    ...blockLayerOptions,
    minzoom: 5,
  },
  countryFillBoundary: {
    ...countryBoundaryLayerSource,
    id: MAP_LAYER_IDS.countryFillBoundaries,
    type: 'fill',
    paint: {
      'fill-color': TRANSPARENT_COLOR,
      'fill-opacity': 1,
      'fill-outline-color': TRANSPARENT_COLOR,
    },
  },
  countryLineBoundary: {
    ...countryBoundaryLayerSource,
    id: MAP_LAYER_IDS.countryLineBoundaries,
    type: 'line',
    paint: {
      'line-color': TRANSPARENT_COLOR,
      'line-width': 2,
    },
  },
  countrySelectedBoundary: {
    ...countryBoundaryLayerSource,
    id: MAP_LAYER_IDS.countrySelectedBoundaries,
    type: 'line',
    paint: {
      'line-color': TRANSPARENT_COLOR,
      'line-width': 3,
    },
  },
  customRegionFillBoundary: {
    ...customRegionLayerSource,
    id: MAP_LAYER_IDS.customRegionFillBoundaries,
    type: 'fill',
    paint: {
      'fill-color': TRANSPARENT_COLOR,
      'fill-opacity': 1,
      'fill-outline-color': TRANSPARENT_COLOR,
    },
  },
  customRegionLabel: {
    ...customRegionLayerSource,
    id: MAP_LAYER_IDS.customRegionLabels,
    type: 'symbol',
    minzoom: 3,
    maxzoom: 22,
    layout: {
      'icon-anchor': 'center',
      'text-field': ['get', 'country_name'],
      'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
      'text-letter-spacing': 0.2,
      'text-size': 12,
    },
    paint: {
      'text-color': DEFAULT_LABEL_COLOR,
      'text-halo-color': DEFAULT_BOUNDARY_COLOR,
      'text-halo-width': 1,
      'text-opacity': 0,
    },
  },
  customRegionLineBoundary: {
    ...customRegionLayerSource,
    id: MAP_LAYER_IDS.customRegionLineBoundaries,
    type: 'line',
    paint: {
      'line-color': TRANSPARENT_COLOR,
      'line-width': 2,
    },
  },
  customRegionSelectedBoundary: {
    ...customRegionLayerSource,
    id: MAP_LAYER_IDS.customRegionSelectedBoundaries,
    type: 'line',
    paint: {
      'line-color': TRANSPARENT_COLOR,
      'line-width': 3,
    },
  },
  distanceLabel: {
    id: MAP_LAYER_IDS.distanceLabels,
    type: 'symbol',
    source: 'geojson',
    layout: {
      'text-field': ['get', 'description'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto',
    },
    paint: {
      'text-color': BLOCK_LABEL_COLOR,
      'text-halo-color': DEFAULT_BOUNDARY_COLOR,
      'text-halo-width': 1,
    },
    filter: ['==', 'isDistance', true],
  },
  eaWell: {
    id: MAP_LAYER_IDS.eaWells,
    type: 'circle',
    source: MAP_SOURCE_IDS.eaWells,
    paint: {
      'circle-radius': 6,
      'circle-stroke-width': 0.5,
      'circle-stroke-color': WELL_COLORS.boundary,
      'circle-color': WELL_COLORS.wells_to_watch,
    },
  },
  facility: {
    type: 'symbol',
    minzoom: 5,
    layout: {
      'icon-image': [
        'match',
        ['get', 'facilityIcon'],
        // facility_icon, mapbox icon name:
        'Fixed-Platform',
        'Fixed-Platform',
        'FPSO',
        'FPSO',
        'Manifold',
        'Manifold',
        'Oil-Facility',
        'Oil-Facility',
        'Pipe',
        'Pipe',
        'Refinery',
        'Refinery',
        'Rig',
        'Rig',
        'Semi-Sub',
        'Semi-Sub',
        'Separation',
        'Separation',
        'Spar',
        'Spar',
        'Square',
        'Square',
        'Tanker',
        'Tanker',
        'Valve',
        'Valve',
        /* other */ 'Default',
      ],
      'icon-size': [
        'match',
        ['get', 'facilityIcon'],
        'Manifold',
        1,
        /* other */ 0.8,
      ],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
    },
  },
  field: {
    type: 'fill',
    minzoom: 4,
    paint: {
      'fill-color': [
        'match',
        ['get', 'hydrocarbonType'],
        'gas',
        FIELD_COLORS.gas,
        'Gas',
        FIELD_COLORS.gas,
        'Gas/Condensate',
        FIELD_COLORS.gas,
        'oil',
        FIELD_COLORS.oil,
        'Oil',
        FIELD_COLORS.oil,
        'Oil/Condensate',
        FIELD_COLORS.oil,
        /* other */ FIELD_COLORS.default,
      ],
    },
  },
  formation: {
    type: 'fill',
    minzoom: 3,
    paint: {
      'fill-color': [
        'match',
        ['get', 'name'],
        'Black Oil',
        FORMATION_COLORS.blackOil,
        'Volatile Oil',
        FORMATION_COLORS.volatileOil,
        'Gas / Condensate',
        FORMATION_COLORS.gasAndCondensate,
        'Wet Gas',
        FORMATION_COLORS.wetGas,
        'Dry Gas',
        FORMATION_COLORS.dryGas,
        /* other */ FORMATION_COLORS.default,
      ],
    },
  },
  highlight: {
    type: 'line',
    filter: ['==', 'legacyId', ''], // Default to no highlight
    paint: {
      'line-color': BOUNDARY_COLOR,
      'line-width': 2,
    },
  },
  label: {
    type: 'symbol',
    minzoom: 6,
    layout: {
      'icon-anchor': 'center',
      'text-field': ['get', 'name'],
      'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
      'text-letter-spacing': 0.2,
      'text-size': 12,
    },
    paint: {
      'text-halo-color': DEFAULT_BOUNDARY_COLOR,
      'text-halo-width': 1.5,
    },
  },
  lease: {
    ...assetLayerOptions,
    minzoom: 5,
  },
  lngFacility: {
    id: MAP_LAYER_IDS.lngFacilities,
    source: MAP_SOURCE_IDS.lngFacilities,
    type: 'symbol',
    minzoom: 4,
    layout: {
      'icon-image': [
        'match',
        ['get', 'facilityIcon'],
        // facilityIcon, mapbox icon name:
        'LNG-Liquefaction',
        'LNG-Liquefaction',
        'LNG-Regas',
        'LNG-Regas',
        /*other*/ 'Default',
      ],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
    },
  },
  measureLine: {
    id: MAP_LAYER_IDS.measureLines,
    type: 'line',
    source: MAP_SOURCE_IDS.geojson,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-width': 2.5,
    },
    filter: ['in', '$type', 'LineString'],
  },
  measurePoint: {
    id: MAP_LAYER_IDS.measurePoints,
    type: 'circle',
    source: MAP_SOURCE_IDS.geojson,
    paint: {
      'circle-radius': 5,
      'circle-color': DEFAULT_LABEL_COLOR,
      'circle-stroke-width': 2,
      'circle-stroke-color': DEFAULT_BOUNDARY_COLOR,
    },
    filter: ['==', 'isDistance', false],
  },
  pipeline: {
    type: 'line',
    minzoom: 4,
    paint: {
      'line-width': 3,
      'line-color': [
        'match',
        ['get', 'product'],
        'Gas',
        PIPELINE_COLORS.gas,
        'Oil',
        PIPELINE_COLORS.oil,
        /* other */ PIPELINE_COLORS.default,
      ],
      'line-dasharray': [
        'match',
        ['get', 'status'],
        'Planned Pipeline',
        ['literal', [2, 1]],
        'Future Pipeline',
        ['literal', [2, 1]],
        'Proposed Abandoned',
        ['literal', [2, 1]],
        'Active',
        ['literal', [1, 0]],
        'Online',
        ['literal', [1, 0]],
        'Offline',
        ['literal', [1, 0]],
        /* other */ ['literal', [1, 0]],
      ],
    },
  },
  pipelineNetwork: {
    id: MAP_LAYER_IDS.pipelineNetworks,
    source: MAP_SOURCE_IDS.pipelineNetworks,
    type: 'line',
    minzoom: 4,
    paint: {
      'line-color': BOUNDARY_COLOR,
      'line-width': 2,
    },
  },
  pipelineSegment: {
    type: 'line',
    minzoom: 4,
    paint: {
      'line-width': 3,
      'line-color': [
        'match',
        ['get', 'hydrocarbon'],
        'Gas',
        PIPELINE_COLORS.gas,
        'Oil',
        PIPELINE_COLORS.oil,
        /* other */ PIPELINE_COLORS.default,
      ],
      'line-dasharray': [
        'match',
        ['get', 'status'],
        'Planned Pipeline',
        ['literal', [2, 1]],
        'Future Pipeline',
        ['literal', [2, 1]],
        'Proposed Abandoned',
        ['literal', [2, 1]],
        'Active',
        ['literal', [1, 0]],
        'Online',
        ['literal', [1, 0]],
        'Offline',
        ['literal', [1, 0]],
        /* other */ ['literal', [1, 0]],
      ],
    },
  },
  presaltPolygon: {
    type: 'fill',
    minzoom: 3,
    paint: {
      'fill-color': PRESALT_POLYGON_COLOR,
    },
  },
  screeningOutline: {
    id: MAP_LAYER_IDS.screening,
    type: 'fill',
    source: MAP_SOURCE_IDS.screening,
    paint: {
      'fill-color': ['get', 'color'],
    },
  },
  screeningCluster: {
    id: MAP_LAYER_IDS.screeningClusters,
    type: 'circle',
    source: MAP_SOURCE_IDS.screeningCluster,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': SCREENING_CLUSTER_COLOR,
      'circle-radius': ['step', ['get', 'point_count'], 20, 50, 30, 100, 40],
    },
  },
  screeningClusterCount: {
    id: MAP_LAYER_IDS.screeningPointsCount,
    source: MAP_SOURCE_IDS.screeningCluster,
    filter: ['has', 'point_count'],
    type: 'symbol',
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  },
  storageSite: {
    type: 'fill',
    paint: {
      'fill-color': PRESALT_POLYGON_COLOR,
    },
  },
  well: {
    type: 'circle',
    paint: {
      'circle-radius': 6,
      'circle-stroke-width': 0.5,
      'circle-stroke-color': WELL_COLORS.boundary,
      'circle-color': [
        'match',
        ['get', 'weaClassification'],
        'Production',
        WELL_COLORS.production,
        'Injection',
        WELL_COLORS.injection,
        'Exploration',
        WELL_COLORS.exploration_and_appraisal,
        'Appraisal',
        WELL_COLORS.exploration_and_appraisal,
        /* other */ WELL_COLORS.default,
      ],
    },
  },
  wellLabel: {
    ...labelLayerOptions,
    minzoom: 10,
    layout: {
      ...labelLayerOptions.layout,
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-offset': [0, 0.6],
    },
  },
};

// Legend Items
const ASSET_LEGEND_ITEM = {
  label: 'Assets / Blocks',
  items: [
    { color: BLOCK_COLOR, shape: 'square', title: 'Licensed Block' },
    { color: UNLICENSED_BLOCK_COLOR, shape: 'square', title: 'Unlicensed Block' },
  ],
};

const BASIN_LEGEND_ITEM = {
  label: 'Basins',
  items: [
    { color: BASIN_COLORS.offshore, shape: 'oval', title: 'Offshore' },
    { color: BASIN_COLORS.onshore, shape: 'oval', title: 'Onshore' },
  ],
};

const FIELD_LEGEND_ITEM = {
  label: 'Fields',
  items: [
    { border: 'none', color: FIELD_COLORS.gas, shape: 'oval', title: 'Gas' },
    { border: 'none', color: FIELD_COLORS.oil, shape: 'oval', title: 'Oil' },
  ],
};

// const OTHER_LEGEND_ITEM = {
//   label: 'Others',
//   items: [
//     {
//       border: 'none',
//       color: FORMATION_COLORS.blackOil,
//       shape: 'square',
//       title: 'Black Oil Formation',
//     },
//     {
//       border: 'none',
//       color: FORMATION_COLORS.volatileOil,
//       shape: 'square',
//       title: 'Volatile Oil Formation',
//     },
//     {
//       border: 'none',
//       color: FORMATION_COLORS.gasAndCondensate,
//       shape: 'square',
//       title: 'Gas and Condensate Formation',
//     },
//     { border: 'none', color: FORMATION_COLORS.wetGas, shape: 'square', title: 'Wet Gas Formation' },
//     { border: 'none', color: FORMATION_COLORS.dryGas, shape: 'square', title: 'Dry Gas Formation' },
//     { border: 'none', color: PRESALT_POLYGON_COLOR, shape: 'square', title: 'Pre-salt Polygon' },
//   ],
// };

const PIPELINE_LEGEND_ITEM = {
  label: 'Pipelines',
  items: [
    {
      border: `4px solid ${PIPELINE_COLORS.gas}`,
      color: 'transparent',
      shape: 'line',
      title: 'Gas (Active, Online, Offline)',
    },
    {
      border: `4px dotted ${PIPELINE_COLORS.gas}`,
      color: 'transparent',
      shape: 'dottedLine',
      title: 'Gas (Planned Pipeline, Future Pipeline, Proposed Abandoned)',
    },
    {
      border: `4px solid ${PIPELINE_COLORS.oil}`,
      color: 'transparent',
      shape: 'line',
      title: 'Oil (Active, Online, Offline)',
    },
    {
      border: `4px dotted ${PIPELINE_COLORS.oil}`,
      color: 'transparent',
      shape: 'dottedLine',
      title: 'Oil (Planned Pipeline, Future Pipeline, Proposed Abandoned)',
    },
  ],
};

const WELL_LEGEND_ITEM = {
  label: 'Wells',
  items: [
    { color: WELL_COLORS.exploration_and_appraisal, title: 'E&A' },
    { color: WELL_COLORS.production, title: 'Production' },
    { color: WELL_COLORS.injection, title: 'Injection' },
    { color: WELL_COLORS.wells_to_watch, title: 'Wells to Watch' },
    { color: WELL_COLORS.default, title: 'Other' },
  ],
};

export const WELL_LEGEND_ITEM_INDEX = 4;

export const DEFAULT_LEGEND_ITEMS = [
  ASSET_LEGEND_ITEM,
  BASIN_LEGEND_ITEM,
  FIELD_LEGEND_ITEM,
  PIPELINE_LEGEND_ITEM,
  WELL_LEGEND_ITEM,
];

// Layer properties
export const layerProperties = {
  [TOTAL_PRODUCTION_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Total Production (boe/d)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: RED_MIN, title: '> 0 - 250k' },
        { color: RED_LOW, title: '> 250k - 1MM' },
        { color: RED_MEDIUM, title: '> 1MM - 2MM' },
        { color: RED_HIGH, title: '> 2MM - 5MM' },
        { color: RED_MAX, title: '> 5MM' },
      ],
    },
    dataKey: 'mapProduction',
    hydrocarbonType: 'All',
  },
  [OIL_AND_CONDENSATE_PRODUCTION_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Total Production Oil and Condensate (bbl/d)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: GREEN_MIN, title: '> 0 - 250k' },
        { color: GREEN_LOW, title: '> 250k - 1MM' },
        { color: GREEN_MEDIUM, title: '> 1MM - 2MM' },
        { color: GREEN_HIGH, title: '> 2MM - 5MM' },
        { color: GREEN_MAX, title: '> 5MM' },
      ],
    },
    dataKey: 'mapProduction',
    hydrocarbonType: 'Oil/Condensate',
  },
  [GAS_PRODUCTION_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Total Production Gas (Bcf/d)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: RED_MIN, title: '> 0 - 1' },
        { color: RED_LOW, title: '> 1 - 2' },
        { color: RED_MEDIUM, title: '> 2 - 5' },
        { color: RED_HIGH, title: '>5 - 10' },
        { color: RED_MAX, title: '> 10' },
      ],
    },
    dataKey: 'mapProduction',
    hydrocarbonType: 'Gas',
  },
  [TOTAL_RESERVES_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Reserves (Billion boe)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: RED_MIN, title: '> 0 - 5' },
        { color: RED_LOW, title: '> 5 - 10' },
        { color: RED_MEDIUM, title: '> 10 - 25' },
        { color: RED_HIGH, title: '> 25 - 50' },
        { color: RED_MAX, title: '> 50' },
      ],
    },
    dataKey: 'mapReserves',
    hydrocarbonType: 'All',
  },
  [OIL_AND_CONDENSATE_RESERVES_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Oil Reserves (MMbbl)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: GREEN_MIN, title: '> 0 - 5k' },
        { color: GREEN_LOW, title: '> 5k - 10k' },
        { color: GREEN_MEDIUM, title: '> 10k - 25k' },
        { color: GREEN_HIGH, title: '> 25k - 50k' },
        { color: GREEN_MAX, title: '> 50k' },
      ],
    },
    dataKey: 'mapReserves',
    hydrocarbonType: 'Oil/Condensate',
  },
  [GAS_RESERVES_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Gas Reserves (tcf)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: RED_MIN, title: '> 0 - 5' },
        { color: RED_LOW, title: '> 5 - 10' },
        { color: RED_MEDIUM, title: '> 10 - 25' },
        { color: RED_HIGH, title: '> 25 - 50' },
        { color: RED_MAX, title: '> 50' },
      ],
    },
    dataKey: 'mapReserves',
    hydrocarbonType: 'Gas',
  },
  [NPV_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'NPV (US$MM)',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: GREEN_MIN, title: '> 0 - 250,000' },
        { color: GREEN_LOW, title: '> 250,000 - 500,000' },
        { color: GREEN_MEDIUM, title: '> 500,000 - 750,000' },
        { color: GREEN_HIGH, title: '> 750,000 - 1,000,000' },
        { color: GREEN_MAX, title: '> 1,000,000' },
      ],
    },
    dataKey: 'mapNpv',
  },
  [OPERATORS_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Total Operators',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: RED_MIN, title: '> 0 - 5' },
        { color: RED_LOW, title: '> 5 - 10' },
        { color: RED_MEDIUM, title: '> 10 - 25' },
        { color: RED_HIGH, title: '> 25 - 50' },
        { color: RED_MAX, title: '> 50' },
      ],
    },
    dataKey: 'mapCompanies',
  },
  [PARTICIPANTS_HEAT_MAP.value]: {
    call: 'setHeatMapStyle',
    legend: {
      legendLabel: 'Total Participants',
      legendItems: [
        { color: ZERO_VALUE_FILL, title: '= 0' },
        { color: RED_MIN, title: '> 0 - 10' },
        { color: RED_LOW, title: '> 10 - 25' },
        { color: RED_MEDIUM, title: '> 25 - 50' },
        { color: RED_HIGH, title: '> 50 - 100' },
        { color: RED_MAX, title: '> 100' },
      ],
    },
    dataKey: 'mapCompanies',
  },
  // 'productionBubbleMap': {call: '', defaultStyle: '', },
  // 'reservesBubbleMap': {call: '', defaultStyle: '', },
  [CCUS_PROJECTS.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.ccusProjects],
  },
  [CO2_EMITTERS.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.co2Emitters],
  },
  [STORAGE_LOCATIONS.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.storageSites],
  },
  [BLOCKS.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.assets, MAP_LAYER_TYPES.blocks, MAP_LAYER_TYPES.leases],
  },
  [FIELDS.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.fields],
  },
  [PIPELINES.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.pipelines],
  },
  [WELLS.value]: {
    call: 'toggleLayer',
    entityTypes: [MAP_LAYER_TYPES.wells],
  },
};

// Timeout for retrying mapbox tiles. This is used to prevent the map from
// adding features to the map before the sources are created.
export const MAP_SOURCE_RETRY_TIMEOUT = 3000;

// Map Zooms
export const DEFAULT_MAP_ZOOM = 9;
export const PIPELINE_NETWORK_MAP_ZOOM = 6;

// Screenshots
export const SS_HEIGHT = 666;
export const SS_WIDTH = 748;
