/* eslint-disable no-use-before-define */
// ignore the above rule in favor of grouping the types together
/* METRICS */
const DISCOUNTED_CASH_FLOW = {
  label: 'Discounted Cash Flow',
  value: 'Discounted Cash Flow',
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
};
export const TOTAL_EMISSIONS = {
  label: 'Total Emissions',
  value: 'Total Emissions',
};
export const EMISSIONS_INTENSITY = {
  label: 'Emissions Intensity',
  value: 'Emissions Intensity',
  disableStackedChart: true,
  defaultRange: { x: [2012, 2050], y: [0, 100] },
};
const ESTIMATED_CURRENT_PRODUCTION = {
  label: 'Estimated Current Production',
  value: 'Estimated Current Production GHG',
};
export const CURRENT_YEAR_EMISSIONS = {
  label: 'Current Year Emissions',
  value: 'Current Year Emissions',
};
const CURRENT_YEAR_EMISSIONS_INTENSITY = {
  label: 'Current Year Emissions Intensity',
  value: 'Current Year Emissions Intensity',
  message: 'Block size is determined by absolute emissions for current year',
};
const NPV10 = {
  label: 'NPV10',
  value: 'NPV10',
};
const REMAINING_EMISSIONS = {
  label: 'Remaining Emissions',
  value: 'Remaining Emissions',
};
export const REMAINING_EMISSIONS_INTENSITY = {
  label: 'Remaining Emissions Intensity',
  value: 'Remaining Emissions Intensity',
  message: 'Block size is determined by absolute emissions for remaining years',
};
export const REMAINING_RESERVES = {
  label: 'Reserves',
  value: 'Reserves GHG',
};
const TOTAL_PRODUCTION = {
  label: 'Total Production',
  value: 'Total Production GHG',
  downloadMonthly: true,
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
  optionIsDisabled: ({ aggregate, graphType }) =>
    aggregate === COMPANY_AGGREGATE.name ||
    aggregate === DEVELOPMENT_STATUS_AGGREGATE.name ||
    'Total Production GHG' !== graphType.value,
  resetGraphTypeOptions: ({ aggregate, graphTypeOptions }) =>
    aggregate === COMPANY_AGGREGATE.name || aggregate === DEVELOPMENT_STATUS_AGGREGATE.name
      ? []
      : graphTypeOptions.filter((option) =>
          graphTypeChildren['Total Production'].find((child) => child.name === option.name),
        ),
};

export const LINE_GRAPH_TYPES = [
  TOTAL_PRODUCTION,
  DISCOUNTED_CASH_FLOW,
  TOTAL_EMISSIONS,
  EMISSIONS_INTENSITY,
];
export const BAR_GRAPH_TYPES = [
  ESTIMATED_CURRENT_PRODUCTION,
  NPV10,
  REMAINING_RESERVES,
  CURRENT_YEAR_EMISSIONS,
  CURRENT_YEAR_EMISSIONS_INTENSITY,
  REMAINING_EMISSIONS,
  REMAINING_EMISSIONS_INTENSITY,
];
export const TREE_MAP_TYPES = [
  ESTIMATED_CURRENT_PRODUCTION,
  REMAINING_RESERVES,
  CURRENT_YEAR_EMISSIONS,
  CURRENT_YEAR_EMISSIONS_INTENSITY,
  REMAINING_EMISSIONS,
  REMAINING_EMISSIONS_INTENSITY,
];
export const EMISSION_GRAPH_TYPES = [
  EMISSIONS_INTENSITY,
  CURRENT_YEAR_EMISSIONS,
  CURRENT_YEAR_EMISSIONS_INTENSITY,
  REMAINING_EMISSIONS,
  REMAINING_EMISSIONS_INTENSITY,
  TOTAL_EMISSIONS,
];

/* GRAPH TYPE OPTIONS */
const INCLUDE_NON_MODELLED_PRODUCTION_GRAPH_TYPE_OPTION = {
  name: 'Include Non-Modelled Production',
  tooltip:
    'Can only be selected with country or asset aggregate. The use of filters will also affect the presence of this data.',
};
export const graphTypeChildren = {
  [TOTAL_PRODUCTION.value]: [INCLUDE_NON_MODELLED_PRODUCTION_GRAPH_TYPE_OPTION],
};

/* EMISSION SOURCE TYPES */
const PRODUCTION_AND_PROCESSING = {
  label: 'Production and Processing',
  value: 'Production and Processing',
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
};
const FLARING = {
  label: 'Flaring',
  value: 'Flaring',
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
};
const VENTING = {
  label: 'Venting',
  value: 'Venting',
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
};
const DRILLING = {
  label: 'Drilling',
  value: 'Drilling',
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
};
const FUGITIVES = {
  label: 'Fugitives',
  value: 'Fugitives',
  isDisabled: ({ aggregate }) => aggregate === EMISSIONS_SOURCE_AGGREGATE.name,
};
export const EMISSION_SOURCE_TYPES = [
  PRODUCTION_AND_PROCESSING,
  FLARING,
  VENTING,
  DRILLING,
  FUGITIVES,
];
export const isGraphTypeEmission = (graphType) =>
  !!EMISSION_GRAPH_TYPES.find((type) => type.value === graphType.value);
/* AGGREGATES */
export const COUNTRY_AGGREGATE = {
  name: 'Country',
  options: true,
};

export const ASSET_AGGREGATE = {
  name: 'Asset',
  options: true,
};

export const COMPANY_AGGREGATE = {
  name: 'Company',
  options: true,
};

const DEVELOPMENT_STATUS_AGGREGATE = {
  name: 'Development Status',
  aggregate: 'Development Status',
};

export const EMISSIONS_SOURCE_AGGREGATE = {
  name: 'Emissions Source',
  aggregate: 'Emissions Source',
  isDisabled: ({ emissionsSource, graphType }) =>
    (emissionsSource && emissionsSource.length !== EMISSION_SOURCE_TYPES.length) ||
    !EMISSION_GRAPH_TYPES.find((type) => type.value === graphType.value),
  tooltip: `Can only be selected when an Emission metric type is selected and 'All' emissions sources are selected`,
};

export const LINE_GRAPH_AGGREGATES = [
  COUNTRY_AGGREGATE,
  ASSET_AGGREGATE,
  COMPANY_AGGREGATE,
  DEVELOPMENT_STATUS_AGGREGATE,
  EMISSIONS_SOURCE_AGGREGATE,
];
export const BAR_GRAPH_AGGREGATES = [
  COUNTRY_AGGREGATE,
  ASSET_AGGREGATE,
  COMPANY_AGGREGATE,
  DEVELOPMENT_STATUS_AGGREGATE,
];
export const TREE_MAP_AGGREGATES = [
  COUNTRY_AGGREGATE,
  ASSET_AGGREGATE,
  COMPANY_AGGREGATE,
  DEVELOPMENT_STATUS_AGGREGATE,
  EMISSIONS_SOURCE_AGGREGATE,
];

export const graphTitles = {
  [DISCOUNTED_CASH_FLOW.value]: '(US$MM)',
  [EMISSIONS_INTENSITY.value]: '(kgCO₂e/boe)',
  [ESTIMATED_CURRENT_PRODUCTION.value]: '(boe/d)',
  [CURRENT_YEAR_EMISSIONS.value]: '(tCO₂e)',
  [CURRENT_YEAR_EMISSIONS_INTENSITY.value]: '(kgCO₂e/boe)',
  [NPV10.value]: '(US$MM)',
  [REMAINING_EMISSIONS.value]: '(tCO₂e)',
  [REMAINING_EMISSIONS_INTENSITY.value]: '(kgCO₂e/boe)',
  [REMAINING_RESERVES.value]: '(MMboe)',
  [TOTAL_EMISSIONS.value]: '(tCO₂e)',
  [TOTAL_PRODUCTION.value]: '(boe/d)',
};
