export const styles = {
  container: {
    paddingTop: '2em',
  },
  button: {
    marginRight: '1em',
    fontSize: '1rem',
    borderRadius: '2em',
    padding: '0.5em 2em',
    textTransform: 'none',
    maxHeight: 'fit-content',
  },

  avatar: (size) => ({
    width: size === 'small' ? '2em' : '2.5em',
    height: size === 'small' ? '2em' : '2.5em',
    fontSize: '1em',
    backgroundColor: (theme) => theme.palette.baseColors.deepSeaNavy.c100,
  }),

  chip: {
    '& .MuiChip-label': {
      fontSize: (theme) => theme.fontSizes.base,
    },
  },
};
