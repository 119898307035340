import { Box, Link, Typography } from '@mui/material';
import { GasMeter, OilBarrel } from '@mui/icons-material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import { formatTwoDecimals } from '../../../../../../utils/numberFormatter';

export const COLUMNS = [
  {
    field: 'country',
    headerName: 'Country',
    renderCell: (params) => params.row.country.name,
    width: 200,
  },
  {
    field: 'asset',
    headerName: 'Asset',
    renderCell: (params) => {
      const { country, legacyId, name } = params.row; // Destructure `params.row`

      const baseUrl = window.location.origin;
      const link = `${baseUrl}/valuations/${country.isoCode}/${legacyId}`;

      return (
        <Box display="flex" alignItems="center">
          <Link
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <Typography color="secondary" style={{ display: 'contents', alignItems: 'center' }}>
              {name}
            </Typography>
            <OpenInNewIcon fontSize="small" color="action" />
          </Link>
        </Box>
      );
    },
    width: 200,
  },
  {
    field: 'group',
    headerName: 'Grouping',
    renderCell: (params) => params.row.maOpportunity?.group,
    width: 200,
  },
  {
    field: 'sellerCompany',
    headerName: 'Seller',
    renderCell: (params) => params.row.maOpportunity?.sellerCompany?.name,
    width: 200,
  },
  {
    field: 'sellerStake',
    headerName: 'Seller Stake on Offer',
    renderCell: (params) =>
      params.row.maOpportunity?.sellerStake
        ? `${params.row.maOpportunity?.sellerStake * 100}%`
        : 'N/A',
    width: 200,
  },
  {
    field: 'sellerMotivation',
    headerName: 'Seller Motivation',
    renderCell: (params) => params.row.maOpportunity?.sellerMotivation,
    width: 200,
  },
  {
    field: 'resourceTheme',
    headerName: 'Resource Theme',
    renderCell: (params) => params.row.maOpportunity?.resourceTheme,
    width: 200,
  },
  {
    field: 'operatedStake',
    headerName: 'Operated Stake Available',
    renderCell: (params) => (params.row.maOpportunity?.operatedStake === true ? 'Yes' : 'No'),
    width: 200,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    renderCell: (params) => params.row.maOpportunity?.comment,
    width: 400,
  },
  {
    field: 'officiallyOnMarket',
    headerName: 'Market Standing',
    renderCell: (params) => params.row.maOpportunity?.officiallyOnMarket,
    width: 200,
  },
  {
    field: 'dateOfEntry',
    headerName: 'Date Entered',
    renderCell: (params) => params.row.maOpportunity?.dateOfEntry,
    width: 200,
  },
  {
    field: 'npv',
    headerName: 'NPV10',
    width: 200,
    renderCell: (params) => formatTwoDecimals({ value: params.row.maOpportunity?.npv }),
  },
  {
    field: 'hydrocarbonType',
    headerName: 'Hydrocarbon Type',
    renderCell: (params) => {
      const titleizedValue =
        params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase();

      return (
        <Box display="flex" alignItems="center">
          {params.value === 'gas' ? (
            <GasMeter fontSize="small" color="secondary" />
          ) : (
            <OilBarrel fontSize="small" color="secondary" />
          )}
          <Typography ml={1} style={{ display: 'contents', alignItems: 'center' }}>
            {titleizedValue}
          </Typography>
        </Box>
      );
    },
    width: 200,
  },
  {
    field: 'remainingCapex',
    headerName: 'Remaining Capex',
    width: 200,
    renderCell: (params) => formatTwoDecimals({ value: params.row.maOpportunity?.remainingCapex }),
  },
  {
    field: 'remainingReserves',
    headerName: 'Remaining Reserves',
    width: 200,
    renderCell: (params) =>
      formatTwoDecimals({ value: params.row.maOpportunity?.remainingReserves }),
  },
  {
    field: 'estimatedRecentProduction',
    headerName: 'Estimated Recent Production',
    width: 200,
    renderCell: (params) =>
      formatTwoDecimals({ value: params.row.maOpportunity?.estimatedRecentProduction }),
  },
];
