import React, { useCallback, useEffect, useState } from 'react';

import Graph from '../../../../components/Graphs/graph';
import GraphContainer from '../../../../components/Graphs/graphContainer';
import GraphControls from '../../../../components/Graphs/GraphControls';
import Grid from '@mui/material/Grid2';
import Modal from '../../../../components/Modals';
import { getAdjustedLayout } from './helpers';
import { useMaContext } from '../../MaContext';
import { useStyles } from './styles';

const ChartGraph = ({
  data,
  title,
  loading,
  xConfig,
  yConfig,
  stackByConfig,
  adjustmentsConfig,
  minMaxConfig,
  graphLayout,
  graphContext,
  open = false,
  openModal = () => null,
  closeModal = () => null,
  isInModal = false,
  maxXAxisTicks = 50,
}) => {
  const enableChartSettingsButton =
    minMaxConfig?.options?.length > 0 ||
    xConfig?.options?.length > 1 ||
    yConfig?.options?.length > 1;
  const [showLegend, setShowLegend] = useState(true);
  const [openControlsModal, setOpenControlsModal] = useState(false);
  const [layout, setLayout] = useState(graphLayout);
  const { refresh } = useMaContext();

  const graphConfig = {
    modeBarButtonsToRemove: [
      'pan2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'lasso2d',
      'select2d',
    ],
  };

  useEffect(() => {
    setLayout(getAdjustedLayout({ data, graphLayout, isInModal, showLegend, maxXAxisTicks }));
  }, [data, graphLayout, isInModal, showLegend]);

  const onRelayout = useCallback(
    (event) => {
      // only use data range as default x-axis range if present, otherwise use empty array to use full x-axis range
      const xAxisRange =
        !isNaN(event?.['xaxis.range[0]']) && !isNaN(event?.['xaxis.range[1]'])
          ? [event['xaxis.range[0]'], event['xaxis.range[1]']]
          : [];

      const yAxisRange =
        !isNaN(event?.['yaxis.range[0]']) && !isNaN(event?.['yaxis.range[1]'])
          ? [event['yaxis.range[0]'], event['yaxis.range[1]']]
          : [];

      setLayout(
        getAdjustedLayout({
          data,
          graphLayout,
          isInModal,
          showLegend,
          xAxisRange,
          yAxisRange,
          maxXAxisTicks,
        }),
      );
    },
    [data, graphLayout, isInModal, showLegend],
  );

  if (isInModal && showLegend) {
    // show legend for all traces when in modal
    data.forEach((trace) => (trace.showlegend = true));
  }

  return (
    <GraphContainer
      open={open}
      setOpen={openModal}
      setClose={closeModal}
      setOpenControlsModal={setOpenControlsModal}
      showToggle={!isInModal}
      title={title}
      loading={loading}
      enableChartSettingsButton={enableChartSettingsButton}
      showPlotlyLegendToggle
      showPlotlyLegend={showLegend}
      setShowPlotlyLegend={setShowLegend}
      disableDownload
    >
      <Graph
        data={data}
        config={graphConfig}
        loading={loading || refresh}
        layout={layout}
        onRelayout={onRelayout}
        context={graphContext}
      />
      {enableChartSettingsButton && (
        <GraphControls
          typesLabel="Aggregate By"
          types={xConfig?.options}
          graphType={xConfig?.value || ''}
          setGraphType={xConfig?.setValue || (() => {})}
          compareTo={yConfig?.value}
          setCompareTo={yConfig?.setValue}
          compareToOptions={yConfig?.options}
          stackType={stackByConfig?.value}
          setStackType={stackByConfig?.setValue}
          stackTypes={stackByConfig?.options}
          adjustments={adjustmentsConfig?.values}
          setAdjustments={adjustmentsConfig?.setValues}
          adjustmentsOptions={adjustmentsConfig?.options}
          minMaxFilter={minMaxConfig?.values}
          setMinMaxFilter={minMaxConfig?.setValues}
          minMaxFilterOptions={minMaxConfig?.options}
          open={openControlsModal}
          setOpen={setOpenControlsModal}
        />
      )}
    </GraphContainer>
  );
};

export const MaGraph = ({
  data,
  title,
  xConfig,
  yConfig,
  stackByConfig,
  adjustmentsConfig,
  minMaxConfig,
  loading,
  graphLayout,
  graphContext,
  maxXAxisTicks = 50,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <div>
      <ChartGraph
        data={data}
        title={title}
        loading={loading}
        xConfig={xConfig}
        yConfig={yConfig}
        stackByConfig={stackByConfig}
        adjustmentsConfig={adjustmentsConfig}
        minMaxConfig={minMaxConfig}
        graphLayout={graphLayout}
        openModal={openModal}
        maxXAxisTicks={maxXAxisTicks}
        graphContext={graphContext}
      />
      <Modal
        open={open}
        handleClose={closeModal}
        title="Chart Expansion"
        titleAlignedWithClose
        styles={classes}
      >
        <Grid container className={classes?.root} style={{ height: '100%' }}>
          <ChartGraph
            data={data}
            title={title}
            loading={loading}
            xConfig={xConfig}
            yConfig={yConfig}
            stackByConfig={stackByConfig}
            adjustmentsConfig={adjustmentsConfig}
            minMaxConfig={minMaxConfig}
            graphLayout={graphLayout}
            isInModal
            open={open}
            openModal={openModal}
            closeModal={closeModal}
            maxXAxisTicks={maxXAxisTicks}
            graphContext={graphContext}
          />
        </Grid>
      </Modal>
    </div>
  );
};
