import { gql } from '@apollo/client';

export const GET_MANUAL_REVIEW_ENTITIES_QUERY = gql`
  query nexusManualReviewEntities(
    $status: String
    $entityType: String
    $createdAt: String
    $page: Int
    $limit: Int
  ) {
    nexusManualReviewEntities(
      status: $status
      entityType: $entityType
      createdAt: $createdAt
      page: $page
      limit: $limit
    ) {
      totalCount
      manualReviewEntities {
        id
        tentativeEntityName
        entityType
        createdAt
        manualEntityNameMapping {
          dirtyEntityName
          entityName {
            id
            name
            entityUuid
          }
        }
      }
    }
  }
`;

export const GET_MANUAL_REVIEW_ENTITY_QUERY = gql`
  query nexusManualReviewEntity($id: ID!, $levenshteinDistance: Int) {
    nexusManualReviewEntity(id: $id, levenshteinDistance: $levenshteinDistance) {
      id
      tentativeEntityName
      entityType
      createdAt
      entityNameSuggestions {
        id
        name
      }
    }
  }
`;

export const CREATE_MANUAL_ENTITY_NAME_MAPPING_MUTATION = gql`
  mutation nexusCreateManualEntityNameMapping($manualReviewEntityId: ID!, $entityNameId: ID!) {
    nexusCreateManualEntityNameMapping(
      manualReviewEntityId: $manualReviewEntityId
      entityNameId: $entityNameId
    ) {
      manualEntityNameMapping {
        id
        dirtyEntityName
        entityNameId
        entityType
        author
      }
    }
  }
`;

export const CREATE_COMPANY_MUTATION = gql`
  mutation nexusCreateCompany($companyName: String!, $manualReviewEntityId: ID!) {
    nexusCreateCompany(companyName: $companyName, manualReviewEntityId: $manualReviewEntityId) {
      company {
        id
        name
        entityUuid
        entityType
        entityValidFrom
        validFrom
      }
    }
  }
`;
