import { Box, Paper, Typography } from '@mui/material';

import React from 'react';
import { styles } from './styles';

export const PageShell = ({ title, actions = null, children }) => (
  <Box sx={styles.root}>
    <Paper sx={styles.container} elevation={1}>
      <Box sx={styles.header}>
        {!!title && (
          <Typography sx={styles.title} variant="h1">
            {title}
          </Typography>
        )}
        {actions}
      </Box>
      <Box sx={styles.pageContent}>{children}</Box>
    </Paper>
  </Box>
);
