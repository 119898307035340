import {
  OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM,
  OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS,
} from '../constants';

import { MA_OPPORTUNITIES_AGGREGATES_QUERY } from '../operations';
import { useMaContext } from '../../../MaContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaOpportunitiesAggregates = (
  by = OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  fields = OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS,
) => {
  const { filterVariables } = useMaContext();

  const requestedFields = useMemo(() => {
    return OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS.reduce((acc, field) => {
      acc[field] = fields?.includes(field);
      return acc;
    }, {});
  }, [fields]);

  const { data, loading, error } = useQuery(MA_OPPORTUNITIES_AGGREGATES_QUERY, {
    variables: { by, filters: filterVariables, ...requestedFields },
  });

  return { data: data?.maOpportunitiesAggregates || [], loading, error };
};
