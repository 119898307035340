import { ADMINS_PATH } from '../utils/constants';
import { AdminsPage } from '../pages/Admins';
import { useAdminsPageOptions } from './useAdminsPageOptions';
import { useCurrentUser } from '../contexts/CurrentUserContext';
import { useMemo } from 'react';

export const useAdminRoutes = () => {
  const { currentUser } = useCurrentUser();
  const adminsPageOptions = useAdminsPageOptions();

  const routes = [];

  if (currentUser?.admin) {
    routes.push({
      path: ADMINS_PATH,
      Page: AdminsPage,
      exact: true,
    });
  }

  // Map over the options and return the path and page component.
  // Some options may have an iframeSrc, so we include that as well.
  // To add a new option, add it to the adminsPageOptions array.
  const options = adminsPageOptions.map((option) => ({
    path: option.route,
    Page: option.pageComponent,
    iframeSrc: option.iframeSrc,
    exact: option.exact,
  }));
  routes.push(...options);

  return useMemo(() => routes, [adminsPageOptions]);
};
