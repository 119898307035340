import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import { analytics, dimensions } from '../../utils/analytics';

import FilterItem from './filterItem';
import React from 'react';
import { defaultBasicFilterTypes } from './filterTypes';
import { getUserId } from '../../utils/auth';
import { preparePropVariables } from './utils';
import { useFeatureFlags } from '../../contexts/FeatureFlagsContext';

export default ({
  filters,
  applyFilters,
  basicFilterTypes = defaultBasicFilterTypes,
  xs = 6,
  md = 3,
  lg = 3,
  context,
  googleAnalyticsTag,
  filterItemClass,
  inModal,
}) => {
  const { paHideCountriesWithNoAssets } = useFeatureFlags();
  const propVariables = preparePropVariables(filters, context);

  const setFilter = (filter, name) => {
    const newFilters = {
      ...filters,
    };
    newFilters[name] = filter;
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [name]: newFilters[name] }),
      userId: getUserId(),
      ...dimensions(newFilters, context),
    });
    applyFilters(newFilters);
  };

  return (
    <>
      {basicFilterTypes.map((filter) => (
        <FilterItem
          key={`${context}-basic-filters-${filter.name}`}
          context={`${context}-basic-filters`}
          filter={filter}
          setFilter={setFilter}
          propVariables={
            filter.propVariables && typeof filter.propVariables === 'function'
              ? filter.propVariables(filters, context, paHideCountriesWithNoAssets)
              : propVariables
          }
          value={filters[filter.key]}
          disabled={filter.disabled ? filter.disabled(filters.assets) : false}
          xs={xs}
          md={md}
          lg={lg}
          controlValues={
            filter.control?.getControlValuesFromFilters
              ? filter.control.getControlValuesFromFilters(filters)
              : {}
          }
          className={filterItemClass}
          inModal={inModal}
        />
      ))}
    </>
  );
};
