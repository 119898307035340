import React, { useEffect, useState } from 'react';
import { Slider as SliderMui, Typography } from '@mui/material';

import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
    marginBottom: 0,
  },
  slider: {
    color: theme.palette.text.primary,
    '& .MuiSlider-valueLabel': {
      '& > *': {
        '& > *': {
          color: theme.palette.baseColors.offWhite.c100,
        },
      },
    },
  },
}));

export const Slider = ({
  className,
  classNameLabel,
  value: valueProps,
  onChange: onChangeProps,
  label,
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(valueProps);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (
      (value.constructor === Array &&
        valueProps.constrctor === Array && // If value is array
        (value[0] !== valueProps[0] || value[1] !== valueProps[1])) || // Check if array has changed
      value !== valueProps // Otherwise value is a number so check if it has changed
    ) {
      setValue(valueProps);
    }
  }, [valueProps]);

  return (
    <>
      <Typography
        id={props.id}
        variant="body2"
        gutterBottom
        className={clsx(classes.label, classNameLabel)}
      >
        {label}
      </Typography>
      <SliderMui
        className={clsx(classes.slider, className)}
        value={value}
        onChange={handleChange}
        onChangeCommitted={onChangeProps}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        {...props}
      />
    </>
  );
};
