import { Box, Typography } from '@mui/material';

import { Public as PublicIcon } from '@mui/icons-material';
import React from 'react';
import { dateFormatter } from '../utils';
import { styles } from '../styles';

export const TextCol = ({ value, type }) => {
  const label = type === 'text' ? value : dateFormatter.format(new Date(value));
  return (
    <Typography
      component="span"
      variant="subtitle1"
      sx={{ color: (theme) => theme.palette.text.secondary }}
    >
      {label}
    </Typography>
  );
};

export const HeaderCol = ({ type, value, addition = null, subtitle, icon = <PublicIcon /> }) => {
  if (type === 'text' || type === 'date') {
    return <TextCol value={value} type={type} />;
  }

  if (type === 'title') {
    return (
      <Box sx={styles.titleBox}>
        <Box sx={styles.title}>
          {icon}
          <Typography sx={{ fontSize: (theme) => theme.fontSizes.bigger }} variant="body1">
            {value}
          </Typography>
          {addition}
        </Box>
        {subtitle && (
          <Typography component="span" variant="subtitle1">
            {subtitle}
          </Typography>
        )}
      </Box>
    );
  }

  if (type === 'custom') {
    return value;
  }

  return null;
};
