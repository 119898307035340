import { gql } from '@apollo/client';

export const COUNTRIES_WITH_FILTER_QUERY = gql`
  query countriesWithFilter(
    $companies: [String!]
    $assets: [JSON!]
    $basins: [String!]
    $operator: Boolean
    $hasAssets: Boolean
    $hasBlocks: Boolean
    $hasLeases: Boolean
    $hasGhgEmissions: Boolean
    $hasMaTransactions: Boolean
    $includeHistoricalParticipants: Boolean
    $sortBy: String
    $feature: String
  ) {
    countriesWithFilter(
      companies: $companies
      assets: $assets
      basins: $basins
      operator: $operator
      hasAssets: $hasAssets
      hasBlocks: $hasBlocks
      hasLeases: $hasLeases
      hasGhgEmissions: $hasGhgEmissions
      hasMaTransactions: $hasMaTransactions
      includeHistoricalParticipants: $includeHistoricalParticipants
      sortBy: $sortBy
      feature: $feature
    ) {
      id
      displayName
      name
      isoCode
      geom
      region {
        id
        name
        displayName
      }
    }
  }
`;

export const CCUS_COUNTRIES_FILTER_QUERY = gql`
  query ccusCountries($filters: JSON) {
    ccusCountries(filters: $filters) {
      id
      displayName
      name
      isoCode
      geom
      zoom
      region {
        id
        name
        displayName
      }
    }
  }
`;

export const ASSETS_BY_NAME_WITH_FILTER_QUERY = gql`
  query assetsByNameWithFilter(
    $search: String!
    $countries: [String!]
    $companies: [String!]
    $basins: [String!]
    $geologyEpochIds: [ID!]
    $operator: Boolean
    $includeHistoricalParticipants: Boolean!
    $isMAndAOpportunity: Boolean
    $feature: String
  ) {
    assetsByNameWithFilter(
      search: $search
      countries: $countries
      companies: $companies
      basins: $basins
      operator: $operator
      geologyEpochIds: $geologyEpochIds
      includeHistoricalParticipants: $includeHistoricalParticipants
      isMAndAOpportunity: $isMAndAOpportunity
      feature: $feature
    ) {
      assets {
        id
        legacyId
        name
        displayName
        country {
          id
          name
          displayName
          isoCode
        }
      }
      totalCount
    }
  }
`;

export const ASSETS_FILTER = gql`
  query assetsFilter($assets: JSON!) {
    assetsFilter(assets: $assets) {
      id
      legacyId
      name
      displayName
      country {
        id
        isoCode
      }
      participations {
        id
        effectiveDate
        formattedPercentStake
        percentStake
        company {
          id
          name
        }
      }
    }
  }
`;

export const COMPANIES_QUERY = gql`
  {
    companies {
      id
      name
    }
  }
`;

export const COMPANIES_WITH_FILTER_QUERY = gql`
  query companiesWithFilter(
    $search: String
    $assets: [JSON!]
    $countries: [String!]
    $basins: [String!]
    $activeInBlocks: Boolean!
    $activeInLeases: Boolean!
    $includeHistoricalParticipants: Boolean!
    $operator: Boolean!
    $maBuyers: Boolean
    $maSellers: Boolean
    $isMAndAOpportunity: Boolean
  ) {
    companiesWithFilter(
      search: $search
      assets: $assets
      countries: $countries
      basins: $basins
      activeInBlocks: $activeInBlocks
      activeInLeases: $activeInLeases
      includeHistoricalParticipants: $includeHistoricalParticipants
      operator: $operator
      maBuyers: $maBuyers
      maSellers: $maSellers
      isMAndAOpportunity: $isMAndAOpportunity
    ) {
      companies {
        id
        name
      }
      totalCount
    }
  }
`;

export const BASINS_WITH_FILTER_QUERY = gql`
  query basinsWithFilter(
    $search: String
    $countries: [String!]
    $assets: [JSON!]
    $companies: [String!]
    $operator: Boolean
    $hasMaTransactions: Boolean
    $isMAndAOpportunity: Boolean
  ) {
    basinsWithFilter(
      search: $search
      countries: $countries
      assets: $assets
      companies: $companies
      operator: $operator
      hasMaTransactions: $hasMaTransactions
      isMAndAOpportunity: $isMAndAOpportunity
    ) {
      totalCount
      basins {
        id
        name
      }
    }
  }
`;

export const CCUS_PROJECT_QUERY = gql`
  query dimCcusProjects($filters: JSON) {
    dimCcusProjects(filters: $filters) {
      ccusProjects {
        id
        projectName
        hubName
        countryIso
      }
    }
  }
`;

export const DEVELOPMENT_STATUS_WITH_FILTER_QUERY = gql`
  query developmentStatusWithFilter($countries: [String!]) {
    developmentStatusWithFilter(countries: $countries) {
      id
      name
      isDisabled
    }
  }
`;

export const DEVELOPMENT_STATUS_QUERY = gql`
  {
    developmentStatus {
      id
      name
    }
  }
`;

export const DEVELOPMENT_TYPE_QUERY = gql`
  {
    developmentType {
      id
      name
    }
  }
`;

export const DIM_COMPANY_TARGET_QUERY = gql`
  {
    dimCompanyTarget {
      id
      displayName
      name
    }
  }
`;

export const DIM_CCUS_DEVELOPMENT_STATUS_QUERY = gql`
  {
    dimCcusDevelopmentStatus {
      id
      developmentStatus
    }
  }
`;

export const DIM_CCUS_PROJECT_TYPE_QUERY = gql`
  {
    dimCcusProjectType {
      id
      projectType
    }
  }
`;

export const DIM_CCUS_SEPARATION_TYPE_QUERY = gql`
  {
    dimCcusSeparationType {
      id
      separationType
    }
  }
`;

export const DIM_STORAGE_TYPE_QUERY = gql`
  {
    dimStorageType {
      id
      storageType
    }
  }
`;

export const DIM_COMPANIES_WITH_FILTER_QUERY = gql`
  query dimCompaniesWithFilter($filters: JSON, $search: String) {
    dimCompaniesWithFilter(filters: $filters, search: $search) {
      companies {
        id
        companyName
      }
      totalCount
    }
  }
`;

export const DIM_FACILITIES_QUERY = gql`
  query dimFacilities($filters: JSON, $search: String) {
    dimFacilities(filters: $filters, search: $search) {
      facilities {
        id
        countryIso
        facilityName
        geom
      }
      totalCount
    }
  }
`;

export const DIM_INDUSTRY_QUERY = gql`
  query dimIndustry($filters: JSON) {
    dimIndustry(filters: $filters) {
      id
      name
      displayName
    }
  }
`;

export const DIM_SHORE_STATUS_QUERY = gql`
  {
    dimShoreStatus {
      id
      shoreStatus
    }
  }
`;

export const DIM_CCUS_PROJECT_SCALE_QUERY = gql`
  {
    dimCcusProjectScale {
      id
      projectScale
    }
  }
`;

export const DIM_STORAGE_BLOCK_STATUS_QUERY = gql`
  {
    dimStorageBlockStatus {
      id
      blockStatus
    }
  }
`;

export const FACT_FACILITY_EMISSION_MAX_VALUES_QUERY = gql`
  {
    factFacilityEmissionMaxValues {
      maxAbatementCost
      maxCaptureCost
      maxCo2Emission
      maxStorageCost
      maxTransportationCost
    }
  }
`;

export const GEOLOGIES_WITH_FILTER_QUERY = gql`
  query geologiesWithFilter($assetIdentifiers: [FiltersAssetInput!], $countries: [String!]) {
    geologiesWithFilter(assetIdentifiers: $assetIdentifiers, countries: $countries) {
      id
      displayName
      name
      epochCardinality
      era {
        id
        name
        cardinality
      }
      period {
        id
        name
        cardinality
      }
      epoch {
        id
        name
        cardinality
      }
    }
  }
`;

// temporary until lower 48 is released
export const GHG_FILTERS_QUERY = gql`
  query ghgFilter(
    $assets: [JSON!]
    $basins: [JSON!]
    $companies: [JSON!]
    $countries: [JSON!]
    $includeHistoricalParticipants: Boolean
    $operator: Boolean
    $filter: String!
    $search: String
  ) {
    ghgFilter(
      assets: $assets
      basins: $basins
      companies: $companies
      countries: $countries
      includeHistoricalParticipants: $includeHistoricalParticipants
      operator: $operator
      filter: $filter
      search: $search
    ) {
      assets {
        id
        displayName
        country {
          id
          isoCode
        }
      }
      assetUnits {
        id
        optionDisplayName
        percentStake
        displayName
        name
        companyName
        isoCode
      }
      basins {
        id
        name
      }
      companies {
        id
        name
      }
      totalCount
    }
  }
`;

export const STORAGE_SITE_QUERY = gql`
  query dimStorageSites($filters: JSON) {
    dimStorageSites(filters: $filters) {
      storageSites {
        id
        storageSiteName
        countryIso
      }
    }
  }
`;

// TODO: make it so we just intake a single filter object for all filter types
export const FACILITIES_QUERY = gql`
  query facilities($countries: [String!], $search: String) {
    facilities(countries: $countries, search: $search) {
      facilities {
        id
        name
      }
      totalCount
    }
  }
`;

export const RESOURCE_THEME_QUERY = gql`
  {
    resourceType {
      id
      displayName
      name
    }
  }
`;

export const SHORE_STATUS_QUERY = gql`
  {
    shoreStatus {
      id
      name
    }
  }
`;
