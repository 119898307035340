import DefaultReport from '../../../../../assets/images/default-report.png';

export const COMPONENTS_MOCK = [
  {
    type: 'text',
    name: 'Text Fields',
    components: [
      {
        type: 'title',
        name: 'Title',
        img: DefaultReport,
      },
      {
        type: 'subtitle',
        name: 'Subtitle',
        img: DefaultReport,
      },
      {
        type: 'body-text',
        name: 'Body Text',
        img: DefaultReport,
      },
      {
        type: 'bullet-points',
        name: 'Bullet Points',
        img: DefaultReport,
      },
    ],
  },
  {
    type: 'tables',
    name: 'Tables',
    components: [
      {
        type: 'assets',
        name: 'Assets',
        img: DefaultReport,
      },
      {
        type: 'participants',
        name: 'Participants',
        img: DefaultReport,
      },
    ],
  },
  {
    type: 'chart',
    name: 'Charts',
    components: [
      {
        type: 'production',
        name: 'Production Line Chart',
        img: DefaultReport,
      },
      {
        type: 'cashflow',
        name: 'Cashflow Line Chart',
        img: DefaultReport,
      },
    ],
  },
  {
    type: 'images',
    name: 'Images',
    components: [
      {
        type: 'assets',
        name: 'Assets',
        img: DefaultReport,
      },
      {
        type: 'country',
        name: 'Country View',
        img: DefaultReport,
      },
    ],
  },
];

export const LAYOUT_MOCK = [
  {
    type: 'title',
    components: ['text'],
    img: DefaultReport,
  },
  {
    type: 'chart-with-text',
    components: ['text', 'chart', 'tables', 'images'],
    img: DefaultReport,
  },
  {
    type: 'table-with-text',
    components: ['text', 'chart', 'tables', 'images'],
    img: DefaultReport,
  },
];

export const REPORTS_MOCK = [
  {
    id: 1,
    type: 'country',
    entity: 'Brazil',
    metadata: {
      name: 'Brazil Country Report',
      reportType: 'Country Report',
    },
    status: 'published',
    createdAt: new Date().toISOString(),
    createdBy: 'John Doe',
    pages: [
      {
        img: DefaultReport,
        number: 1,
        layout: 'title',
        items: [
          {
            type: 'header',
            text: 'This is the title page',
            chart: null,
            table: null,
          },
        ],
      },
      {
        img: DefaultReport,
        number: 2,
        layout: 'chart-with-text',
        items: [
          {
            type: 'header',
            text: 'This is the chart page',
            chart: null,
            table: null,
          },
          {
            type: 'chart-pa-production',
            text: null,
            chart: null,
            table: null,
          },
        ],
      },
    ],
    versions: [
      {
        id: 1,
        version: 6,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 2,
        version: 5,
        status: 'published',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 3,
        version: 4,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 4,
        version: 3,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 5,
        version: 2,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 6,
        version: 1,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
    ],
  },
  {
    id: 2,
    type: 'country',
    entity: 'USA',
    metadata: {
      name: 'USA Country Report',
      reportType: 'Country Report',
    },
    status: 'published',
    createdAt: new Date().toISOString(),
    createdBy: 'John Doe',
    versions: [
      {
        id: 7,
        version: 5,
        status: 'published',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 8,
        version: 4,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 9,
        version: 3,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 10,
        version: 2,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        id: 11,
        version: 1,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
    ],
  },
  {
    id: 3,
    type: 'country',
    entity: 'GoM',
    metadata: {
      name: 'GoM Country Report',
      reportType: 'Country Report',
    },
    status: 'draft',
    createdAt: new Date().toISOString(),
    createdBy: 'John Doe',
    versions: [
      {
        id: 12,
        version: 1,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
    ],
  },
  {
    id: 4,
    type: 'corporate',
    entity: 'Shell',
    metadata: {
      name: 'Shell Company Report',
      reportType: 'Company Report',
    },
    status: 'draft',
    createdAt: new Date().toISOString(),
    createdBy: 'Jane Doe',
    versions: [
      {
        id: 13,
        version: 1,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'Jane Doe',
      },
    ],
  },
  {
    id: 5,
    type: 'asset',
    entity: 'P-51',
    metadata: {
      name: 'P-51 Asset Report',
      reportType: 'Asset Report',
    },
    status: 'draft',
    createdAt: new Date().toISOString(),
    createdBy: 'Jane Doe',
    versions: [
      {
        id: 14,
        version: 1,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'Jane Doe',
      },
    ],
  },
];
