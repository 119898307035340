import { getChartOptions } from '../../helpers';
import { titleize } from '../../../../utils/helpers';

export const OPPORTUNITIES_AGGREGATE_FIELDS_ENUM = {
  VALUATIONS: 'valuationsSum',
  RESERVES: 'reservesSum',
  PRODUCTIONS: 'productionsSum',
  ACQUISITION_COST: 'acquisitionCostSum',
  COUNT: 'countSum',
};

export const OPPORTUNITIES_AGGREGATE_FIELDS_OPTIONS = Object.values(
  OPPORTUNITIES_AGGREGATE_FIELDS_ENUM,
);

export const OPPORTUNITIES_AGGREGATE_BY_TITLE = 'NPV10 Valuation (US$MM)';

export const OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM = {
  COUNTRY: 'country',
  COMPANY: 'seller',
};

export const OPPORTUNITIES_AGGREGATE_GRAPH_BY_OPTIONS = getChartOptions(
  Object.values(OPPORTUNITIES_AGGREGATE_BY_TYPE_ENUM),
);

export const OPPORTUNITIES_STACK_BY_TYPE_ENUM = {
  SELLER: 'seller',
};

export const OPPORTUNITIES_STACK_BY_OPTIONS = Object.values(OPPORTUNITIES_STACK_BY_TYPE_ENUM).map(
  (d) => ({
    value: d,
    label: titleize(d),
    // Disabled the stack option if it's the same as the graph type
    isDisabled: ({ graphType }) => graphType === d,
  }),
);

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM = {
  RESERVES: 'reserves',
  PRODUCTIONS: 'productions',
  ACQUISITION_COST: 'acquisitionCost',
  COUNT: 'count',
};

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM = {
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.RESERVES]: OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.RESERVES,
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.PRODUCTIONS]:
    OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.PRODUCTIONS,
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.ACQUISITION_COST]:
    OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.ACQUISITION_COST,
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.COUNT]: OPPORTUNITIES_AGGREGATE_FIELDS_ENUM.COUNT,
};

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM = {
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.RESERVES]: 'Reserves (MMboe)',
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.PRODUCTIONS]: 'Production (boe/d)',
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.ACQUISITION_COST]: 'Acquisition Cost (US$/boe)',
  [OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM.COUNT]: 'Number of Opportunities',
};

export const OPPORTUNITIES_AGGREGATE_COMPARE_TO_OPTIONS = Object.values(
  OPPORTUNITIES_AGGREGATE_COMPARE_TO_ENUM,
).map((name) => ({
  label: OPPORTUNITIES_AGGREGATE_COMPARE_TO_TITLES_ENUM[name],
  value: name,
}));
