import {
  ADMINS_SMART_REPORTS_EDIT_REPORT_PATH,
  ADMINS_SMART_REPORTS_NEW_PATH,
  ADMINS_SMART_REPORTS_PATH,
} from '../../../../utils/constants';

import { NewSmartReport } from './components/NewSmartReport';
import React from 'react';
import { Route } from '@mui/icons-material';
import { SmartReportList } from './components/SmartReportList';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { EditReport } from './EditReport/EditReport';
import ProtectedRoute from '../../../../components/ProtectedRoute';
import ErrorBoundary from '../../../../components/ErrorBoundary';

export const SmartReports = () => {
  return (
    <Switch path={ADMINS_SMART_REPORTS_PATH}>
      <ProtectedRoute
        exact
        key={ADMINS_SMART_REPORTS_EDIT_REPORT_PATH}
        path={ADMINS_SMART_REPORTS_EDIT_REPORT_PATH}
      >
        <ErrorBoundary>
          <EditReport />
        </ErrorBoundary>
      </ProtectedRoute>
      <Route exact path={ADMINS_SMART_REPORTS_PATH} component={SmartReportList} />
      <Route exact path={ADMINS_SMART_REPORTS_NEW_PATH} component={NewSmartReport} />
    </Switch>
  );
};
