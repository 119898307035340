import React, { useEffect, useRef, useState } from 'react';

import CardListItem from './cardListItem';
import Grid from '@mui/material/Grid2';
import Loading from '../Loading';
import PageScroll from './pageScroll';
import Sort from './sort';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: '22px',
    margin: 0,
    width: '100%',
    transform: 'none',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  autoPosition: {
    bottom: 'auto',
    position: 'relative',
  },
  list: {
    margin: 0,
    width: '100%',
    height: '84px',
    [theme.breakpoints.down('lg')]: {
      height: '80px',
    },
    [theme.breakpoints.down('md')]: {
      height: '75px',
    },
  },
  listWithData: {
    height: '100px',
    [theme.breakpoints.down('lg')]: {
      height: '93px',
    },
    [theme.breakpoints.down('md')]: {
      height: '87px',
    },
  },
  cardListContainer: {
    margin: 0,
    width: 'calc(100% - 50px)',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  cardList: {
    margin: 0,
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    overflowY: 'scroll',
    scrollSnapType: 'y proximity',
  },
  cardListItemContainer: {
    scrollSnapAlign: 'start',
  },
  text: {
    padding: '0 10px',
  },
}));

let reportsObserver = null;

// autoPosition - override position of card list
// getDateText, onCardClick, dateLabel - prop for cardListItem
// items - array of items to render to cards
// loading - boolean if list is loading
// selectedItem - the selected item
// title - title of list
// setSortType, sortType, sortTypes - props for sort component
const CardList = ({
  autoPosition,
  dateLabel,
  getDateText,
  items = [],
  loading,
  onCardClick,
  selectedItem,
  setSortType,
  sortType,
  sortTypes,
  title,
}) => {
  const classes = useStyles();
  const [currPage, setCurrPage] = useState(1);
  const cardListRef = useRef(null);
  // numCols is used to help calculate pagination
  let numCols = 6;

  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  if (isSm) {
    numCols = 3;
  } else if (isMd) {
    numCols = 4;
  }

  // calculates total pages
  const numPages = Math.ceil(items.length / numCols);

  // for hiding the scrollbar so we can use our custom paginated scroll
  const getScrollbarWidth = () => {
    if (cardListRef && cardListRef.current) {
      const el = cardListRef.current;
      return `calc(100% + ${el.offsetWidth} - ${el.clientWidth})`;
    }
    return 'calc(100% + 17px)';
  };

  // observer for handling paginated scrolling
  const handleObserver = (entities) => {
    const entity = entities[0];
    if (entity.isIntersecting) {
      const newCurrentPage = parseInt(entity.target.dataset.page);
      setCurrPage(newCurrentPage > numPages ? numPages : newCurrentPage);
    }
  };

  // sets the pagination observer
  useEffect(() => {
    let options = {
      root: cardListRef.current,
      rootMargin: '0px',
      threshold: 0.9,
    };

    reportsObserver = new IntersectionObserver(handleObserver, options);

    for (let page = 1; page <= numPages; page++) {
      const el = document.getElementById(`${title}-card-list-item-row-${page}`);
      reportsObserver.observe(el);
    }
  }, [items, currPage, numPages]);

  // renders the card list items where id of grid is used to attatch pagination observer (only first card per row)
  const CardListItems = () =>
    items.map((item, idx) => {
      const page = idx / numCols + 1;
      return (
        <Grid
          key={`${title}-card-list-item-${item.displayName}-${idx}`}
          id={idx % numCols === 0 ? `${title}-card-list-item-row-${page}` : undefined}
          data-page={page}
          container
          className={classes.cardListItemContainer}
          size={{
            xs: 4,
            md: 3,
            lg: 2,
          }}
        >
          <CardListItem
            item={item}
            dateLabel={dateLabel}
            getDateText={getDateText}
            onCardClick={onCardClick}
            sortType={sortType}
            selected={selectedItem?.displayName === item.displayName}
          />
        </Grid>
      );
    });

  return (
    <Grid
      container
      className={clsx({ [classes.root]: true, [classes.autoPosition]: autoPosition })}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid container direction="row">
            <Typography variant="overline" className={classes.text}>
              {title} LIST: {items.length}
            </Typography>
            {sortType && sortTypes && (
              <Sort
                setSortType={setSortType}
                sortType={sortType}
                sortTypes={sortTypes}
                styles={classes}
              />
            )}
          </Grid>
          <Grid
            container
            className={clsx({ [classes.list]: true, [classes.listWithData]: !!sortType.getValue })}
          >
            <Grid container className={classes.cardListContainer}>
              <Grid
                container
                ref={cardListRef}
                className={classes.cardList}
                style={{ width: getScrollbarWidth() }}
                direction="row"
                spacing={2}
              >
                <CardListItems />
              </Grid>
            </Grid>
            <PageScroll currPage={currPage} numPages={numPages} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CardList;
