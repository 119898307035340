import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';

import { DataCol } from './components/DataCol';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { HeaderCol } from './components/HeaderCol';
import React from 'react';
import { styles } from './styles';

const getValue = (value, data, reportId) =>
  (value && typeof value === 'function' ? value(data, reportId) : value) || null;

const CollapseLocal = ({ data, columnsWidth, columnConfig, showLeadingLines, getDataArray }) => {
  const rows = getDataArray(data);
  return (
    <Accordion sx={styles.accordion} square>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}>
        <Box sx={styles.grid(columnsWidth)}>
          {columnConfig.map((col, idx) => (
            <HeaderCol
              key={`${data.id}-${idx}`}
              type={col.type}
              value={getValue(col.headerValue, data)}
              addition={getValue(col.headerAddition, data)}
              subtitle={getValue(col.headerSubtitle, data)}
              icon={getValue(col.icon, data)}
            />
          ))}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <Box sx={styles.gridVersions(columnsWidth)}>
          {rows.map((row, index) => {
            const isLast = index === rows.length - 1;
            return (
              <>
                {columnConfig.map((col, idx) => (
                  <DataCol
                    key={`${data.id}-${row.id}-${idx}`}
                    type={col.type}
                    value={getValue(col.value, row, data.id)}
                    addition={getValue(col.addition, row)}
                    status={getValue(col.status, row)}
                    isLast={isLast}
                    showLeadingLines={showLeadingLines}
                  />
                ))}

                <div />
              </>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const CollapseStack = ({ data, columnConfig, showLeadingLines = true, getDataArray }) => {
  const columnsWidth = columnConfig.map((col) => col.width || '1fr');

  return (
    <Box>
      {data.map((item) => (
        <CollapseLocal
          key={item.id}
          data={item}
          columnConfig={columnConfig}
          columnsWidth={columnsWidth}
          showLeadingLines={showLeadingLines}
          getDataArray={getDataArray}
        />
      ))}
    </Box>
  );
};
