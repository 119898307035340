import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import {
  ASSETS,
  BASINS,
  COMPANIES,
  COUNTRIES,
  FilterKeys,
  HYDROCARBONS,
  OPPORTUNITIES,
  SHORE_STATUSES,
} from '../../../components/Filters/filterTypes';
import { VALUATIONS_CONTEXT, VALUATIONS_GOOGLE_ANALYTICS_TAG } from '../constants';
import Button from '@mui/material/Button';
import Chips from './chips';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import { defaultDimensions } from '../../../utils/analytics';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { FilterBarWrapper } from '../../../components/FilterBarWrapper';

const useStyles = makeStyles((theme) => ({
  filtersRoot: {
    flexShrink: 0,
  },
  formContainer: {
    padding: '10px',
  },
  valuationButton: {
    ...theme.palette.button,
    margin: '13px 2px',
    height: '36px',
    width: 'fit-content',
    minWidth: 'none',
  },
  filters: {
    width: '100%',
  },
  valuationFormControl: {
    minWidth: 0,
    width: '140px',
    height: '36px',
  },
  valuationSelects: {
    margin: theme.spacing(1),
    minWidth: 0,
    width: '140px',
    height: '36px',
  },
  valuationSelectsInput: {
    minWidth: 0,
    width: '140px',
    height: '36px',
  },
  valuationSelectLineHeight: {
    lineheight: 0,
  },
  valuationRadioGroup: {
    margin: theme.spacing(1),
  },
  valuationFilterRow: {
    marginTop: '20px',
  },
  valuationFilterAdvancedRow: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      width: '155px',
    },

    '& .MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      maxHeight: '28px',
    },
  },
  partLeft: {
    display: 'flex',
  },
  partRight: {
    display: 'flex',
    flexShrink: 0,
    paddingRight: '8px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  buttonSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexShrink: '0',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      width: '155px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingBottom: '.5rem',
      rowGap: '1rem',
      minWidth: 'unset',
    },
  },
  filterSection: {
    display: 'flex',
    flexGrow: '1',
    flexWrap: 'wrap',
    '& .advanced-autocomplete': {
      padding: '0',
    },
    '& .MuiAutocomplete-root': {
      margin: '0',
    },
  },
  filterItem: {
    minWidth: '1px',
    flexBasis: '16.66%',
    [theme.breakpoints.down('lg')]: {
      flexBasis: '33.33%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
}));

export default ({
  filters,
  setFilters,
  clearFilters,
  setWatchList,
  watchList,
  openAdvancedFilters,
}) => {
  const classes = useStyles();

  const setFilter = (filter, name) => {
    const newFilters = {
      ...filters,
    };
    newFilters[name] = filter;
    setFilters(newFilters);
  };

  const handleAdvancedFilterClick = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'advanced_filters',
      userId: getUserId(),
      ...defaultDimensions,
    });
    openAdvancedFilters();
  };

  return (
    <Grid className={classes.filtersRoot} container direction="column">
      <div id="valuations-asset-filters" className={classes.filters}>
        <FilterBarWrapper
          filters={filters}
          applyFilters={setFilters}
          basicFilterTypes={[COUNTRIES, ASSETS, COMPANIES, BASINS, SHORE_STATUSES, HYDROCARBONS]}
          context={VALUATIONS_CONTEXT}
          googleAnalyticsTag={VALUATIONS_GOOGLE_ANALYTICS_TAG}
        >
          <div className={classes.buttonSection}>
            <Button
              startIcon={<FilterListIcon />}
              id="valuations-advanced-filters"
              className={classes.valuationButton}
              variant="contained"
              color="secondary"
              onClick={handleAdvancedFilterClick}
            >
              All Filters
            </Button>
            <div className={classes.switchWrapper}>
              <div id="valuations-watch-list-switch">
                <FormControlLabel
                  labelPlacement="start"
                  checked={watchList}
                  control={
                    <Switch
                      color="secondary"
                      onChange={({ target }) => {
                        if (target.checked)
                          gtag('event', ANALYTICS_EVENTS.event, {
                            event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
                            event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                            event_label: 'view_watchlist',
                            userId: getUserId(),
                            ...defaultDimensions,
                          });
                        setWatchList(target.checked);
                      }}
                    />
                  }
                  label={<Typography variant="overline">View Watch List</Typography>}
                />
              </div>
              <div id="valuations-ma-switch">
                <FormControlLabel
                  labelPlacement="start"
                  checked={filters[FilterKeys.opportunities]}
                  control={
                    <Switch
                      color="secondary"
                      onChange={({ target }) => {
                        if (target.checked)
                          gtag('event', ANALYTICS_EVENTS.event, {
                            event_category: VALUATIONS_GOOGLE_ANALYTICS_TAG,
                            event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                            event_label: 'view_ma_opportunities',
                            userId: getUserId(),
                            ...defaultDimensions,
                          });
                        setFilter(target.checked, FilterKeys.opportunities);
                      }}
                    />
                  }
                  label={<Typography variant="overline">{OPPORTUNITIES.label}</Typography>}
                />
              </div>
            </div>
          </div>
        </FilterBarWrapper>
      </div>
      <Chips filters={filters} setFilters={setFilters} clearFilters={clearFilters} />
    </Grid>
  );
};
