import { gql } from '@apollo/client';

export const CREATE_HUBSPOT_TICKET_MUTATION = gql`
  mutation createHubspotTicket($issueType: String!, $summary: String!, $description: String!) {
    createHubspotTicket(issueType: $issueType, summary: $summary, description: $description) {
      createHubspotTicket {
        created
      }
    }
  }
`;
