import React, { useMemo, useState } from 'react';

import { MaGraph } from '../MaGraph';
import { SCATTER_PLOT_CHART_CONTEXT } from '../../../../components/Graphs/constants';
import { getGraphLayout } from '../../helpers';
import { truncate } from '../../helpers';
import { useGetDeals } from './hooks/useGetDeals';
import { useGetMinMaxFilters } from '../Filters/hooks/useGetMinMaxFilters';
import { useStyles } from './styles';

const AcquisitionCostGraph = ({ data, customData, loading, minMaxConfig, graphContext }) => {
  const chartTitle = 'Acquisition Cost (US$/boe)';
  const chartData = useMemo(
    () => [
      {
        x: data?.map((d) => d.effectiveDate) || [],
        y: data?.map((d) => d.acquisitionCostSum) || [],
        name: chartTitle,
        type: 'scatter',
        mode: 'markers',
        customdata: customData,
        hovertemplate: `Year: %{x|%Y}<br>${chartTitle}: %{y:,.2f}<br>Asset(s): %{customdata[0]}<br>Buyer: %{customdata[1]}<br>Seller: %{customdata[2]}<extra></extra>`,
      },
    ],
    [data],
  );

  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: chartTitle,
    xAxisType: 'date',
  });

  return (
    <MaGraph
      data={chartData}
      title={chartTitle}
      loading={loading}
      graphLayout={graphLayout}
      minMaxConfig={minMaxConfig}
      graphContext={graphContext}
    />
  );
};

const PriceFlowingBarrelGraph = ({ data, customData, loading, minMaxConfig, graphContext }) => {
  const chartTitle = 'Price per Flowing Barrel (US$/boe)';

  const chartData = useMemo(
    () => [
      {
        x: data?.map((d) => d.effectiveDate) || [],
        y: data?.map((d) => d.priceFlowingBarrelSum) || [],
        name: chartTitle,
        type: 'scatter',
        mode: 'markers',
        customdata: customData,
        hovertemplate: `Year: %{x|%Y}<br>${chartTitle}: %{y:,.2f}<br>Asset(s): %{customdata[0]}<br>Buyer: %{customdata[1]}<br>Seller: %{customdata[2]}<extra></extra>`,
      },
    ],
    [data],
  );

  const graphLayout = getGraphLayout({
    showLegend: true,
    yAxisTitle: chartTitle,
    xAxisType: 'date',
  });

  return (
    <MaGraph
      data={chartData}
      title={chartTitle}
      loading={loading}
      graphLayout={graphLayout}
      minMaxConfig={minMaxConfig}
      graphContext={graphContext}
    />
  );
};

export const TransactionAnalytics = () => {
  const classes = useStyles();
  const { acquisitionCostMinMax, priceFlowingBarrel } = useGetMinMaxFilters();
  const [minMaxFilter, setMinMaxFilter] = useState({});

  const acqMinMaxConfig = {
    values: minMaxFilter,
    setValues: setMinMaxFilter,
    options: [acquisitionCostMinMax],
  };

  const prcMinMaxConfig = {
    values: minMaxFilter,
    setValues: setMinMaxFilter,
    options: [priceFlowingBarrel],
  };

  const { data, loading, error } = useGetDeals(minMaxFilter);

  const customData = useMemo(
    () =>
      data?.map((d) => {
        const { assetNames, buyerNames, sellerNames } = d?.maCompanyTransactions.reduce(
          (acc, c) => {
            return {
              assetNames: [...acc.assetNames, c?.maAssetTransactions?.map((a) => a?.assetName)],
              buyerNames: [...acc.buyerNames, c?.buyerCompany?.name],
              sellerNames: [...acc.sellerNames, c?.sellerCompany?.name],
            };
          },
          { assetNames: [], buyerNames: [], sellerNames: [] },
        );

        return [
          // remove duplicates and truncate at 100 chars
          truncate([...new Set(assetNames.flat())].join(', '), 100),
          truncate([...new Set(buyerNames)].join(', '), 100),
          truncate([...new Set(sellerNames)].join(', '), 100),
        ];
      }),
    [data],
  );

  if (error) return null;

  return (
    <div className={classes.graphWrapper}>
      <AcquisitionCostGraph
        data={data}
        customData={customData}
        loading={loading}
        minMaxConfig={acqMinMaxConfig}
        graphContext={SCATTER_PLOT_CHART_CONTEXT}
      />
      <PriceFlowingBarrelGraph
        data={data}
        customData={customData}
        loading={loading}
        minMaxConfig={prcMinMaxConfig}
        graphContext={SCATTER_PLOT_CHART_CONTEXT}
      />
    </div>
  );
};
