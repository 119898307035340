import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import React from 'react';
import { Skeleton } from '@mui/material';
import { getPageTitle } from '../../helpers';
import { useStyles } from './styles';

export const VideoGalleryGridItemSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid item className={classes.gridItem}>
      <Card className={classes.card}>
        <Skeleton animation="wave" variant="rectangular" height={140} />
        <CardContent>
          <Skeleton animation="wave" variant="text" width="70%" height="30px" />
          <Skeleton animation="wave" variant="text" width="30%" />
          <Skeleton animation="wave" variant="text" />
        </CardContent>
      </Card>
    </Grid>
  );
};

export const VideoGalleryGridItem = ({ video, onClick }) => {
  const classes = useStyles();

  const onVideoClick = (video) => {
    if (onClick && typeof onClick === 'function') {
      onClick(video);
    }
  };

  return (
    <Grid item key={video.id} className={classes.gridItem}>
      <Card className={classes.card}>
        <CardActionArea onClick={() => onVideoClick(video)}>
          <CardMedia component="img" alt={video.title} height="140" image={video.thumbnailUrl} />
          <CardContent>
            <Box sx={{ display: 'flex' }}>
              <Tooltip title={video.title} placement="top-end">
                <Typography noWrap gutterBottom variant="h5" component="h2">
                  {video.title}
                </Typography>
              </Tooltip>
            </Box>
            <Typography sx={{ color: 'textSecondary' }}>
              {`${getPageTitle(video.contextPage)} #${video.contextOrder}`}
            </Typography>
            <Typography noWrap variant="body2" component="p" sx={{ color: 'textSecondary' }}>
              {video.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
