import { MaFilterLabels, MaFilterMaxKeys, MaSliderKeys } from '../constants';
import { getMarks, roundDownToNearestThousand, roundUpToNearestThousand } from '../utils';

import { titleize } from '../../../../../utils/helpers';
import { useGetMaFiltersSummary } from './useGetMaFiltersSummary';
import { useMemo } from 'react';

export const useGetSliderFilterTypes = () => {
  const { data, loading } = useGetMaFiltersSummary();

  const sliderFilters = useMemo(() => {
    if (!data || loading) {
      return [];
    }

    return Object.entries(MaSliderKeys).reduce((acc, [key, keyValue]) => {
      const upperKey = titleize(key);
      const min = Math.floor(data[`min${upperKey}`]);
      const max = Math.ceil(data[`max${upperKey}`]);
      const marks = getMarks(roundDownToNearestThousand(min), roundUpToNearestThousand(max));

      acc.push({
        key: keyValue,
        label: MaFilterLabels[key],
        maxKey: MaFilterMaxKeys[key],
        marks,
      });
      return acc;
    }, []);
  }, [data, loading]);

  const sliderMaxValues = useMemo(() => {
    if (!data || loading) {
      return {};
    }

    return Object.entries(MaFilterMaxKeys).reduce((acc, [key, keyValue]) => {
      const upperKey = titleize(key);
      const maxValue = Math.ceil(data[`max${upperKey}`]);
      acc[keyValue] = roundUpToNearestThousand(maxValue);
      return acc;
    }, {});
  }, [data, loading]);

  return {
    sliderFilters: sliderFilters || [],
    sliderMaxValues: sliderMaxValues || {},
  };
};
