import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PageShell } from '../../../PageShell';
import { styles } from './styles';
import { useQuery } from '@apollo/client';
import { GET_MANUAL_REVIEW_ENTITIES_QUERY } from '../../../../operations';
import { formatTimestamp } from '../../../../../../utils/helpers';
import { EntityDetailModal } from '../EntityDetailModal';

const ENTITY_TYPES = {
  COMPANY: 'Company',
  ASSET: 'Asset',
};

const STATUS_OPTIONS = {
  ASSIGNED: 'Assigned',
  UNASSIGNED: 'Unassigned',
};

const Filter = ({ onApply }) => {
  const [entityType, setEntityType] = useState(null);
  const [status, setStatus] = useState(null);
  const [createdAt, setcreatedAt] = useState(null);

  const handleFilterChange = () => {
    onApply({ entityType, status, createdAt });
  };

  return (
    <Box sx={styles.filterContainer}>
      <FormControl sx={styles.select}>
        <InputLabel>Entity Type </InputLabel>
        <Select value={entityType} onChange={(e) => setEntityType(e.target.value)}>
          <MenuItem value={null}>
            <em>All</em>
          </MenuItem>
          {Object.entries(ENTITY_TYPES).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={styles.select}>
        <InputLabel>Status</InputLabel>
        <Select value={status} onChange={(e) => setStatus(e.target.value)}>
          <MenuItem value={null}>
            <em>All</em>
          </MenuItem>
          {Object.entries(STATUS_OPTIONS).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        type="date"
        label="Ingested At"
        value={createdAt}
        onChange={(e) => setcreatedAt(e.target.value)}
        sx={styles.dateInput}
        slotProps={{ inputLabel: { shrink: true } }}
      />

      <Button variant="contained" color="primary" onClick={handleFilterChange} sx={styles.button}>
        Apply Filters
      </Button>
    </Box>
  );
};

const ListItem = ({ entity, onRowClick }) => {
  return (
    <TableRow key={entity.id} onClick={onRowClick} sx={styles.tableRow}>
      <TableCell>{entity.tentativeEntityName}</TableCell>
      <TableCell>{ENTITY_TYPES[entity.entityType]}</TableCell>
      <TableCell>{entity.manualEntityNameMapping?.entityName?.name}</TableCell>
      <TableCell>{formatTimestamp(entity.createdAt)}</TableCell>
    </TableRow>
  );
};

const List = ({ entities, onRowClick, totalCount, onPaginate, pagination }) => {
  const handleChangePage = (_, newPage) => {
    onPaginate({ ...pagination, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    onPaginate({ page: 1, limit: parseInt(event.target.value, 10) });
  };

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.tableHeader}>Name</TableCell>
            <TableCell sx={styles.tableHeader}>Type</TableCell>
            <TableCell sx={styles.tableHeader}>Assigned Entity</TableCell>
            <TableCell sx={styles.tableHeader}>Ingested At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entities.map((entity) => (
            <ListItem key={entity.id} entity={entity} onRowClick={() => onRowClick(entity)} />
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={pagination.limit}
        page={pagination.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const LoadingState = () => {
  return (
    <Box sx={styles.loadingContainer}>
      <CircularProgress size={40} sx={styles.spinner} />
      <Typography sx={styles.loadingText}>Loading...</Typography>
    </Box>
  );
};

const ErrorState = ({ error, onRetry }) => {
  return (
    <Box sx={styles.errorContainer}>
      <ErrorOutlineIcon sx={styles.errorIcon} />
      <Typography sx={styles.errorText}>Something went wrong</Typography>
      <Typography sx={styles.errorMessage}>{error.message}</Typography>
      <Button variant="contained" color="primary" onClick={onRetry} sx={styles.retryButton}>
        Try Again
      </Button>
    </Box>
  );
};

export const EntityNameList = () => {
  const [selectedEntityId, setSelectedEntityId] = useState(null);

  const getQueryParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      page: Number(params.get('page')) || 1,
      limit: Number(params.get('limit')) || 10,
      entityType: params.get('entityType') || null,
      status: params.get('status') || null,
      createdAt: params.get('createdAt') || null,
    };
  }, []);

  const [filters, setFilters] = useState(getQueryParams());

  const [isRefetching, setIsRefetching] = useState(false);
  const { data, error, loading, refetch } = useQuery(GET_MANUAL_REVIEW_ENTITIES_QUERY, {
    variables: filters,
  });
  const response = data?.nexusManualReviewEntities;
  const entities = response?.manualReviewEntities || [];
  const totalCount = response?.totalCount || 0;

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };

      // Update URL without refreshing the page
      const params = new URLSearchParams(
        Object.entries(updatedFilters).filter(([, value]) => value !== null && value !== ''),
      );
      window.history.replaceState(null, '', `?${params.toString()}`);

      return updatedFilters;
    });
  };

  const handleRowClick = (entity) => {
    setSelectedEntityId(entity.id);
  };

  const handeEntityUpdate = async () => {
    setIsRefetching(true);
    try {
      await refetch();
    } finally {
      setIsRefetching(false);
    }
  };

  return (
    <PageShell title="Nexus - Entity Name Corrections">
      <Filter onApply={handleFilterChange} />
      {loading || isRefetching ? (
        <LoadingState />
      ) : error ? (
        <ErrorState error={error} onRetry={() => handleFilterChange(filters)} />
      ) : (
        <List
          entities={entities}
          onRowClick={handleRowClick}
          totalCount={totalCount}
          onPaginate={handleFilterChange}
          pagination={filters}
        />
      )}
      <EntityDetailModal
        open={Boolean(selectedEntityId)}
        onClose={(hasUpdates) => {
          hasUpdates && handeEntityUpdate();
          setSelectedEntityId(null);
        }}
        entityId={selectedEntityId}
      />
    </PageShell>
  );
};
