import { MA_OPPORTUNITIES_QUERY } from '../../../operations';
import { useMaContext } from '../../../../../MaContext';
import { useQuery } from '@apollo/client';

export const useGetMaOpportunities = (page, perPage, order, orderBy) => {
  const { filterVariables } = useMaContext();
  const { loading, error, data } = useQuery(MA_OPPORTUNITIES_QUERY, {
    variables: { filters: filterVariables, page: page + 1, perPage, order, orderBy },
  });

  return {
    data: data?.maOpportunities?.opportunities || [],
    totalCount: data?.maOpportunities?.totalCount || undefined,
    loading,
    error,
  };
};
