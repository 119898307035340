const button = {
  textTransform: 'none',
  borderRadius: '2em',
  minWidth: '160px',
  padding: '10px 20px',
  margin: '10px 20px 20px 0',
  fontSize: '1rem',
  boxShadow: 'none',
};

export const styles = {
  container: {
    marginTop: '50px',
  },

  row: {
    marginBottom: '30px',
  },

  label: {
    fontFamily: (theme) => theme.typography.fontFamily,
    fontWeight: 'normal',
  },

  button: button,
  cancelButton: {
    ...button,
    backgroundColor: (theme) => theme.palette.baseColors.gray.c100,
  },

  select: {
    width: '100%',
    maxWidth: '400px',
    minWidth: '300px',
    maxHeight: '50px',
    boxShadow: 'none',
    textAlign: 'end',

    '& .MuiOutlinedInput-input.MuiSelect-select.MuiInputBase-input': {
      backgroundColor: (theme) => theme.palette.baseColors.gray.c75,
      borderRadius: '1em',
      paddingRight: '40px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  templateList: {
    maxHeight: '500px',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },

  template: {
    maxWidth: '280px',
    cursor: 'pointer',
    padding: '5px',

    '> img': { borderRadius: '1em' },

    '&.selected': {
      '> img': { outline: (theme) => `5px solid ${theme.palette.info.light}` },
    },
  },

  noTemplate: {
    maxWidth: '280px',
    maxHeight: '145px',
    height: '100%',
    borderRadius: '1em',
    margin: '5px 0 ',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: (theme) => `5px dashed ${theme.palette.baseColors.darkGray.c25}`,
    color: (theme) => theme.palette.baseColors.darkGray.c25,

    '&.selected': {
      outline: (theme) => `5px solid ${theme.palette.info.light}`,
      color: (theme) => theme.palette.info.light,
    },
  },
};
