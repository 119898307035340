import * as React from 'react';

import { ADMINS_PATH, ROOT_PATH, SIGN_IN_PATH } from '../../utils/constants';
import { AdminPanelSettings, Logout, Public } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FullLogoLightBackground from '../../../assets/images/FullLogoLightBackground.svg';
import IconButton from '@mui/material/IconButton';
import { LOGOUT_MUTATION } from '../../pages/Login/operations';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import { clearUserCredentials } from '../../utils/auth';
import { useAdminsPageOptions } from '../../hooks/useAdminsPageOptions';
import { useMutation } from '@apollo/client';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: theme.palette.baseColors.deepSeaNavy.c100,
  color: theme.palette.baseColors.offWhite.c100,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: theme.palette.baseColors.deepSeaNavy.c100,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  background: theme.palette.baseColors.deepSeaNavy.c100,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));

const ListItemIconWrapper = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, selected }) => ({
  minWidth: 0,
  justifyContent: 'center',
  marginRight: open ? 30 : 'auto',
  color: selected ? theme.palette.baseColors.offWhite.c100 : theme.palette.baseColors.white.c25,
}));

const ListItemWrapper = styled(ListItem)(({ theme }) => ({
  display: 'block',
  '&:hover': {
    backgroundColor: theme.palette.baseColors.deepSeaNavy.c75,
  },
}));
ListItemWrapper.defaultProps = {
  disablePadding: true,
};

const ListItemButtonWrapper = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'disabled',
})(({ open, disabled }) => ({
  display: 'flex',
  justifyContent: open ? 'initial' : 'center',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.5 : 1,
  transition: 'opacity 0.3s ease-in-out',
}));

export const AdminsBar = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const adminsPageOptions = useAdminsPageOptions();

  const [userLogout, { loading: logoutLoading }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      clearUserCredentials();
      sessionStorage.clear();
      history.push(SIGN_IN_PATH);
    },
    onError: alert,
  });

  const extraOptions = [
    {
      label: 'Admins portal',
      icon: AdminPanelSettings,
      onClick: () => history.push(ADMINS_PATH),
      selected: true,
    },
    { label: 'Welligence dashboard', icon: Public, onClick: () => history.push(ROOT_PATH) },
    { label: 'Sign Out', icon: Logout, onClick: () => userLogout() },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            id="logo"
            src={FullLogoLightBackground}
            alt="Welligence-Logo"
            width={'172px'}
            height={'45px'}
            style={{
              opacity: open ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
          />
          <IconButton
            onClick={() => setOpen((open) => !open)}
            sx={{
              color: theme.palette.baseColors.offWhite.c100,
              '&:hover': {
                backgroundColor: theme.palette.baseColors.deepSeaNavy.c75,
              },
            }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {adminsPageOptions.map(({ label, icon: Icon, route, hide }) =>
            hide ? null : (
              <ListItemWrapper key={label}>
                <ListItemButtonWrapper open={open} onClick={() => history.push(route)}>
                  <ListItemIconWrapper open={open} selected={currentPath === route}>
                    <Icon />
                  </ListItemIconWrapper>
                  <ListItemText
                    primary={label}
                    sx={[{ opacity: open ? 1 : 0, transition: 'opacity 0.3s ease-in-out' }]}
                  />
                </ListItemButtonWrapper>
              </ListItemWrapper>
            ),
          )}
        </List>
        <List sx={{ marginTop: 'auto', paddingTop: '20px' }}>
          {extraOptions.map(({ label, icon: Icon, onClick, selected }) => (
            <ListItemWrapper key={label}>
              <ListItemButtonWrapper open={open} onClick={onClick} disabled={logoutLoading}>
                <ListItemIconWrapper open={open} selected={selected}>
                  <Icon />
                </ListItemIconWrapper>
                <ListItemText
                  primary={label}
                  sx={[
                    {
                      opacity: open ? 1 : 0,
                      transition: 'opacity 0.3s ease-in-out',
                    },
                  ]}
                />
              </ListItemButtonWrapper>
            </ListItemWrapper>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  );
};
