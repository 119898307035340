import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { EntityRow } from '../EntityRow';
import { GroupRow } from './components/GroupRow';
import clsx from 'clsx';
import { useStyles } from './Group.style';

export const Group = ({
  group,
  groupMetaData,
  groupDepth = 0,
  setGroupIsChosen,
  entitiesGrouped = [],
  setOptionIsChosen,
  openGroupWhileTyping,
  groupsOpenedByDefault = false,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(groupsOpenedByDefault);
  useEffect(() => {
    if (openGroupWhileTyping) {
      setIsOpen(true);
    }
  }, [openGroupWhileTyping]);

  return (
    <Box sx={{ marginLeft: `${groupDepth}rem` }}>
      <div>
        <GroupRow
          displayName={group.displayName}
          groupMetaData={groupMetaData}
          setOptionIsChosen={(e) => setGroupIsChosen(group, e)}
          openCloseCb={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          isClickable={entitiesGrouped.length > 0}
        />
      </div>
      <div className={clsx(classes.subList, { [classes.subListOpened]: isOpen })}>
        {entitiesGrouped.every((entity) => entity.dropdownGroup)
          ? entitiesGrouped.map(({ dropdownGroup: subGroup, entities, groupMetaData }) => (
              <Group
                key={subGroup.key}
                group={subGroup}
                groupMetaData={groupMetaData}
                groupDepth={groupDepth + 1}
                setGroupIsChosen={setGroupIsChosen}
                entitiesGrouped={entities}
                setOptionIsChosen={setOptionIsChosen}
                openGroupWhileTyping={openGroupWhileTyping}
                groupsOpenedByDefault={groupsOpenedByDefault}
              />
            ))
          : entitiesGrouped.map(({ name, displayName, isChosen, id }) => (
              <EntityRow
                displayName={displayName || name}
                isChosen={!!isChosen}
                key={id}
                setOptionIsChosen={(e) => setOptionIsChosen(id, e)}
              />
            ))}
      </div>
    </Box>
  );
};
