import { Box, Typography } from '@mui/material';

import React from 'react';
import { TextCol } from './HeaderCol';
import { styles } from '../styles';

export const DataCol = ({
  type,
  value,
  addition = null,
  status = null,
  isLast,
  showLeadingLines,
}) => {
  if (type === 'text' || type === 'date') {
    return <TextCol value={value} type={type} />;
  }

  if (type === 'title') {
    return (
      <Box
        sx={
          showLeadingLines
            ? {
                ...styles.block(status),
                ...(isLast ? styles.blockEnd(status) : {}),
              }
            : {}
        }
      >
        <Typography component="span">{value}</Typography>
        {addition}
      </Box>
    );
  }

  if (type === 'custom') {
    return value;
  }
};
