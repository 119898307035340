import {
  ADMIN_PATHS,
  ANALYTICS_EVENTS,
  CCUS_PATHS,
  CCUS_SUBSCRIPTION,
  CCUS_NOTES_SUBSCRIPTION,
  GHG_PATHS,
  GHG_SUBSCRIPTION,
  INTELLIGENCE_PATHS,
  INTELLIGENCE_SUBSCRIPTION,
  M_A_PATHS,
  M_A_SUBSCRIPTION,
  OIL_AND_GAS_MAP_PATHS,
  OIL_AND_GAS_MAP_SUBSCRIPTION,
  PORTFOLIO_ANALYSIS_PATHS,
  PORTFOLIO_ANALYSIS_SUBSCRIPTION,
  PUBLIC_PATHS,
  WEB_BASIC_PATHS,
  WEB_BASIC_SUBSCRIPTION,
} from '../../utils/constants';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserId, isAuthenticated } from '../../utils/auth';

import { CURRENT_USER_QUERY } from './operations';
import SnackbarContext from '../SnackbarContext';
import { curateLocation } from '../../utils/helpers';
import { isValidGALink } from '../../utils/analytics';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

const CurrentUserContext = createContext();

export const CurrentUserProvider = (props) => {
  const history = useHistory();
  const { setSnack } = useContext(SnackbarContext);
  const [showDownloadLimitMessage, setShowDownloadLimitMessage] = useState(false);

  useEffect(() => {
    // need to send if url typed directly (not on route change listener)
    // i.e. oil and gas map opens in new tab
    if (isValidGALink(location.pathname)) {
      gtag('event', ANALYTICS_EVENTS.pageview, { page: location.pathname, userId: getUserId() });
    }
    // listen for route changes
    return history.listen((location) => {
      if (isValidGALink(location.pathname)) {
        gtag('event', ANALYTICS_EVENTS.pageview, { page: location.pathname, userId: getUserId() });
      }
    });
  }, [history]);

  const {
    data: userData,
    loading: userLoading,
    refetch: refetchUserData,
  } = useQuery(CURRENT_USER_QUERY, { fetchPolicy: 'network-only' });

  const refreshProvider = () => {
    refetchUserData();
  };

  const currentUser =
    userLoading || !userData || !userData.currentUserProfile ? {} : userData.currentUserProfile;

  if (!userLoading && userData && userData.currentUserProfile) {
    gtag('set', { userId: getUserId() });
  }

  const { downloadMessage, featuresEntitled, mapCcusTilesetSources, mapTilesetSources } =
    currentUser;

  const flashDownloadLimitMessage = () => {
    refreshProvider();
    setShowDownloadLimitMessage(true);
  };

  const isPublicPath = (location) => location === '/' || PUBLIC_PATHS.includes(location);
  const isAdminsPath = (location) => ADMIN_PATHS.includes(location);

  const isUserEntitled = (location) => {
    if (isPublicPath(location)) return true;

    const curatedPath = curateLocation(location);
    if (isAdminsPath(curatedPath)) {
      return currentUser?.admin;
    }

    const featurePathsMap = {
      [WEB_BASIC_SUBSCRIPTION]: WEB_BASIC_PATHS,
      [OIL_AND_GAS_MAP_SUBSCRIPTION]: OIL_AND_GAS_MAP_PATHS,
      [CCUS_SUBSCRIPTION]: CCUS_PATHS,
      [M_A_SUBSCRIPTION]: M_A_PATHS,
      [INTELLIGENCE_SUBSCRIPTION]: INTELLIGENCE_PATHS,
      [CCUS_NOTES_SUBSCRIPTION]: INTELLIGENCE_PATHS,
      [PORTFOLIO_ANALYSIS_SUBSCRIPTION]: PORTFOLIO_ANALYSIS_PATHS,
      [GHG_SUBSCRIPTION]: GHG_PATHS,
    };

    return Object.entries(featurePathsMap).some(([feature, paths]) => {
      return featuresEntitled?.includes(feature) && paths.includes(curatedPath);
    });
  };

  useEffect(() => {
    if (downloadMessage && showDownloadLimitMessage) {
      setSnack({ open: true, message: downloadMessage, severity: 'success' });
      setShowDownloadLimitMessage(false);
    }
  }, [userData]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        userLoading,
        flashDownloadLimitMessage,
        isUserEntitled,
        refreshProvider,
        mapCcusTilesetSources,
        mapTilesetSources,
      }}
    >
      {(!isAuthenticated() || isPublicPath(location.pathname) || featuresEntitled) &&
        props.children}
    </CurrentUserContext.Provider>
  );
};

export const CurrentUserConsumer = CurrentUserContext.Consumer;

export const useCurrentUser = () => useContext(CurrentUserContext);

export default CurrentUserContext;
