import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { COLUMNS } from './constants';
import { DataGrid } from '@mui/x-data-grid';
import SnackbarContext from '../../../../../../contexts/SnackbarContext';
import { useDownloadMaOpportunities } from './hooks/useDownloadMaOpportunities';
import { useGetMaOpportunities } from './hooks/useGetMaOpportunities';
import { useStyles } from './styles';

const SESSION_STORAGE_KEY = 'OpportunitiesTableSettings';
const getInitialTableSettings = () => {
  const sessionSettings = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (sessionSettings) {
    const { paginationModel, sortModel } = JSON.parse(sessionSettings);
    return { paginationModel, sortModel };
  }

  return {
    paginationModel: { page: 0, pageSize: 25 },
    sortModel: [{ field: 'country', sort: 'asc' }],
  };
};

export const OpportunitiesTable = () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [downloading, setDownloading] = useState(false);
  const { downloadMaOpportunities } = useDownloadMaOpportunities();
  const { paginationModel: defaultPaginationModel, sortModel: defaultSortModel } =
    getInitialTableSettings();

  const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const { data, totalCount, loading, error } = useGetMaOpportunities(
    paginationModel?.page,
    paginationModel?.pageSize,
    sortModel[0]?.sort,
    sortModel[0]?.field,
  );

  const rowCountRef = useRef(totalCount || 0);
  const rowCount = useMemo(
    () => (rowCountRef.current = totalCount || rowCountRef.current),
    [totalCount],
  );

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({ paginationModel, sortModel }));
  }, [paginationModel, sortModel]);

  const onDownload = useCallback(async () => {
    setDownloading(true);
    setSnack({
      open: true,
      message: 'Your download should begin shortly.',
      severity: 'success',
    });

    const { ok, errors } = await downloadMaOpportunities();

    if (!ok) {
      setSnack({
        open: true,
        message: errors[0]?.message || "We're sorry, but something went wrong.",
        severity: 'error',
      });
    }
    setDownloading(false);
  }, [downloadMaOpportunities, setDownloading, setSnack]);

  if (error) return null;

  return (
    <div>
      <Box className={classes.tableHeader}>
        <Typography className={classes.table}>M&A Opportunities</Typography>
        <Button
          className={classes.download}
          variant="contained"
          onClick={onDownload}
          disabled={downloading}
        >
          Download Data
        </Button>
      </Box>
      <Box className={classes.container}>
        <div className={classes.tableWrapper}>
          <DataGrid
            className={classes.table}
            rows={data}
            columns={COLUMNS}
            rowCount={rowCount}
            loading={loading}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            pageSizeOptions={[10, 25, 50]}
            disableSelectionOnClick
            slotProps={{
              pagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from.toLocaleString()} - ${to.toLocaleString()} of ${count.toLocaleString()}`,
              },
            }}
          />
        </div>
      </Box>
    </div>
  );
};
