import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import {
  AdvancedFilterCategories,
  defaultCheckBoxFilterTypes,
  defaultCheckListFilterTypes,
  defaultFilterTypes,
  defaultMinMaxFilterTypes,
} from './filterTypes';
import React, { Fragment, useEffect, useState } from 'react';
import { analytics, dimensions } from '../../utils/analytics';

import { Box } from '@mui/material';
import CheckBoxItem from './checkBoxItem';
import CheckListGroup from './checkListGroup';
import CheckListItem from './checkListItem';
import { CloseButtons } from '../Modal/CloseButtons';
import Divider from '@mui/material/Divider';
import FilterItem from './filterItem';
import Grid from '@mui/material/Grid2';
import InputList from '../InputList';
import Modal from '../Modals';
import SliderItem from './sliderItem';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { getUserId } from '../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { preparePropVariables } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  button: {
    ...theme.palette.altButton,
  },
  editButton: {
    ...theme.palette.altButton,
    height: '28px',
    width: '50px',
    marginLeft: '5px',
  },
  apply: {
    ...theme.palette.button,
    height: '100%',
    width: '128px',
    marginRight: '10px',
  },
  buttonsContainer: {
    flex: '0 1 auto',
    padding: '10px 20px',
    borderTop: 'solid rgba(0, 0, 0, 0.12) thin',
  },
  filtersRoot: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) thin',
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
    paddingTop: '10px',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 73.6px)',
    },
    '& .advanced-autocomplete': {
      padding: '0',
    },
    '& .MuiAutocomplete-root': {
      margin: '0',
    },
  },
  modal: {
    backgroundColor: 'white',
    height: '90vh',
    width: '90%',
    maxHeight: '1100px',
    maxWidth: '1400px',
  },
  chipItemContainer: {
    minHeight: '55px',
    padding: '10px 20px',
  },
  topFilters: {
    display: 'grid',
    gap: '12px',
    padding: '0 10px',
    maxHeight: '38%',
    minHeight: '150px',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
      minHeight: 'unset',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  bottomFilters: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    backgroundColor: '#F5F5F5',
    padding: '0 10px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  equalWidth: {
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  checkListContainer: {
    display: 'grid',
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  minMaxContainer: {
    ...theme.palette.altScrollbarBlue,
    overflow: 'auto',
    padding: '5px 15px 0',
    flexWrap: 'nowrap',
  },
  sliderContainer: {
    padding: '5px',
  },
  sliderScroll: {
    ...theme.palette.altScrollbarBlue,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 15px',
    overflow: 'auto',
    flexWrap: 'nowrap',
    width: '97%',
    height: 'calc(100% - 30.25px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100% - 27.6px)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 24.94px)',
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  consolidateAssetsButton: {
    ...theme.palette.button,
    minWidth: '128px',
  },
  filterItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingBottom: '5px',
  },
}));

const BasicFiltersRow = ({
  context,
  filters,
  setFilters,
  filterItemClass,
  filterTypes = defaultFilterTypes,
  googleAnalyticsTag,
}) => {
  const classes = useStyles();
  const propVariables = preparePropVariables(filters);

  const deleteFilter = (filterToDelete) => {
    const newFilters = { ...filters };
    const { parent, value } = filterToDelete;
    newFilters[parent] = filters[parent].filter((val) => val.name !== value.name);
    setFilters(newFilters);
  };

  const onFilterChange = (filter, name) => {
    const newFilters = { ...filters };
    newFilters[name] = filter;
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [name]: newFilters[name] }),
      userId: getUserId(),
      ...dimensions(newFilters, context),
    });
    setFilters(newFilters);
  };

  return (
    <Box
      className={classes.topFilters}
      sx={{ gridTemplateColumns: `repeat(${filterTypes.length}, 1fr)` }}
    >
      {filterTypes.map((filter, idx) => (
        <FilterItem
          key={`${context}-modal-${filter.name}-=${idx}`}
          context={`${context}-modal`}
          filter={filter}
          setFilter={onFilterChange}
          propVariables={
            filter.propVariables && typeof filter.propVariables === 'function'
              ? filter.propVariables(filters, context)
              : propVariables
          }
          withChipList
          value={filters[filter.key]}
          deleteFilter={deleteFilter}
          disabled={filter.disabled ? filter.disabled(filters.assets) : false}
          controlValues={
            filter.control?.getControlValuesFromFilters
              ? filter.control.getControlValuesFromFilters(filters)
              : {}
          }
          inModal
          className={filterItemClass || classes.filterItem}
        />
      ))}
    </Box>
  );
};

export const AdvancedFiltersRow = ({
  context,
  filters,
  setFilters,
  checkBoxFilterTypes = defaultCheckBoxFilterTypes,
  checkListFilterTypes = defaultCheckListFilterTypes,
  checkListItems,
  checkListAllReturnsNone = false,
  googleAnalyticsTag,
  minMaxFilterTypes = defaultMinMaxFilterTypes,
  sliderFiltersLabel = '',
  sliderFilterTypes = [],
  sliderFilterMaxValues = {},
  sliderFilterLoading,
  order = [
    AdvancedFilterCategories.sliders,
    AdvancedFilterCategories.checkLists,
    AdvancedFilterCategories.minMax,
  ],
  checkListContainerGridColumns,
  loading = false,
  equalWidth = false,
}) => {
  const classes = useStyles();
  const onFilterChange = (filter, name) => {
    const newFilters = { ...filters };
    newFilters[name] = filter;
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.singleFilter,
      event_label: analytics({ [name]: newFilters[name] }),
      userId: getUserId(),
      ...dimensions(newFilters, context),
    });
    setFilters(newFilters);
  };

  const sliderFilters = sliderFilterTypes.length > 0 && (
    <div className={classes.sliderContainer}>
      {sliderFiltersLabel && (
        <Typography variant="overline" className={classes.subHeader} style={{ padding: '0 15px' }}>
          {sliderFiltersLabel}
        </Typography>
      )}
      <Grid className={classes.sliderScroll}>
        {sliderFilterTypes.map((filter, idx) => (
          <SliderItem
            key={`${context}-modal-${filter.label}-${idx}`}
            item={filter}
            loading={sliderFilterLoading}
            onChange={onFilterChange}
            value={filters[filter.key]}
            maxValue={sliderFilterMaxValues[filter.maxKey]}
          />
        ))}
      </Grid>
    </div>
  );

  const checkListFilters = checkListFilterTypes.length > 0 && (
    <Box
      className={classes.checkListContainer}
      sx={{
        gridTemplateColumns: checkListContainerGridColumns || '1fr 1fr 1fr',
      }}
    >
      {checkListFilterTypes.map((filter, idx) =>
        filter.group ? (
          <CheckListGroup
            key={`group-${filter.name}-${idx}`}
            checkListItems={checkListItems}
            filter={filter}
            filters={filters}
            setFilter={onFilterChange}
          />
        ) : (
          <Box key={`item-${filter.name}-${idx}`} sx={{ padding: '5px' }}>
            <CheckListItem
              checkListItems={checkListItems}
              filter={filter}
              filters={filters}
              setFilter={onFilterChange}
              allReturnsNone={checkListAllReturnsNone}
            />
          </Box>
        ),
      )}
    </Box>
  );

  const minMaxFilters = (minMaxFilterTypes.length > 0 || checkBoxFilterTypes.length > 0) && (
    <div className={classes.minMaxContainer}>
      <InputList values={filters} setInput={onFilterChange} items={minMaxFilterTypes} />
      <Divider style={{ marginTop: '10px' }} />
      {checkBoxFilterTypes.map((filter, idx) => (
        <CheckBoxItem
          key={`${context}-modal-${filter.label}-${idx}`}
          item={filter}
          onClick={onFilterChange}
          value={filters[filter.key]}
        />
      ))}
    </div>
  );

  const filterComponents = {
    [AdvancedFilterCategories.sliders]: sliderFilters,
    [AdvancedFilterCategories.checkLists]: checkListFilters,
    [AdvancedFilterCategories.minMax]: minMaxFilters,
  };

  if (loading) {
    return (
      <div className={classes.bottomFilters}>
        <Typography variant="h6" align="center">
          Loading filters...
        </Typography>
      </div>
    );
  }

  return (
    <div className={clsx(classes.bottomFilters, { [classes.equalWidth]: equalWidth })}>
      {order.map((filter, idx) => (
        <Fragment key={`filter-type-${idx}`}>{filterComponents[filter]}</Fragment>
      ))}
    </div>
  );
};

export default ({
  apply,
  checkBoxFilterTypes = defaultCheckBoxFilterTypes,
  checkListFilterTypes = defaultCheckListFilterTypes,
  checkListItems,
  clearFilters,
  context,
  defaultFilters,
  filterItemClass,
  filterTypes = defaultFilterTypes,
  googleAnalyticsTag,
  minMaxFilterTypes = defaultMinMaxFilterTypes,
  open,
  setOpen,
  sliderFiltersLabel = '',
  sliderFilterTypes = [],
  sliderFilterMaxValues = {},
  sliderFilterLoading,
  title,
  checkListContainerGridColumns,
}) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(defaultFilters);

  const onApply = () => {
    apply(filters);
    setOpen(false);
  };

  useEffect(() => setFilters(defaultFilters), [defaultFilters]);

  return (
    <Modal
      handleClose={() => setOpen(false)}
      open={open}
      title={title}
      titleAlignedWithClose={true}
      styles={classes}
    >
      <div className={classes.filtersRoot}>
        <BasicFiltersRow
          context={context}
          filters={filters}
          setFilters={setFilters}
          filterItemClass={filterItemClass}
          filterTypes={filterTypes}
          googleAnalyticsTag={googleAnalyticsTag}
        />

        <AdvancedFiltersRow
          context={context}
          filters={filters}
          setFilters={setFilters}
          checkBoxFilterTypes={checkBoxFilterTypes}
          checkListFilterTypes={checkListFilterTypes}
          checkListItems={checkListItems}
          googleAnalyticsTag={googleAnalyticsTag}
          minMaxFilterTypes={minMaxFilterTypes}
          sliderFiltersLabel={sliderFiltersLabel}
          sliderFilterTypes={sliderFilterTypes}
          sliderFilterMaxValues={sliderFilterMaxValues}
          sliderFilterLoading={sliderFilterLoading}
          checkListContainerGridColumns={checkListContainerGridColumns}
        />
      </div>

      <CloseButtons apply={onApply} clear={clearFilters} />
    </Modal>
  );
};
