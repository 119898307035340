import {
  Business as BussinesIcon,
  LocalFireDepartment as LocalFireDepartmentIcon,
  Public as PublicIcon,
} from '@mui/icons-material';

import React from 'react';

export const REPORT_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  ARCHIVED: 'archived',
};

export const REPORT_STATUS_COLORS = {
  [REPORT_STATUS.PUBLISHED]: 'success',
  [REPORT_STATUS.DRAFT]: 'warning',
  [REPORT_STATUS.ARCHIVED]: 'default',
};

export const REPORT_TYPES = {
  COUNTRY: 'country',
  ASSET: 'asset',
  CORPORATE: 'corporate',
};

export const REPORT_TYPE_ICONS = {
  [REPORT_TYPES.COUNTRY]: <PublicIcon />,
  [REPORT_TYPES.ASSET]: <LocalFireDepartmentIcon />,
  [REPORT_TYPES.CORPORATE]: <BussinesIcon />,
};
