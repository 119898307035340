import { gql } from '@apollo/client';

export const UserConfirmationQuery = gql`
  query UserConfirmation($confirmationToken: String!, $redirectUrl: String!) {
    userConfirmation(confirmationToken: $confirmationToken, redirectUrl: $redirectUrl) {
      confirmed
      redirectUrl
    }
  }
`;
