import { gql } from '@apollo/client';

export const EXPORT_MA_OPPORTUNITIES_ENDPOINT = 'm-a/export-opportunities';

export const MA_OPPORTUNITIES_AGGREGATES_QUERY = gql`
  query maOpportunitiesAggregates(
    $by: MaAggregateByEnum!
    $filters: MaFilters
    $valuationsSum: Boolean!
    $reservesSum: Boolean!
    $productionsSum: Boolean!
    $acquisitionCostSum: Boolean!
    $countSum: Boolean!
  ) {
    maOpportunitiesAggregates(by: $by, filters: $filters) {
      type
      group
      valuationsSum @include(if: $valuationsSum)
      reservesSum @include(if: $reservesSum)
      productionsSum @include(if: $productionsSum)
      acquisitionCostSum @include(if: $acquisitionCostSum)
      countSum @include(if: $countSum)
    }
  }
`;

export const MA_OPPORTUNITIES_AGGREGATES_STACKED_QUERY = gql`
  query maOpportunitiesAggregatesStacked(
    $by: MaAggregateByEnum!
    $stackBy: MaStackByEnum!
    $fields: [String!]!
    $filters: MaFilters
  ) {
    maOpportunitiesAggregatesStacked(
      by: $by
      stackBy: $stackBy
      fields: $fields
      filters: $filters
    ) {
      stacks
      data {
        type
        group
        values
      }
    }
  }
`;

export const MA_OPPORTUNITIES_QUERY = gql`
  query maOpportunities(
    $filters: MaFilters
    $page: Int
    $perPage: Int
    $order: String
    $orderBy: String
  ) {
    maOpportunities(
      filters: $filters
      page: $page
      perPage: $perPage
      order: $order
      orderBy: $orderBy
    ) {
      totalCount
      opportunities {
        id
        name
        country {
          id
          name
          isoCode
        }
        hydrocarbonType
        legacyId
        maOpportunity {
          group
          sellerCompany {
            name
          }
          sellerStake
          sellerMotivation
          resourceTheme
          operatedStake
          comment
          officiallyOnMarket
          dateOfEntry
          npv
          remainingReserves
          remainingCapex
          estimatedRecentProduction
        }
      }
    }
  }
`;
