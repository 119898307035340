import { useGetReportTypes } from './useGetReportTypes';
import { useMemo } from 'react';

export const useGetEntities = (reportTypeId) => {
  const { reportTypes } = useGetReportTypes();

  const reportType = useMemo(
    () => reportTypes.find((type) => type.id === reportTypeId),
    [reportTypeId, reportTypes],
  );

  const entitiesByType = {
    Country: [
      { id: 1, name: 'Brazil' },
      { id: 2, name: 'USA' },
      { id: 3, name: 'Canada' },
      { id: 4, name: 'Mexico' },
    ],
    Asset: [
      { id: 1, name: 'Asset 1' },
      { id: 2, name: 'Asset 2' },
      { id: 3, name: 'Asset 3' },
      { id: 4, name: 'Asset 4' },
    ],
    Corporate: [
      { id: 1, name: 'Shell' },
      { id: 2, name: 'ExxonMobil' },
      { id: 3, name: 'Aker BP' },
      { id: 4, name: 'TotalEnergy' },
    ],
  };

  const entities = useMemo(() => entitiesByType[reportType?.name] || [], [reportType]);

  return { entities };
};
