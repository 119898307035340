import {
  TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM,
  TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
} from '../constants';

import { MA_TRANSACTIONS_AGGREGATES_QUERY } from '../operations';
import { useMaContext } from '../../../MaContext';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useGetMaTransactionsAggregates = (
  by = TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  fields = TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS,
  filters = {},
) => {
  const { filterVariables } = useMaContext();

  const requestedFields = useMemo(() => {
    return TRANSACTIONS_AGGREGATE_FIELDS_OPTIONS.reduce((acc, field) => {
      acc[field] = fields?.includes(field);
      return acc;
    }, {});
  }, [fields]);

  const { data, loading, error } = useQuery(MA_TRANSACTIONS_AGGREGATES_QUERY, {
    variables: { by, filters: { ...filterVariables, ...filters }, ...requestedFields },
  });

  return { data: data?.maTransactionsAggregates || [], loading, error };
};
