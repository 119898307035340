import React, { useState } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  TextField,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFeedbackPanelStyles } from './FeedbackPanel.style';

import { useMutation } from '@apollo/client';
import { CREATE_HUBSPOT_TICKET_MUTATION } from './operations';

export const FeedbackPanel = ({ open, onClose, setSnack }) => {
  const classes = useFeedbackPanelStyles();
  const [step, setStep] = useState(1);
  const [feedbackType, setFeedbackType] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [createIssue] = useMutation(CREATE_HUBSPOT_TICKET_MUTATION);

  const handleSelectFeedback = (option) => {
    setFeedbackType(option);
    setStep(2);
  };

  const handleClose = () => {
    setStep(1);
    setFeedbackType('');
    setSubject('');
    setDescription('');
    onClose();
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    handleClose();

    createIssue({
      variables: {
        issueType:
          feedbackType === 'Data/functionality issue' ? 'PRODUCT_ISSUE' : 'FEATURE_REQUEST',
        summary: subject,
        description: description,
      },
    });

    setSnack({
      open: true,
      message: 'Thanks for your feedback!',
      severity: 'success',
    });
  };

  if (!open) return null;

  return (
    <Paper elevation={3} className={classes.container}>
      <div className={classes.header}>
        {step === 2 && (
          <IconButton onClick={handleBack} className={classes.leftIcon}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        )}
        <Typography className={classes.title}>
          {step === 1 ? 'PROVIDE FEEDBACK' : 'LEAVE US A MESSAGE'}
        </Typography>
        <IconButton onClick={handleClose} className={classes.rightIcon}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>

      <Divider className={classes.divider} />

      {step === 1 ? (
        <>
          <Typography variant="body2" className={classes.label}>
            Please select your issue
          </Typography>
          <List>
            {['Data/functionality issue', 'Feature request/enhancement'].map((option, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => handleSelectFeedback(option)}
                  className={classes.optionButton}
                >
                  <ListItemText
                    primary={option}
                    primaryTypographyProps={{ fontSize: '14px', color: '#1A1A1A' }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <>
          <Typography variant="body2" className={classes.label}>
            Please provide more details below:
          </Typography>

          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className={classes.textField}
            size="small"
            slotProps={{ htmlInput: { maxLength: 50 } }}
            error={!subject.trim()}
          />

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={classes.textField}
            size="small"
            slotProps={{ htmlInput: { maxLength: 500 } }}
            error={!description.trim()}
          />

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            className={classes.button}
            disabled={!subject.trim() || !description.trim()}
          >
            Send
          </Button>
        </>
      )}
    </Paper>
  );
};
