import clsx from 'clsx';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2 as Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { useGetComponentsFromLayout, useGetLayoutItems } from '../hooks/useGetReports';

const useStyles = makeStyles((theme) => ({
  img: {
    margin: '10px',
    width: '50%',
    border: 'solid thin black',
  },
  componentImg: {
    margin: '10px 0 0',
    width: '90%',
    border: 'none',
  },
  number: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Barlow',
  },
  selected: {
    borderColor: theme.palette.baseColors.infoBlue.c100,
  },
  horizontalScroll: {
    height: 'fit-content',
    width: '100%',
    overflow: 'auto',
    overflowY: 'hidden',
  },
  transparent: {
    backgroundColor: 'transparent',
    color: 'black',
  },
}));

const LayoutItem = ({ layoutItem, selectedLayout, setSelectedLayout }) => {
  const classes = useStyles();
  const { img, type } = layoutItem;

  return (
    <Tooltip title={type}>
      <img
        src={img}
        alt={`smart-report-layout-item-${type}`}
        className={clsx({
          [classes.img]: true,
          [classes.selected]: selectedLayout?.type === type,
        })}
        onClick={() => setSelectedLayout(layoutItem)}
      />
    </Tooltip>
  );
};

const ComponentItem = ({ componentItem }) => {
  const classes = useStyles();
  const { components, name } = componentItem;
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      className={classes.transparent}
      style={{ width: '100%' }}
      square={true}
      expanded={expanded}
      onChange={(e, exp) => setExpanded(exp)}
    >
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon className={classes.transparent} />}
        aria-controls="panel-layers"
      >
        <Typography variant="subtitle2">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.transparent} style={{ padding: 0 }}>
        <Grid container borderTop="solid thin lightgrey">
          {components.map(({ img, name, type }, idx) => (
            <Grid
              key={`smart-reports-component-item-components-${type}-${idx}`}
              item
              size={6}
              textAlign="center"
            >
              <img
                src={img}
                alt={`smart-report-component-item-${type}`}
                className={clsx({
                  [classes.img]: true,
                  [classes.componentImg]: true,
                })}
                onClick={() => {}}
              />
              <Typography variant="subtitle2">{name}</Typography>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const Layout = ({ report, selectedLayout, setSelectedLayout }) => {
  const classes = useStyles();
  const { layoutItems } = useGetLayoutItems();
  const { components } = useGetComponentsFromLayout(selectedLayout);

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item size={12} display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">Entities</Typography>
        <Typography variant="subtitle2">{report?.entity}</Typography>
      </Grid>
      <Grid item size={12} display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">Layout</Typography>
      </Grid>
      <Grid className={classes.horizontalScroll} item size={12} display="flex">
        {layoutItems.map((layoutItem, idx) => (
          <LayoutItem
            key={`smart-reports-layout-item-${layoutItem.name}-${idx}`}
            layoutItem={layoutItem}
            selectedLayout={selectedLayout}
            setSelectedLayout={setSelectedLayout}
          />
        ))}
      </Grid>
      <Grid item size={12} display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">Components</Typography>
      </Grid>
      <Grid item size={12} display="flex" flexDirection="column">
        {components.map((componentItem, idx) => (
          <ComponentItem
            key={`smart-reports-component-item-${componentItem.name}-${idx}`}
            componentItem={componentItem}
          />
        ))}
      </Grid>
    </Grid>
  );
};
