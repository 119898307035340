import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Legend from './legend';
import { useTabbedLegendContext } from './tabbedLegend.context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    position: 'absolute',
    color: 'black',
    left: '-24px',
    height: '100%',
    width: '28px',
    background: 'white',
    '-webkit-writing-mode': 'vertical-rl',
    writingMode: 'vertical-rl',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.7rem',
    letterSpacing: '0.5px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',

    '& > div': {
      transform: 'rotate(180deg)',
      background: 'white',
      maxWidth: '100%',
      maxHeight: '100%',
      textAlign: 'center',
    },
  },
  itemsContainer: {
    backgroundColor: theme.palette.baseColors.iceBlue.c75,
  },
  keepMounted: {
    ...theme.palette.keepMounted,
  },
  keepMountedEnabled: {
    ...theme.palette.keepMountedEnabled,
  },
  tabs: {
    minHeight: 'auto',
    '& > .MuiTabs-fixed': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > .MuiTabs-flexContainer': {
        width: 'fit-content',
        '& > button': {
          backgroundColor: theme.palette.baseColors.deepSeaNavy.c50,

          '&.Mui-selected': {
            backgroundColor: theme.palette.baseColors.iceBlue.c75,
            color: 'black',
          },
        },
      },
    },
  },
  tabLabel: {
    minHeight: 'auto',
    color: theme.palette.baseColors.deepSeaNavy.c100,
    cursor: 'pointer',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: 'rgba(205, 215, 222, .75)',
    '&:hover': {
      backgroundColor: 'rgba(205, 215, 222, .95)',
    },
    '& > .MuiTab-wrapper': {
      fontSize: '.8rem',
      fontWeight: '400',
    },
  },
}));

export default ({ enabled, keepMounted, tabbedItems, title = false }) => {
  const classes = useStyles();
  const { tabChosen, setTabChosen } = useTabbedLegendContext();

  const handleChange = useCallback(
    (_event, newValue) => {
      setTabChosen(newValue);
    },
    [setTabChosen],
  );

  const { renderedItems, renderedTabs } = useMemo(() => {
    const renderedItems = [];
    const renderedTabs = [];
    tabbedItems.forEach((item, idx) => {
      renderedItems.push(
        <Legend
          key={`legend-tab-component-${item.label}-${idx}`}
          items={item.items}
          multiline={item.multiline}
          itemBorder={item.itemBorder}
          className={classes.itemsContainer}
        />,
      );
      renderedTabs.push(
        <Tab
          className={classes.tabLabel}
          key={`legend-tab-${item.label}-${idx}`}
          label={item.label}
        />,
      );
    });
    return { renderedItems, renderedTabs };
  }, [tabbedItems]);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.keepMounted]: !!keepMounted,
        [classes.keepMountedEnabled]: !!keepMounted && !!enabled,
      })}
    >
      {title && (
        <div className={classes.title}>
          <div>LEGEND</div>
        </div>
      )}
      <Tabs className={classes.tabs} scrollButtons="auto" value={tabChosen} onChange={handleChange}>
        {renderedTabs}
      </Tabs>
      {renderedItems[tabChosen]}
    </div>
  );
};
