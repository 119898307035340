import { REPORTS_MOCK } from '../mock';
import { useCurrentUser } from '../../../../../contexts/CurrentUserContext';
import { useGetEntities } from './useGetEntities';
import { useGetReportTypes } from './useGetReportTypes';

export const useCreateReport = ({ reportTypeId, entityId, reportTemplate }) => {
  const { currentUser } = useCurrentUser();
  const { reportTypes } = useGetReportTypes();
  const { entities } = useGetEntities(reportTypeId);

  const reportType = reportTypes.find((type) => type.id === reportTypeId);
  const entity = entities.find((entity) => entity.id === entityId);

  return {
    createReport: () => {
      console.log('Creating report', reportTypeId, entityId, reportTemplate);

      REPORTS_MOCK.unshift({
        id: REPORTS_MOCK.length + 1,
        type: reportType.value,
        entity: entity.name,
        template: reportTemplate?.id || null,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: currentUser.name,
        versions: [
          {
            id: 1,
            version: 1,
            status: 'draft',
            createdAt: new Date().toISOString(),
            createdBy: currentUser.name,
          },
        ],
      });
    },
  };
};
